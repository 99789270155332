import { Injectable } from '@angular/core';
import { Toggle } from '../../interfaces/toggle.interface';

@Injectable()
export class ToggleService {
  toggle: any;

  constructor() { }

  getToggle(type: string) {
    if (this.toggle !== undefined && this.toggle !== null) {
      return Object.assign({}, this.toggle.filter((x: any) => x.featureName === type)[0]);
    }
    else {
      return {};
    }
  }

  setToggle(tog: Toggle) {
    this.toggle = tog;
  }

  setToogleFeatureEnabled(type: string, value: boolean) {
    if (this.toggle !== undefined && this.toggle !== null) {
      const currentToggle = this.toggle.filter((x: any) => x.featureName === type);
      currentToggle[0].featureEnabled = value;
    }
  }
}
