
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ChannelType } from '../../enums/channel-type.enum';
import { environment } from '../../../../environments/environment';
import { AwsSignatureInputData } from '../../interfaces/aws-input.interface';
import { AwsSignatureService } from '../local/aws-signature.service';
import { UserService } from '../local/user.service';


@Injectable()
export class FileManagerService {

  private awsInputData: AwsSignatureInputData = new AwsSignatureInputData();

  constructor( private http: HttpClient,
               private AwsSignature: AwsSignatureService,
               private userService: UserService) {
    this.awsInputData.requestParameters = '';
    this.awsInputData.contentType = 'application/json';
    this.awsInputData.canonicalQuerystring = '';
  }

  public uploadFile(fileName: string, fileType: string, fileBytes: File, channel: ChannelType) {
    return this.getSignedUrl(fileName, channel).pipe(
      mergeMap((data: any) => this.putFile(data.url, fileBytes, fileType)));
  }

  private getSignedUrl(fileName, channel: ChannelType) {
    const headers = this.setHeaders('GET', `/security-manager/file/url/${fileName}/${channel}`, 'application/x-www-form-urlencoded');

    return this.http.get(environment.serverUrlSecurityManager + `file/url/${fileName}/${channel}`, { headers });
  }

  private putFile(fileUrl, fileBytes, fileType) {
    const file = new Blob([fileBytes]);
    const contentType = fileType === 'pdf' ?  'application/pdf' : 'image/jpeg';

    return this.http.put(fileUrl, file, { headers: {'Content-Type': contentType} });
  }

  private setHeaders(method: string, canonicalUri: string, contentType: string, requestParameters?: any) {
    const userInfo = this.userService.getUser();

    this.awsInputData.method = method;
    this.awsInputData.canonicalUri = canonicalUri;
    this.awsInputData.host = environment.awsCognitoData.hostSecurity;
    this.awsInputData.region = environment.awsCognitoData.region;
    this.awsInputData.service = environment.awsCognitoData.service;
    this.awsInputData.accessKey = userInfo.accessKey;
    this.awsInputData.secretKey = userInfo.secretKey;
    this.awsInputData.contentType = contentType;
    this.awsInputData.requestParameters = requestParameters ? requestParameters : '';
    const signature = this.AwsSignature.generateSignature(this.awsInputData);

    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': signature['Authorization'],
      'X-Amz-Security-Token': userInfo.sessionToken,
      'X-Amz-Date': signature['X-Amz-Date']
    });
  }

  getFileExtension(file: File) {
    return file.name.lastIndexOf('.') > -1 ? file.name.substring(file.name.lastIndexOf('.') + 1) : '';
  }
}
