import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-salary-loans-overview',
  template: ''
})

// tslint:disable-next-line:component-class-suffix
export class SalaryLoansOverviewComponentMock {
}
