// Imports
import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router, NavigationEnd } from '@angular/router';
import { MainService } from '@avaldigitallabs/bpop-security-frontend-lib';

// Declarations
import { environment } from '../environments/environment';
import { EventCodeEnum } from './shared/enums/event-code.enum';
import { ApiService } from './shared/services/remote/api.service';
import { AuditService } from './shared/services/remote/audit.service';
import { GoogleAnalyticsEventsService } from './shared/services/remote/google-analytics-events.service';
import { CommonService } from './shared/services/local/common.service';
import { PopUpPepComponent } from './shared/components/pop-up/pop-up-pep.component';
import { StepperService } from './shared/services/local/stepper.service';
import { Toggle } from './shared/interfaces/toggle.interface';
import { ToggleService } from './shared/services/local/toggle.service';
import { TransactionAudit } from './shared/interfaces/transaction-audit.interface';
import { User } from './shared/interfaces/user.interface';
import { UserService } from './shared/services/local/user.service';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  user: User;
  lastPing?: Date = null;
  idleState = 'Not started.';
  transactionAudit: TransactionAudit;

  private TIMEOUT_SECONDS: number = 60 * 5;
  toggleChat: Toggle;

  constructor(
    private auditService: AuditService,
    private commonService: CommonService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private idle: Idle,
    private keepalive: Keepalive,
    private mainService: MainService,
    private stepperService: StepperService,
    private router: Router,
    private toggleService: ToggleService,
    private userService: UserService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.createCodeGoogleAnalytics();
    this.initToggle();
    this.initIdleSite();
    this.setPageGoogleAnalitics();
    this.toggleChat = {
      featureName: '',
      featureEnabled: false,
    };
  }

  async ngOnInit() {
    await this.mainService.initializeSecurityKeys();
  }

  private createCodeGoogleAnalytics() {
    const script = document.createElement('script');
    script.innerHTML =
      '(function (i, s, o, g, r, a, m) { ' +
      'i["GoogleAnalyticsObject"] = r; i[r] = i[r] || function () { ' +
      '(i[r].q = i[r].q || []).push(arguments) ' +
      '}, ' +
      'i[r].l = 1 * new Date(); a = s.createElement(o), ' +
      'm = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) ' +
      '}) ' +
      '(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga"); ' +
      'ga("create", ' +
      '"' +
      environment.codeGoogleAnalytics +
      '"' +
      ', "auto"); ';

    document.head.appendChild(script);
  }

  initToggle() {
    if (window.name !== '') {
      let infoNewFront = JSON.parse(atob(window.name));
      this.toggleService.setToggle(infoNewFront.toogleList);
    }
  }

  private initIdleSite() {
    // Sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(1);

    // Sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.TIMEOUT_SECONDS);

    // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.googleAnalyticsEventsService.emitEvent(
        'General',
        'Finaliza aplicación por inactividad',
        'Aplicación',
        Date.now()
      );

      this.user = this.userService.getUser();

      this.transactionAudit = {
        documentType: this.user.documentType,
        documentNumber: this.user.documentId + '',
        acceptanceText: undefined,
        acceptance: undefined,
        officeId: this.user.officeCode,
        description: this.stepperService.getSteps().toString(),
        eventCode: EventCodeEnum.CLOSE_SESSION_DUE_TO_INACTIVITY_STEP_ID,
        businessAdvisorNumber:
          this.user.sellerIdNumber !== undefined
            ? this.user.sellerIdNumber.toString()
            : '',
      };

      this.auditService
        .saveTransactionalAuditEvent(this.transactionAudit)
        .subscribe(
          () => {},
          () => {}
        );

      const dialog = this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'T',
          title: 'Lo sentimos',
          message:
            'Pasaron cinco minutos sin que completaras tu ingreso. Por tu seguridad te pedimos que vuelvas desde el inicio',
          url: '',
          textButtom: 'Volver',
          clearUser: false,
          feedback: true,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
        },
        500
      );

      dialog.afterClosed().subscribe(() => {
        this.idle.watch();
        this.idleState = 'Started!';
      });

      this.setLogout();
      this.idle.stop();
      this.idleState = 'Timed out!';
    });

    this.idle.onIdleStart.subscribe(
      () => (this.idleState = "You've gone idle!")
    );
    this.idle.onTimeoutWarning.subscribe(
      (countdown: any) =>
        (this.idleState = 'You will time out in ' + countdown + ' seconds!')
    );

    // Sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.idle.watch();
    this.idleState = 'Started!';

    if (typeof window.addEventListener === 'function') {
      window.addEventListener('scroll', this.preventRefresh);
    } else if (typeof window.addEventListener === 'object') {
      document.documentElement.addEventListener(
        'onscroll',
        this.preventRefresh
      );
    }
  }

  setLogout() {
    this.commonService.setLogout().subscribe(
      () => {},
      (error: any) => {
        // tslint:disable-next-line: no-console
        console.log(error);
      }
    );
  }

  preventRefresh(eve: any) {
    eve.preventDefault ? eve.preventDefault() : (eve.returnValue = false);
  }

  private setPageGoogleAnalitics() {
    // Events google analytics
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }
}
