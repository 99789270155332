import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.css']
})
export class SearchButtonComponent implements OnInit {
  @Output() eventSearch: EventEmitter<null> = new EventEmitter();

  showButton: boolean;
  showButtonLoading: boolean;
  showButtonSuccess: boolean;

  constructor() { }

  ngOnInit() {
    this.showButton = false;
    this.showButtonLoading = false;
    this.showButtonSuccess = false;
  }

  search() {
    this.eventSearch.emit();
  }

  changeViewButtons(_showButton: boolean, _showButtonLoading: boolean, _showButtonSuccess: boolean) {
    this.showButton = _showButton;
    this.showButtonLoading = _showButtonLoading;
    this.showButtonSuccess = _showButtonSuccess;
  }
}
