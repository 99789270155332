import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Course } from '../../interfaces/course.interface';
import { TrainingService } from '../../../shared/services/remote/training.service';

@Component({
  selector: 'app-pop-up-training',
  templateUrl: './pop-up-training.component.html',
  styleUrls: ['./pop-up-training.component.css']
})
export class PopUpTrainingComponent implements OnInit {

  courseDisplay: Course;
  courseDisplayIndex = 0;
  pageDisplay: any;
  pageDisplayIndex = 0;
  nextButton: boolean;
  understoodButton: boolean;

  constructor(
    private trainingService: TrainingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopUpTrainingComponent>) {
      this.courseDisplay = this.data.courses[0];
    }

  ngOnInit() {
    this.courseDisplay.pageResponse.length > 1 ? this.nextButton = true : this.understoodButton = true;
    this.pageDisplay = this.courseDisplay.pageResponse[0];
  }

  continue(): void {
    this.setPassedCourse(this.courseDisplay);
    if (this.data.courses.length === (this.courseDisplayIndex + 1)) {
      this.dialogRef.close();
    }
    else {
      this.courseDisplayIndex++;
      this.courseDisplay = this.data.courses[this.courseDisplayIndex];
      this.pageDisplayIndex = 0;
      this.pageDisplay = this.courseDisplay.pageResponse[this.pageDisplayIndex];
      if (this.courseDisplay.pageResponse.length > 1 && this.pageDisplayIndex !== (this.courseDisplay.pageResponse.length - 1)) {
        this.understoodButton = false;
        this.nextButton = true;
      } else {
        this.understoodButton = true;
        this.nextButton = false;
      }
    }
  }

  nextPage() {
    this.pageDisplayIndex ++;
    this.pageDisplay = this.courseDisplay.pageResponse[this.pageDisplayIndex];

    if (this.courseDisplay.pageResponse.length === this.pageDisplayIndex + 1) {
      this.nextButton = false;
      this.understoodButton = true;
    }
  }

  setPassedCourse(course: Course) {
    this.trainingService.savePassedCourse({
      idVendor: this.data.idVendor,
      idCourse: course.id,
      salesChannel: this.data.salesChannel,
      idRegion: this.data.idRegion,
      region: this.data.region,
      idZone: this.data.idZone,
      zone: this.data.zone,
      idOffice: this.data.idOffice,
      office: this.data.office,
      idAdministrativeOffice: this.data.idAdministrativeOffice,
      administrativeOffice: this.data.administrativeOffice
    }).subscribe(() => {}, error => this.savePassedCourseError(error));
  }

  savePassedCourseError(error: any) {
    console.log('PopUpTrainingComponent - setPassedCourse, Error: ' + JSON.stringify(error));
  }

}
