import { Injectable } from '@angular/core';

import { UserService } from './user.service';

@Injectable()
export class DecrimFilesService {
  decrimFiles: any[];

  constructor(private userService: UserService) {}

  public getDecrimFiles(): any[] {
    return this.decrimFiles;
  }

  public setDecrimFiles(decrimFiles: any[]) {
    this.decrimFiles = decrimFiles;
  }

  public async setExternalDecrimFiles() {
    const decrimFiles = [];
    const externalDecrimFiles = this.userService.getUser().decrimFiles;

    for (let index = 0; index < externalDecrimFiles.length; index++) {
      const externalDecrimFile = externalDecrimFiles[index];
      await fetch(externalDecrimFile.base64File)
        .then((res) => res.blob())
        .then((blob) => {
          decrimFiles[index] = new File([blob], externalDecrimFile.name, {
            type: externalDecrimFile.type,
          });
        });
    }

    this.decrimFiles = decrimFiles;
  }
}
