import { Injectable } from '@angular/core';
declare let ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {

  private clientId: string;
  public emitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }

  public saveClientId() {
    const that = this;
    ga(function(tracker: any) {
      that.clientId =  tracker.get('clientId');
    });
  }

  getClientId() {
    return this.clientId;
  }
}
