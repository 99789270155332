import { Component, OnInit } from '@angular/core';

import { DecisionEnum } from '../../../shared/enums/decision-type.enum';
import { RedirectNewFrontTypeEnum } from '../../../shared/enums/redirect-new-front-type.enum';
import { ToggleEnum } from '../../../shared/enums/toggle-type.enum';

import { CommonService } from '../../../shared/services/local/common.service';
import { CustomerFeedbackComponent } from '../../../shared/components/customer-feedback/customer-feedback.component';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { User } from '../../../shared/interfaces/user.interface';
import { UserService } from '../../../shared/services/local/user.service';

@Component({
  selector: 'app-success-credit',
  templateUrl: './success-credit.component.html',
  styleUrls: ['./success-credit.component.css'],
})
export class SuccessCreditComponent implements OnInit {
  set redirectNewFrontTypeEnum(redirectNewFrontTypeEnum) {}
  get redirectNewFrontTypeEnum() {
    return RedirectNewFrontTypeEnum;
  }

  documentListByUserType: any[] = [];
  isPhoneCall = false;
  showMessageLoadDocuments: boolean;
  user: User;

  constructor(
    private commonService: CommonService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
    this.isPhoneCall =
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL;
    this.initShowMessageLoadDocuments();
  }

  ngOnInit() {
    this.stepperService.setStep('SuccessCredit');
    this.googleAnalyticsEventsService.emitEvent(
      'Proceso Exitoso Aprobado',
      'Ingreso página proceso del crédito exitoso',
      'Página success-credit',
      Date.now()
    );
    this.documentListByUserType = this.userService.getDocumentListByUserType();
  }

  ok() {
    this.user.feedbackEnd = true;
    this.userService.setUser(this.user);
    this.userService.setDocumentListByUserType([]);

    this.googleAnalyticsEventsService.emitEvent(
      'Proceso Exitoso Aprobado',
      'Finalizar proceso exitoso de oferta',
      'Botón Volver a Mis Libranzas',
      Date.now()
    );

    this.commonService.showMesssageBox(
      CustomerFeedbackComponent,
      true,
      {
        clear: true,
        stepId: 4,
        stepDescription: 'Success Credit',
      },
      500
    );
  }

  showItemAuthenticationInOffice(): boolean {
    return this.userService.requireAuthenticationInOffice();
  }

  closeMessage() {
    this.showMessageLoadDocuments = false;
  }

  private initShowMessageLoadDocuments() {
    this.showMessageLoadDocuments = this.toggleService.getToggle(
      ToggleEnum.ONBASE_LOAD_DOCUMENT_FEATURE_TOGGLE
    ).featureEnabled;
  }
}
