// Imports
import 'hammerjs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatIconModule,
  MatRadioModule,
  MatDialogModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatSelectModule,
} from '@angular/material';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AtomSpinnerModule } from 'angular-epic-spinners';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PixelSpinnerModule } from 'angular-epic-spinners';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NguCarouselModule } from '@ngu/carousel';
import { RadarSpinnerModule } from 'angular-epic-spinners';
import { RecaptchaModule } from 'ng-recaptcha';
import { TextMaskModule } from 'angular2-text-mask';
import { BpopSecurityFrontendLibModule } from '@avaldigitallabs/bpop-security-frontend-lib';

import { Routing, RoutingProviders } from './app-routing.module';

// App
import { AppComponent } from './app.component';

//environment
import { environment } from '../environments/environment';

// Pages
import { AccountSelectionComponent } from './pages/master-page/account-selection/account-selection.component';
import { CalculatorComponent } from './pages/master-page/calculator/calculator.component';
import { CalculatorDetailComponent } from './pages/master-page/calculator/calculator-detail/calculator-detail.component';
import { CarouselStepsComponent } from './pages/master-page/carousel-steps/carousel-steps.component';
import { ConfirmOtpComponent } from './pages/master-page/confirm-otp/confirm-otp.component';
import { CreditDetailComponent } from './pages/master-page/credit-detail/credit-detail.component';
import { CreditPersonalizationComponent } from './pages/master-page/credit-personalization/credit-personalization.component';
import { DataClientComponent } from './pages/master-page/data-client/data-client.component';
import { DataClientMasterComponent } from './pages/master-page/data-client-master/data-client-master.component';
import { DataClientPepComponent } from './pages/master-page/data-client-pep/data-client-pep.component';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { OptComponent } from './pages/authentication/opt/opt.component';
import { PepComponent } from './pages/master-page/pep/pep.component';
import { RedirectFromNewFrontComponent } from './pages/redirect-from-new-front/redirect-from-new-front.component';
import { SalaryLoansOverviewComponent } from './pages/master-page/salary-loans-overview/salary-loans-overview.component';
import { SalaryLoansOverviewGraphComponent } from './pages/master-page/salary-loans-overview/salary-loans-overview-graph/salary-loans-overview-graph.component';
import { SalaryLoansOverviewValidationComponent } from './pages/master-page/salary-loans-overview/salary-loans-overview-validation/salary-loans-overview-validation.component';
import { SuccessCreditComponent } from './pages/master-page/success-credit/success-credit.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

// Shared Components
import { AcceptanceDataComponent } from './shared/components/acceptance-data/acceptance-data.component';
import { CaptchaComponent } from './shared/components/captcha/captcha.component';
import { CardsComponent } from './shared/components/cards/cards.component';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { CustomerFeedbackComponent } from './shared/components/customer-feedback/customer-feedback.component';
import { DecrimComponent } from './shared/components/decrim/decrim.component';
import { InsurabilityQuestionComponent } from './shared/components/insurability-question/insurability-question.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { PlatformBindingsComponent } from './shared/components/platform-bindings/platform-bindings.component';
import { PopUpCertificateComponent } from './shared/components/pop-up-certificate/pop-up-certificate.component';
import { PopUpInsuranceApprobationComponent } from './shared/components/pop-up-insurance-approbation/pop-up-insurance-approbation.component';
import { PopUpPepComponent } from './shared/components/pop-up/pop-up-pep.component';
import { PopUpTrainingComponent } from './shared/components/pop-up-training/pop-up-training.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { RnecComponent } from './shared/components/rnec/rnec.component';
import { SearchButtonComponent } from './shared/components/search-button/search-button.component';
import { SlideComponent } from './shared/components/slide/slide.component';
import { SliderComponent } from './shared/components/slider/slider.component';
import { SpecialOfferButtonComponent } from './shared/components/special-offer-button/special-offer-button.component';
import { TextAutocompleteComponent } from './shared/components/text-autocomplete/text-autocomplete.component';
import { TextDocumentComponent } from './shared/components/text-document/text-document.component';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';

// Services
// -> Local
import { AwsSignatureService } from './shared/services/local/aws-signature.service';
import { CipherService } from './shared/services/local/cipher.service';
import { CommonService } from './shared/services/local/common.service';
import { CreditFlowService } from './shared/services/local/credit-flow.service';
import { DecrimFilesService } from './shared/services/local/decrim-files.service';
import { EventAnalyticsService } from './shared/services/local/event-analytics.service';
import { MasterPageService } from './shared/services/local/master-page.service';
import { PayrollCheckService } from './shared/services/local/payroll-check.service';
import { PreselectService } from './shared/services/local/preselect.service';
import { RequestFileService } from './shared/services/local/request-file.service';
import { RiskManagerService } from './shared/services/remote/risk-manager.service';
import { SecurityService } from './shared/services/local/security.service';
import { StepperService } from './shared/services/local/stepper.service';
import { TabsService } from './shared/services/local/tabs.service';
import { ToggleService } from './shared/services/local/toggle.service';
import { UserService } from './shared/services/local/user.service';
import { ValidateResponseService } from './shared/services/local/validate-response.service';

// -> Remote
import { ApiService } from './shared/services/remote/api.service';
import { AuditService } from './shared/services/remote/audit.service';
import { AuthenticationService } from './shared/services/remote/authentication.service';
import { BackOfficeService } from './shared/services/remote/back-office.service';
import { BankInsuranceService } from './shared/services/remote/bank-insurance.service';
import { BurosService } from './shared/services/remote/buros.service';
import { CustomerService } from './shared/services/remote/customer.service';
import { EventService } from './shared/services/remote/event.service';
import { FileManagerService } from './shared/services/remote/file-manager.service';
import { GoogleAnalyticsEventsService } from './shared/services/remote/google-analytics-events.service';
import { InsurabilityService } from './shared/services/remote/insurability.service';
import { OrganizationManagerService } from './shared/services/remote/organization-manager.service';
import { PayrollPayerService } from './shared/services/remote/payroll-payer.service';
import { SecurityRemoteService } from './shared/services/remote/security-remote.service';
import { StrategyService } from './shared/services/remote/strategy.service';
import { TrailsRemoteService } from './shared/services/remote/trails-remote.service';
import { TrainingService } from './shared/services/remote/training.service';

// Access Guards
import { DocumentTypeAccessGuard } from './app.access-guards';

// Interceptors
import { AppInterceptor } from './core/interceptor/app-interceptor';

// Pipes
import { NoSanitizePipe } from './shared/pipes/no-sanitize.pipe';

// Mocks
import { AcceptanceDataMock } from './shared/components/acceptance-data/acceptance-data.component.mock';
import { SalaryLoansOverviewComponentMock } from './pages/master-page/salary-loans-overview/salary-loans-overview.component.mock';

@NgModule({
  imports: [
    AngularFontAwesomeModule,
    AtomSpinnerModule,
    BrowserModule,
    CurrencyMaskModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSidenavModule,
    MatToolbarModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    NguCarouselModule,
    NoopAnimationsModule,
    PixelSpinnerModule,
    RadarSpinnerModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(),
    Routing,
    TextMaskModule,
    BpopSecurityFrontendLibModule.forRoot({
      environment: environment.security,
    }),
  ],
  declarations: [
    AcceptanceDataMock,
    AcceptanceDataComponent,
    AccountSelectionComponent,
    AppComponent,
    CalculatorComponent,
    CalculatorDetailComponent,
    CaptchaComponent,
    CardsComponent,
    CarouselComponent,
    CarouselStepsComponent,
    ConfirmOtpComponent,
    CreditDetailComponent,
    CreditPersonalizationComponent,
    CustomerFeedbackComponent,
    DataClientComponent,
    DataClientMasterComponent,
    DataClientPepComponent,
    DecrimComponent,
    InsurabilityQuestionComponent,
    LoadingComponent,
    MasterPageComponent,
    NoSanitizePipe,
    OptComponent,
    PepComponent,
    PlatformBindingsComponent,
    PopUpCertificateComponent,
    PopUpInsuranceApprobationComponent,
    PopUpPepComponent,
    PopUpTrainingComponent,
    ProgressBarComponent,
    RedirectFromNewFrontComponent,
    RnecComponent,
    SalaryLoansOverviewComponent,
    SalaryLoansOverviewComponentMock,
    SalaryLoansOverviewGraphComponent,
    SalaryLoansOverviewValidationComponent,
    SearchButtonComponent,
    SlideComponent,
    SliderComponent,
    SpecialOfferButtonComponent,
    SuccessCreditComponent,
    TextAutocompleteComponent,
    TextDocumentComponent,
    UploadFileComponent,
    WelcomeComponent,
  ],
  entryComponents: [
    AccountSelectionComponent,
    CalculatorComponent,
    DecrimComponent,
    InsurabilityQuestionComponent,
    PopUpInsuranceApprobationComponent,
    PlatformBindingsComponent,
    PopUpCertificateComponent,
    PopUpPepComponent,
    PopUpTrainingComponent,
    RnecComponent,
    UploadFileComponent,
  ],
  exports: [NgbModule],
  providers: [
    ApiService,
    AuditService,
    AuthenticationService,
    AwsSignatureService,
    BackOfficeService,
    BankInsuranceService,
    BurosService,
    CipherService,
    CommonService,
    CreditFlowService,
    CustomerService,
    DecrimFilesService,
    DocumentTypeAccessGuard,
    EventAnalyticsService,
    EventService,
    FileManagerService,
    GoogleAnalyticsEventsService,
    InsurabilityService,
    MasterPageService,
    OrganizationManagerService,
    PayrollCheckService,
    PayrollPayerService,
    PreselectService,
    RequestFileService,
    RiskManagerService,
    RoutingProviders,
    SecurityRemoteService,
    SecurityService,
    StepperService,
    StrategyService,
    TabsService,
    ToggleService,
    TrailsRemoteService,
    TrainingService,
    UserService,
    ValidateResponseService,
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
