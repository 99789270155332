import { Component, OnInit } from '@angular/core';

import { DataClientType } from '../../../shared/enums/data-client-type.enum';

@Component({
  selector: 'app-data-client-master',
  templateUrl: './data-client-master.component.html',
  styleUrls: ['./data-client-master.component.css']
})
export class DataClientMasterComponent implements OnInit {
  type: DataClientType;

  constructor() {
    this.type = DataClientType.BYMENU;
  }

  ngOnInit() {
  }
}
