import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class InsurabilityService {

  constructor(private httpClient: HttpClient) { }

  lifeInsurance(insurabilityAnswer: any): Observable<object> {
    return this.httpClient.post(environment.serverUrl + 'customers/life-insurance', insurabilityAnswer);
  }
}
