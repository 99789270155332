import { LoadingComponent } from '../../../components/loading/loading.component';

import { ValidateAuthenticationCertihuella } from './validate-authentication-certihuella';
import { ValidateAuthenticationDecrim } from './validate-authentication-decrim';

import { IValidateAuthentication } from './validate-authentication.interface';

import { ValidationMethodEnum } from '../../../enums/validation-method.enum';

import { AuthenticationService } from '../../remote/authentication.service';
import { CommonService } from '../common.service';
import { EventAnalyticsService } from '../event-analytics.service';
import { UserService } from '../user.service';

export class ValidateAuthenticationStrategyService {
  context: IValidateAuthentication;

  constructor(
    private appLoading: LoadingComponent,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private eventAnalyticsService: EventAnalyticsService,
    private userService: UserService
  ) {}

  getValidateAuthenticationType(
    type: ValidationMethodEnum
  ): IValidateAuthentication {
    return type === ValidationMethodEnum.DECRIM
      ? new ValidateAuthenticationDecrim(
          this.appLoading,
          this.authenticationService,
          this.commonService,
          this.eventAnalyticsService,
          this.userService
        )
      : new ValidateAuthenticationCertihuella(
          this.appLoading,
          this.authenticationService,
          this.commonService,
          this.eventAnalyticsService,
          this.userService
        );
  }
}
