// Imports
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EventCodeEnum } from '../../../shared/enums/event-code.enum';

import { User } from '../../../shared/interfaces/user.interface';

import { AuditService } from '../../../shared/services/remote/audit.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

import { CommonService } from '../../../shared/services/local/common.service';
import { DecrimFilesService } from '../../../shared/services/local/decrim-files.service';
import { EventAnalyticsService } from '../../../shared/services/local/event-analytics.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';

import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

@Component({
  selector: 'app-pep',
  templateUrl: './pep.component.html',
  styleUrls: ['./pep.component.css'],
})
export class PepComponent implements OnInit {
  externalUser: any;
  questionResponse1: string;
  questionResponse2: string;
  questionResponse3: string;
  questionResponse4: string;
  user: User;

  question1 =
    '¿Eres una persona expuesta políticamente (PEP) o tienes vínculo conyugal, familiar o asociado con una persona PEP?';
  question2 =
    '¿Eres responsable de impuestos o residente fiscal en EE.UU, u otro país diferente de Colombia?';
  question3 = '¿Realizas operaciones internacionales?';
  question4 =
    '¿Eres miembro de la Junta Directiva, representante legal, accionista o directivo del Banco? ¿Alguno de tus familiares lo es?';

  public options = ['Si', 'No'];

  constructor(
    private auditService: AuditService,
    private commonService: CommonService,
    private decrimFilesService: DecrimFilesService,
    private eventAnalyticsService: EventAnalyticsService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    // Redirect of redesign
    if (this.userService.getUser().accessKey === undefined) {
      if (window.name === '') {
        this.router.navigate(['/welcome']);
      } else {
        let infoNewFront = JSON.parse(atob(window.name));
        this.externalUser = infoNewFront.externalUser;
        this.toggleService.setToggle(infoNewFront.toogleList);
      }

      window.name = '';
    }
  }

  async ngOnInit() {
    await this.initUser();

    this.stepperService.setStep('Pep');
    this.user = this.userService.getUser();
    this.googleAnalyticsEventsService.emitEvent(
      'Declaración Peps',
      'Ingreso página declaración de peps',
      'Página pep',
      Date.now()
    );
  }

  async initUser() {
    if (this.userService.getUser().accessKey === undefined) {
      await this.userService.setExternalUser(this.externalUser);
    }

    this.user = this.userService.getUser();

    if (this.user.decrimFiles) {
      await this.decrimFilesService.setExternalDecrimFiles();
    }
  }

  getBackUrl(): string {
    return 'master-page/salary-loans-overview';
  }

  public sendAcceptanceData() {
    this.sendEvents();

    if (this.questionResponse1 === 'Si' || this.questionResponse2 === 'Si') {
      this.googleAnalyticsEventsService.emitEvent(
        'Declaración Peps',
        this.questionResponse2 === 'Si'
          ? 'Cliente declara ser un US_GAP'
          : 'Cliente declara ser un PEP',
        'Popups',
        Date.now()
      );

      this.messagebox(
        'Para continuar necesitamos actualizar tus datos',
        'Acércarte a una oficina para completar los formularios requeridos y validar la información que nos acabas de suministrar'
      );
    } else if (this.questionResponse3 === 'Si') {
      this.googleAnalyticsEventsService.emitEvent(
        'Declaración Peps',
        'Cliente declara realizar operaciones internacionales',
        'Popups',
        Date.now()
      );

      this.messagebox(
        'Necesitamos actualizar tus datos',
        'Acércarte a una oficina para completar y validar la información que nos acabas de suministrar'
      );
    } else if (this.questionResponse4 === 'Si') {
      this.googleAnalyticsEventsService.emitEvent(
        'Declaración Peps',
        'Cliente declara ser de Junta',
        'Popups',
        Date.now()
      );

      this.messagebox(
        'Tu caso es especial para nosotros',
        'Debido a tu relación con el Banco no podemos aprobarte el crédito por esta vía. Por favor acércate a una oficina para completar los formularios necesarios'
      );
    } else if (
      this.questionResponse1 === 'No' &&
      this.questionResponse2 === 'No' &&
      this.questionResponse3 === 'No' &&
      this.questionResponse4 === 'No'
    ) {
      this.googleAnalyticsEventsService.emitEvent(
        'Declaración Peps',
        'Cliente declara no ser un PEP, no ser un US_GAP, no realizar operaciones internacionales y no ser de Junta',
        'Continuar',
        Date.now()
      );

      this.router.navigate(['/master-page/data-client-pep']);
    }
  }

  public showToolTipInternationalOperations() {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: '',
        title: '¿Que son operaciones internacionales?',
        message:
          'Son los movimientos financieros que realizas en otros países a través de cuentas en el exterior a tu nombre. No son compras por internet, suscripciones a servicios o Remesas/Giros.',
        url: '',
        textButtom: 'Entendí',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  private messagebox(title: string, message: string) {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'C',
        title: title,
        message: message,
        url: '',
        textButtom: 'Entendido',
        clearUser: false,
        feedback: true,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  private sendEvents() {
    if (this.questionResponse1 !== undefined) {
      this.auditory(
        this.questionResponse1 === 'Si'
          ? EventCodeEnum.PEP_AFFIRMATIVE_DECLARATION
          : EventCodeEnum.PEP_NEGATIVE_DECLARATION
      );

      this.eventAnalyticsService.sendEventAnalytics(
        EventCodeEnum.PEP_DECLARATION,
        this.questionResponse1
      );
    }

    if (this.questionResponse2 !== undefined) {
      this.auditory(
        this.questionResponse2 === 'Si'
          ? EventCodeEnum.US_GAP_AFFIRMATIVE_DECLARATION
          : EventCodeEnum.US_GAP_NEGATIVE_DECLARATION
      );

      this.eventAnalyticsService.sendEventAnalytics(
        EventCodeEnum.US_GAP_DECLARATION,
        this.questionResponse2
      );
    }

    if (this.questionResponse3 !== undefined) {
      this.auditory(
        this.questionResponse3 === 'Si'
          ? EventCodeEnum.INTERNATIONAL_OPERATIONS_AFFIRMATIVE_DECLARATION
          : EventCodeEnum.INTERNATIONAL_OPERATIONS_NEGATIVE_DECLARATION
      );

      this.eventAnalyticsService.sendEventAnalytics(
        EventCodeEnum.INTERNATIONAL_OPERATIONS_DECLARATION,
        this.questionResponse3
      );
    }

    if (this.questionResponse4 !== undefined) {
      this.auditory(
        this.questionResponse4 === 'Si'
          ? EventCodeEnum.BOARD_MEMBER_AFFIRMATIVE_DECLARATION
          : EventCodeEnum.BOARD_MEMBER_NEGATIVE_DECLARATION
      );

      this.eventAnalyticsService.sendEventAnalytics(
        EventCodeEnum.BOARD_MEMBER_DECLARATION,
        this.questionResponse4
      );
    }
  }

  private auditory(eventCode: EventCodeEnum) {
    this.auditService
      .saveTransactionalAuditEvent({
        documentType: this.user.documentType,
        documentNumber: this.user.documentId + '',
        acceptanceText: undefined,
        acceptance: undefined,
        officeId: this.user.officeCode,
        description: this.stepperService.getSteps().toString(),
        eventCode: eventCode,
        businessAdvisorNumber: this.user.sellerIdNumber.toString(),
      })
      .subscribe(
        () => {},
        (error) => this.saveTransactionalAuditEventError(error)
      );
  }

  private saveTransactionalAuditEventError(error: any) {
    console.log(
      'CreditPersonalizacion - saveTransactionalAuditEventError, Error: ' +
        JSON.stringify(error)
    );
  }
}
