import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '../../interfaces/user.interface';

import { ApiService } from '../../services/remote/api.service';
import { GoogleAnalyticsEventsService } from '../../services/remote/google-analytics-events.service';
import { SecurityService } from '../../services/local/security.service';
import { UserService } from '../../services/local/user.service';

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.css']
})
export class CustomerFeedbackComponent implements OnInit {
  qualification: string;
  feedback: string;
  user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private dialogRef: MatDialogRef<CustomerFeedbackComponent>,
    private router: Router,
    private securityService: SecurityService,
    private userService: UserService) {
    this.user = this.userService.getUser();
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent('Retroalimentación Cliente', 'Ingreso página retroalimentación del cliente', 'Página customer-feedback', Date.now());

    this.qualification = '';
    this.feedback = '';
  }

  send() {
    this.googleAnalyticsEventsService.emitEvent('Retroalimentación Cliente', 'Envia retroalimentación', 'Botón Enviar Calificación', Date.now());

    const clientId = this.user.federatedAuthClientId;

    if (this.data.clear) {
      this.userService.clearUser();
    }

    this.apiService.pushFeedback({
      score: this.qualification,
      commentRating: this.feedback,
      identificationType: this.user.documentType,
      identificationNumber: this.user.documentId,
      stepId: this.data.stepId,
      stepDescription: this.data.stepDescription
    }).subscribe(() => {}, error => this.sendError(error));

    this.dialogRef.close();
    this.securityService.federatedAuthEnabled()
      ? this.securityService.closeFederateAuthentication(clientId)
      : this.router.navigate(["/welcome"]);
  }

  private sendError(error: any) {
    console.log('CustomerFeedbackComponent - sendError, Error: ' + JSON.stringify(error));
  }
}
