import { throwError as observableThrowError, Observable } from 'rxjs';

import { delay, retryWhen, map, mergeMap, tap } from 'rxjs/operators';
// Interfaces
import { ICreditFlowFactory } from './Icredit-flow-factory.interface';

import { BurosService } from '../../../remote/buros.service';
import { CustomerService } from '../../../remote/customer.service';
import { Preselect } from '../../../../interfaces/preselect.interface';
import { PreselectService } from '../../preselect.service';
import { User } from '../../../../interfaces/user.interface';
import { SimulationRequestInterface } from '../../../../interfaces/simulation-request.interface';
import { CampaignType } from '../../../../enums/campaign-type.enum';
import { ModalityType } from '../../../../enums/modality-type.enum';

import { CustomerDecisionEnum } from '../../../../enums/customer-decision-type.enum';
import { CustomerTypeEnum } from '../../../../enums/customer-type.enum';

export class CreditFlowAnyFee implements ICreditFlowFactory {
  private retryCount: number;
  private retryMax: number;
  private retryDelay: number;

  constructor(
    private burosService: BurosService,
    private customerService: CustomerService,
    private preselectService: PreselectService
  ) {
    this.retryMax = 30;
    this.retryDelay = 4 * 1000;
  }

  getPreselect(
    _preselect: Preselect,
    _user: User,
    modalityType: string,
    processType: string
  ): Observable<object> {
    return new Observable<object>((obj) => {
      let campaign = null;
      if (modalityType === ModalityType.LOYALTY.toString()) {
        campaign = _preselect.customerInBureauTrail
          ? CampaignType.BUREAU_TRAIL_BASE
          : _preselect.almostPaidOff
          ? CampaignType.ALMOST_PAID_OFF
          : null;
      }

      const recalculateRequest: SimulationRequestInterface = {
        documentType: _user.documentType,
        documentNumber: _user.documentId.toString(),
        obligationId: _preselect.obligationId,
        requiredAmount: 0,
        loanTerm: 0,
        payrollCheckProcessId: _user.transactionId,
        modalityType: modalityType,
        campaignType: campaign,
        processType: processType,
        clientType:
          _user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
            ? CustomerTypeEnum.NEW_KNOWN_CUSTOMER
            : undefined,
        payerNit: _preselect.payerNit,
        sectorNumber: _preselect.sectorNumber,
      };

      this.burosService.searchBurosCustomerAnyFee(recalculateRequest).subscribe(
        (data) => obj.next(this.searchBurosCustomerAnyFeeSuccess(data, _user)),
        (error) => obj.error(this.searchBurosCustomerAnyFeeError(error))
      );
    }).pipe(
      tap(
        (event) => {
          return event;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  private searchBurosCustomerAnyFeeSuccess(data: any, _user: User) {
    const preselect = this.preselectService.getPreselect();
    preselect.maxLoanAmount = data.maxLoanAmount;
    preselect.minLoanAmount = data.minLoanAmount;
    preselect.maxLoanTerm = data.maxLoanTerm;
    preselect.minLoanTerm = data.minLoanTerm;
    preselect.interestRate = data.interestRate;
    preselect.feeAmount = data.feeAmount;
    preselect.loanQuota = data.feeAmount;
    preselect.documentType = _user.documentType;
    preselect.documentNumber = _user.documentId;
    preselect.anualRate = data.anualRate;
    preselect.nominalRate = data.nominalRate;
    preselect.obligationBalance = data.obligationBalance;
    preselect.maxFeeNumberRule = data.maxFeeNumberRule;
    this.preselectService.setPreselect(preselect);

    return data.responseCode;
  }

  private searchBurosCustomerAnyFeeError(error) {
    return error;
  }

  getCalculateValues(
    _preselect: Preselect,
    _user: User,
    _value: number,
    _term: number,
    modalityType: string,
    processType: string
  ): Observable<object> {
    let campaign = null;
    if (modalityType === ModalityType.LOYALTY.toString()) {
      campaign = _preselect.customerInBureauTrail
        ? CampaignType.BUREAU_TRAIL_BASE
        : _preselect.almostPaidOff
        ? CampaignType.ALMOST_PAID_OFF
        : null;
    }

    const recalculateRequest: SimulationRequestInterface = {
      documentType: _user.documentType,
      documentNumber: _user.documentId.toString(),
      obligationId: _preselect.obligationId,
      requiredAmount:
        _value === 0
          ? 0
          : _value + Math.ceil(_preselect.obligationBalance / 1000) * 1000,
      loanTerm: _term,
      payrollCheckProcessId: _user.transactionId,
      modalityType: modalityType,
      campaignType: campaign,
      processType: processType,
      clientType:
        _user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
          ? CustomerTypeEnum.NEW_KNOWN_CUSTOMER
          : undefined,
      payerNit: _preselect.payerNit,
      sectorNumber: _preselect.sectorNumber,
    };

    return this.customerService
      .searchRecalculateValuesAsync(recalculateRequest)
      .pipe(
        mergeMap((data: any) =>
          this.askForRecalculateAnswer(
            data.idSimulation,
            recalculateRequest,
            _preselect
          )
        )
      );
  }

  private askForRecalculateAnswer(
    simulateTicket: any,
    request: any,
    _preselect: Preselect
  ) {
    this.retryCount = 0;

    const askForREcalculatedValuesRequest = {
      documentType: request.documentType,
      documentNumber: request.documentNumber,
      obligationId: _preselect.obligationId,
      simulateTicket: simulateTicket,
    };

    return this.customerService
      .askForRecalculateValuesAsyncAnswer(askForREcalculatedValuesRequest)
      .pipe(
        map((data) =>
          this.updatePreselectOnSuccess(
            data,
            _preselect,
            request.requiredAmount === 0
          )
        ),
        retryWhen((attemps) =>
          attemps.pipe(
            map((error) =>
              error.error.code === 'PayrollSimulation010' &&
              ++this.retryCount < this.retryMax
                ? error
                : observableThrowError(error)
            ),
            delay(this.retryDelay)
          )
        )
      );
  }

  private updatePreselectOnSuccess(
    data: any,
    _preselect: Preselect,
    firstCall: boolean
  ) {
    _preselect.obligationBalance = data.obligationBalance;
    _preselect.feeAmount = data.feeAmount;
    _preselect.interestRate = data.interestRate;
    _preselect.nominalRate = data.nominalRate;
    _preselect.creditStudy = data.creditReview;
    _preselect.adjustmentInterests = data.interestAdjustment;
    _preselect.adjustmentInsurancePremium = data.lifeInsuranceAdjustment;
    _preselect.advancedInterests = data.interestAdvanced;
    _preselect.advancedInsurancePremium = data.lifeInsuranceAdvanced;
    _preselect.discounts =
      data.creditReview + data.interestAdvanced + data.lifeInsuranceAdvanced;
    _preselect.disbursement = data.disbursementAmount;
    if (firstCall) {
      _preselect.maxLoanAmount = data.maxLoanAmount;
      _preselect.loanQuota = data.feeAmount;
    }

    return _preselect;
  }
}
