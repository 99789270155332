import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'underscore';

@Component({
  selector: 'app-pop-up-insurance-approbation',
  templateUrl: './pop-up-insurance-approbation.component.html',
  styleUrls: ['./pop-up-insurance-approbation.component.css'],
})
export class PopUpInsuranceApprobationComponent {
  aditionalAmount: boolean;
  aditionalAmountCheckboxEnable: boolean;
  chkDisabled: any;
  groupedCards: any = [];
  insuranceDetail: Array<any> = new Array();
  insuranceOffer: any;
  numberOfCards = 2;
  planList: any[];
  spouse: boolean;
  spouseCheckboxEnable: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: any[],
    public dialogRef: MatDialogRef<PopUpInsuranceApprobationComponent>
  ) {
    this.preProcessData();
    this.changeNameKeyData();
    this.setInsuranceOffer(this.data[0]);
    this.setGroupCards();
    this.chkDisabled = { aditionalAmount: false, spouse: false };
  }

  setOffert(id: any) {
    this.setInsuranceOffer(this.findOffer(id));
  }

  onAcceptButtonClick(desition: boolean) {
    this.insuranceOffer.desition = desition;
    this.dialogRef.close(this.insuranceOffer);
  }

  setGroupCards() {
    for (let i = 0; i < this.planList.length; i += this.numberOfCards) {
      this.groupedCards.push(this.planList.slice(i, i + this.numberOfCards));
    }
  }

  private setInsuranceOffer(offer) {
    this.insuranceOffer = offer;
    this.spouse = this.insuranceOffer.spouseCoveraged;
    this.aditionalAmount = this.insuranceOffer.maxCoverage;
    this.insuranceDetail = [];

    this.insuranceOffer.description.forEach((cov) => {
      this.insuranceDetail.push(cov.description.split('*'));
    });
  }

  private changeNameKeyData() {
    this.planList = this.data;

    for (const plan of this.planList) {
      plan.id = plan.code ? plan.code : plan.id;
      plan.description = plan.coverages ? plan.coverages : plan.description;

      delete plan.code;
      delete plan.coverages;
    }
  }

  private preProcessData() {
    this.spouseCheckboxEnable = this.shouldBeCheckboxEnabled(
      this.data,
      'spouseCoveraged'
    );
    this.aditionalAmountCheckboxEnable = this.shouldBeCheckboxEnabled(
      this.data,
      'maxCoverage'
    );
  }

  private shouldBeCheckboxEnabled(data, property) {
    let hasTrue = false;
    let hasFalse = false;
    data.forEach((element) => {
      const propertyValue = element[property];
      if (propertyValue === true) {
        hasTrue = true;
      } else {
        hasFalse = true;
      }
    });
    return hasTrue && hasFalse;
  }

  private findOffer(code: string) {
    return _.findWhere(this.data, {
      id: code,
    });
  }
}
