// Imports
import { ModuleWithProviders } from '@angular/core';
import {
  RouterModule,
  Routes,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

// Guards
import { DocumentTypeAccessGuard } from './app.access-guards';

// Declarations
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { PepComponent } from './pages/master-page/pep/pep.component';
import { OptComponent } from './pages/authentication/opt/opt.component';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { DataClientComponent } from './pages/master-page/data-client/data-client.component';
import { CreditDetailComponent } from './pages/master-page/credit-detail/credit-detail.component';
import { SuccessCreditComponent } from './pages/master-page/success-credit/success-credit.component';
import { DataClientPepComponent } from './pages/master-page/data-client-pep/data-client-pep.component';
import { AcceptanceDataComponent } from './shared/components/acceptance-data/acceptance-data.component';
import { CustomerFeedbackComponent } from './shared/components/customer-feedback/customer-feedback.component';
import { DataClientMasterComponent } from './pages/master-page/data-client-master/data-client-master.component';
import { SalaryLoansOverviewComponent } from './pages/master-page/salary-loans-overview/salary-loans-overview.component';
import { CreditPersonalizationComponent } from './pages/master-page/credit-personalization/credit-personalization.component';
import { AccountSelectionComponent } from './pages/master-page/account-selection/account-selection.component';
import { ConfirmOtpComponent } from './pages/master-page/confirm-otp/confirm-otp.component';
import { RedirectFromNewFrontComponent } from './pages/redirect-from-new-front/redirect-from-new-front.component';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  {
    path: 'loading',
    component: LoadingComponent,
    canActivate: [DocumentTypeAccessGuard],
  },
  {
    path: 'acceptance-data',
    canActivate: [DocumentTypeAccessGuard],
    children: [{ path: 'first', component: AcceptanceDataComponent }],
  },
  {
    path: 'authentication/otp',
    component: OptComponent,
    canActivate: [DocumentTypeAccessGuard],
  },
  {
    path: 'customer-feedback',
    component: CustomerFeedbackComponent,
    canActivate: [DocumentTypeAccessGuard],
  },
  {
    path: 'redirect-from-new-front',
    component: RedirectFromNewFrontComponent,
  },
  {
    path: 'master-page',
    component: MasterPageComponent,
    children: [
      {
        path: 'peps',
        component: PepComponent,
      },
      {
        path: 'data-client',
        component: DataClientComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'credit-detail',
        component: CreditDetailComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'success-credit',
        component: SuccessCreditComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'data-client-pep',
        component: DataClientPepComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'data-client-master',
        component: DataClientMasterComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'salary-loans-overview',
        component: SalaryLoansOverviewComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'credit-personalization',
        component: CreditPersonalizationComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'account-selection',
        component: AccountSelectionComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
      {
        path: 'confirm-otp',
        component: ConfirmOtpComponent,
        canActivate: [DocumentTypeAccessGuard],
      },
    ],
  },
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(routes);

export const RoutingProviders = [
  {
    provide: 'boardGuard',
    useValue: boardGuard,
  },
];

export function boardGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) {
  return true;
}
