import * as encoding from 'text-encoding';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class CipherService {
  constructor() { }

  cipherStringRSAOAEP512(message: string): Observable<string> {
    return new Observable((observer) => {
      window.crypto.subtle.importKey(
        'jwk',
        {
          kty: 'RSA',
          e: 'AQAB',
          n: environment.rsaOaep512PublicKey,
          alg: 'RSA-OAEP-512',
          ext: true,
        },
        {
          name: 'RSA-OAEP',
          hash: { name: 'SHA-512' },
        },
        false,
        ['encrypt']
      ).then((key) => {
        window.crypto.subtle.encrypt(
          {
            name: 'RSA-OAEP',
          },
          key,
          new encoding.TextEncoder().encode(message)
        ).then((encrypted) => {
          let binary = '';

          const myBytes = (new Uint8Array(encrypted));

          for (let i = 0; i < myBytes.byteLength; i++) {
            binary += String.fromCharCode(myBytes[i]);
          }

          observer.next(window.btoa(binary));
          observer.complete();
        });
      });
    });
  }
}
