import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { isNumeric } from 'rxjs/util/isNumeric';
import * as _ from 'underscore';

import { CustomerDecisionEnum } from '../../../shared/enums/customer-decision-type.enum';
import { CustomerTypeEnum } from '../../../shared/enums/customer-type.enum';
import { DecisionEnum } from '../../../shared/enums/decision-type.enum';
import { EventCodeEnum } from '../../../shared/enums/event-code.enum';
import { InsurabilityResponseEnum } from '../../../shared/enums/insurability-response.enum';

import { Event } from '../../../shared/interfaces/event.interface';
import { InsuranceData } from '../../../shared/interfaces/insurance-data.interface';
import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { User } from '../../../shared/interfaces/user.interface';
import { ValidateResponse } from '../../../shared/interfaces/validate-response.interface';

import { InsurabilityQuestionComponent } from '../../../shared/components/insurability-question/insurability-question.component';
import { PopUpInsuranceApprobationComponent } from '../../../shared/components/pop-up-insurance-approbation/pop-up-insurance-approbation.component';
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

import { CommonService } from '../../../shared/services/local/common.service';
import { EventAnalyticsService } from '../../../shared/services/local/event-analytics.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';
import { ValidateResponseService } from '../../../shared/services/local/validate-response.service';

import { BankInsuranceService } from '../../../shared/services/remote/bank-insurance.service';
import { CustomerService } from '../../../shared/services/remote/customer.service';
import { EventService } from '../../../shared/services/remote/event.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

@Component({
  selector: 'app-credit-detail',
  templateUrl: './credit-detail.component.html',
  styleUrls: ['./credit-detail.component.css'],
})
export class CreditDetailComponent implements OnInit, OnDestroy {
  @ViewChild('chkAccept', { static: false }) chkAcc: any;
  @ViewChild('chkOtherValue', { static: false }) chkVal: any;
  @ViewChild('txtOtherValueCredit', { static: false }) txtOthVal: ElementRef;

  insuranceData: InsuranceData;
  preselect: Preselect;
  user: User;
  validateResponse: ValidateResponse;

  acceptanceContent: string;
  acceptanceDataObject: object;
  acceptedTermsAndCoditions = false;
  bankingInsuranceToggle: any;
  disableOtherValue: boolean;
  finalDisbursement: number;
  insuranceApprobation = true;
  insuranceOfferArray = [];
  insuranceShow = false;
  isCustomerNewPayrollLoan: boolean;
  loading: boolean;
  minimunRequestedAmount: number;
  selectIndex = 0;
  selectedInsurance: any;
  showAcceptanceData = false;
  showDetailTable = false;
  totalDiscounts: number;
  valErrorMax: boolean;
  valErrorMin: boolean;
  valErrorZero: boolean;
  valValue: boolean;

  private dialogRef;
  private event: Event;

  constructor(
    private bankInsuranceService: BankInsuranceService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private eventService: EventService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService,
    private validateResponseService: ValidateResponseService
  ) {
    this.user = this.userService.getUser();
    this.validateResponse = this.validateResponseService.getResponse();
    this.acceptanceContent = this.getTextAcceptanceContent();
    this.preselect = this.preselectService.getPreselectPersonalized();
    this.isCustomerNewPayrollLoan = this.userService.isCustomerNewPayrollLoan();
    this.initBankInsurance();
  }

  ngOnInit() {
    this.stepperService.setStep('CreditDetail');
    this.googleAnalyticsEventsService.emitEvent(
      // tslint:disable-next-line:no-duplicate-string
      'Personalizar Oferta',
      'Ingreso página detalle crédito',
      'Página credit-detail',
      Date.now()
    );

    this.disableOtherValue = false;

    const interestRatePlusOne = this.preselect.nominalRate + 1;
    this.preselect.anualRate = Number(
      (Math.pow(interestRatePlusOne, 12) - 1).toFixed(4)
    );
    this.minimunRequestedAmount = this.preselect.requesAmount * 0.8;

    if (this.minimunRequestedAmount < this.preselect.minLoanAmount) {
      this.minimunRequestedAmount = this.preselect.minLoanAmount;
    }

    this.preselect.otherAmountAccepted = this.minimunRequestedAmount;
    this.ngBlurText();
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngBlurText() {
    this.googleAnalyticsEventsService.emitEvent(
      'Personalizar Oferta',
      'Acepta menor valor',
      'Texto Menor Valor',
      Date.now()
    );

    this.valValue = false;
    this.valErrorMax = false;
    this.valErrorMin = false;
    this.valErrorZero = false;

    if (this.preselect.otherAmountAccepted === 0) {
      this.valErrorZero = true;
    } else if (
      this.preselect.otherAmountAccepted >= this.preselect.minLoanAmount &&
      this.preselect.otherAmountAccepted <= this.preselect.requesAmount
    ) {
      this.valValue = true;

      const roundValue = Math.floor(this.preselect.otherAmountAccepted / 1000);
      if (roundValue !== this.preselect.otherAmountAccepted / 1000) {
        this.preselect.otherAmountAccepted = roundValue * 1000 + 1000;
      }
    } else if (
      this.preselect.otherAmountAccepted !== 0 &&
      this.preselect.otherAmountAccepted !== null &&
      this.preselect.otherAmountAccepted > this.preselect.requesAmount
    ) {
      this.valErrorMax = true;
    } else if (
      this.preselect.otherAmountAccepted !== 0 &&
      this.preselect.otherAmountAccepted !== null &&
      this.preselect.otherAmountAccepted < this.preselect.minLoanAmount
    ) {
      this.valErrorMin = true;
    }
  }

  otherValue() {
    this.disableOtherValue = !this.chkVal.checked;
    this.preselect.otherAmountAccepted = this.minimunRequestedAmount;
    this.ngBlurText();

    if (!this.chkVal.checked) {
      this.googleAnalyticsEventsService.emitEvent(
        'Personalizar Oferta',
        'No acepta menor valor',
        'Check Solo Acepta Valor',
        Date.now()
      );
      this.preselect.otherAmountAccepted = null;
      this.valValue = false;
      this.valErrorMax = false;
      this.valErrorMin = false;
    }
  }

  insuranceApprovationAction() {
    this.dialogRef = this.commonService.showMesssageBox(
      PopUpInsuranceApprobationComponent,
      true,
      this.insuranceOfferArray,
      800,
      500
    );

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.updateSelectedInsurance({
        code: result.desition ? result.id : 'CUSTOMER_DOES_NOT_ACCEPT_OFFER',
        desition: result.desition,
        maxCoverage: result.maxCoverage,
        price: result.price,
        spouseCoveraged: result.spouseCoveraged,
      });

      this.selectIndex = this.findIndexInsurancensuraceSelect(result.id);
    });
  }

  acceptedTerms() {
    if (!this.chkAcc.checked) {
      this.googleAnalyticsEventsService.emitEvent(
        'Personalizar Oferta',
        'Acepta términos y condiciones',
        'Check Términos y Condiciones',
        Date.now()
      );
    } else {
      this.googleAnalyticsEventsService.emitEvent(
        'Personalizar Oferta',
        'No acepta términos y condiciones',
        'Check Términos y Condiciones',
        Date.now()
      );
    }
  }

  openTermsAndCoditions() {
    this.acceptanceDataObject = {
      titleBigGreen: '',
      titleMediumGray: 'Términos y Condiciones Crédito de Libranza Prestayá:',
      subTitle: '',
      content: this.acceptanceContent,
      showCloseButton: true,
      showCloseAcceptance: true,
      showCloseNotAcceptance: false,
    };

    this.showAcceptanceData = true;
  }

  acceptData() {
    this.acceptedTermsAndCoditions = true;
    this.showAcceptanceData = false;
  }

  noAcceptData() {
    this.showAcceptanceData = false;
  }

  closeAcceptanceData() {
    this.showAcceptanceData = false;
  }

  showDetailTableClick() {
    this.showDetailTable = true;
  }

  showObligationBalance() {
    return !this.isCustomerNewPayrollLoan;
  }

  showTotalAmountText() {
    return this.valValue && this.showObligationBalance();
  }

  keyUp() {
    if (
      !isNumeric(
        this.txtOthVal.nativeElement.value
          .replace('$', '')
          .replace(/[.]/gi, '')
          .replace(/ /g, '')
      )
    ) {
      this.txtOthVal.nativeElement.value = '';
    }
  }

  getTextAcceptanceContent() {
    let textAcceptanceContent =
      this.commonService.getAcceptanceTermConditions();

    if (
      this.user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
    ) {
      textAcceptanceContent =
        this.commonService.getAcceptanceTermConditionsNewPayrollLoan();
    }

    if (
      this.user.customerDecisionType ===
        CustomerDecisionEnum.EXTEND_PAYROLL_LOAN &&
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
    ) {
      textAcceptanceContent =
        this.commonService.getAcceptanceTermConditionsWithCallPhone();
    }

    return textAcceptanceContent;
  }

  private async initBankInsurance() {
    this.bankingInsuranceToggle =
      this.toggleService.getToggle('BANKING_INSURANCE');

    if (this.bankingInsuranceToggle.featureEnabled) {
      this.loading = true;
      this.insuranceData = {
        documentType: this.user.documentType,
        documentNumber: this.user.documentId,
        disbursementAmount: this.validateResponse.disbursement,
        obligationId: this.user.obligationId,
      };

      if (this.isCustomerNewPayrollLoan) {
        this.insuranceData.clientType = CustomerTypeEnum.NEW_KNOWN_CUSTOMER;
        this.insuranceData.feeNumber = this.preselect.feeNumber;
        this.insuranceData.sectorNumber = this.preselect.sectorNumber;
      }

      this.selectedInsurance = {};
      this.bankInsuranceService
        .getBankInsuranceData(this.user, this.insuranceData)
        .subscribe(
          (response) => this.getBankInsuranceDataSuccess(response),
          (error) => this.getBankInsuranceDataError(error)
        );
    } else {
      this.updateSelectedInsurance({
        code: null,
        desition: false,
        maxCoverage: false,
        price: 0,
        spouseCoveraged: false,
      });
    }
  }

  private updateSelectedInsurance(selectedOffer: any) {
    this.selectedInsurance = {
      code: selectedOffer.code,
      desition: selectedOffer.desition,
      maxCoverage: selectedOffer.maxCoverage,
      price: selectedOffer.price,
      spouseCoveraged: selectedOffer.spouseCoveraged,
    };

    this.totalDiscounts =
      Number(this.validateResponse.discounts) +
      Number(this.selectedInsurance.price);
    this.finalDisbursement =
      Number(this.validateResponse.disbursement) -
      Number(this.selectedInsurance.price);
  }

  private getBankInsuranceDataSuccess(response: any) {
    this.loading = false;
    this.insuranceShow = true;
    this.insuranceOfferArray =
      this.orderByMinCoverageAndSposeCoveraged(response);
    this.validateInsuranceOffer();

    this.insuranceApprobation = true;

    if (this.insuranceOfferArray.length > 0) {
      this.updateSelectedInsurance({
        code: this.insuranceOfferArray[0].code,
        desition: true,
        maxCoverage: this.insuranceOfferArray[0].maxCoverage,
        price: this.insuranceOfferArray[0].price,
        spouseCoveraged: this.insuranceOfferArray[0].spouseCoveraged,
      });
    } else {
      this.updateSelectedInsurance({
        code: null,
        desition: false,
        maxCoverage: false,
        price: 0,
        spouseCoveraged: false,
      });
      this.loading = false;
      this.insuranceApprobation = false;
      this.insuranceShow = false;
    }
  }

  private validateInsuranceOffer() {
    const insuranceOfferArrayTmp = [];
    for (const offer of this.insuranceOfferArray) {
      if (parseFloat(offer.price) < this.validateResponse.disbursement) {
        insuranceOfferArrayTmp.push(offer);
      }
    }

    this.insuranceOfferArray = insuranceOfferArrayTmp;
  }

  private orderByMinCoverageAndSposeCoveraged(data: any) {
    return _.sortBy(_.sortBy(data, 'spouseCoveraged'), 'maxCoverage');
  }

  private getBankInsuranceDataError(error: any) {
    this.loading = false;
    this.insuranceApprobation = false;
    this.insuranceShow = false;

    this.updateSelectedInsurance({
      code: 'CUSTOMER_NOT_COVERED',
      desition: false,
      maxCoverage: false,
      price: 0,
      spouseCoveraged: false,
    });

    if (error.error.code === 'CustomerManagement020') {
      console.log(
        'CustomerManagement020 Bank Insurance: ' + JSON.stringify(error)
      );
    } else {
      this.messageErrorPopUp();
    }
  }

  confirm() {
    this.googleAnalyticsEventsService.emitEvent(
      'Confirmación Oferta',
      'Confirmar la oferta personalizada',
      'Botón Confirmar Pedido',
      Date.now()
    );

    this.event = {
      documentType: this.user.documentType,
      documentNumber: this.user.documentId,
      event: 'ACCEPT_TERMS_AND_CONDITIONS',
      name: this.user.name,
      eventText: window.btoa(this.acceptanceContent),
    };

    this.dialogRef = this.commonService.showMesssageBox(
      InsurabilityQuestionComponent,
      true,
      {
        title: 'Seguro de vida deudores',
        message:
          'Para la contratación del seguro de vida deudores es necesario que nos informe si ¿Padeces o has padecido alguna de las siguientes enfermedades?',
        messageComplement:
          this.commonService.getMessageComplementForInsurabilityQuestion(),
        hiddenButtonClose: false,
        user: this.user,
      },
      568
    );

    this.dialogRef
      .afterClosed()
      .subscribe((response: InsurabilityResponseEnum) => {
        if (response === InsurabilityResponseEnum.ERROR) {
          this.messageErrorPopUp();
        } else if (
          response === InsurabilityResponseEnum.NO_ACCEPTANCE_CONDITIONS ||
          response === InsurabilityResponseEnum.OK_WITH_ILL
        ) {
          this.messageNoContinue();
        } else if (response === InsurabilityResponseEnum.OK_WITHOUT_ILL) {
          this.loading = true;

          this.addMinimunAcepted();
          this.eventService.pushEvent(this.event).subscribe(
            () => this.acceptanceDataSuccess(),
            () => this.acceptanceDataError()
          );
          this.eventAnalyticsService.sendEventAnalytics(
            EventCodeEnum.ACCEPT_TERMS_AND_CONDITIONS,
            'Aceptó términos y condiciones'
          );
        }
      });
  }

  noAcceptCredit() {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      false,
      {
        icon: 'E',
        title: '',
        message:
          'Esta seguro, de no aceptar las condiciones de la oferta no podrás continuar con tu solicitud',
        url: '',
        textButtom: 'Estoy seguro',
        clearUser: false,
        feedback: true,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
        termAndConditions: true,
      },
      500
    );
  }

  private acceptanceDataError() {
    this.loading = false;
    this.messageErrorPopUp();
  }

  private acceptanceDataSuccess() {
    if (this.preselect.otherAmountAccepted === null) {
      this.preselect.otherAmountAccepted = 0;
    }

    this.customerService.acceptLoan(this.preselect).subscribe(
      () => this.acceptLoanSuccess(),
      () => this.acceptLoanError()
    );
  }

  private acceptLoanError() {
    this.loading = false;
    this.messageErrorPopUp();
  }

  private acceptLoanSuccess() {
    this.loading = false;
    this.preselect.bankInsurance = this.selectedInsurance;
    if (this.insuranceOfferArray.length > 1) {
      this.preselect.spouse = this.selectedInsurance.spouseCoveraged;
      this.preselect.maxCoverage = this.selectedInsurance.maxCoverage;
    }
    this.preselect.accidentInsurancePrice = this.selectedInsurance.price;
    this.preselect.adjustmentInsurancePremium =
      this.validateResponse.insurancePremiumAdjustment;
    this.preselect.adjustmentInterests =
      this.validateResponse.adjustmentInterests;
    this.preselect.creditStudy = this.validateResponse.creditStudy;
    this.preselect.discounts = this.totalDiscounts;
    this.preselectService.setPreselectPersonalized(this.preselect);

    this.router.navigate(['master-page/account-selection']);
  }

  private addMinimunAcepted() {
    if (this.chkVal.checked) {
      this.preselect.otherAmountAccepted = this.preselect.requesAmount;
    }
  }

  private findIndexInsurancensuraceSelect(code: string) {
    return this.insuranceOfferArray.findIndex((item: any) => item.id === code);
  }

  private messageErrorPopUp() {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'E',
        title: '',
        message: 'Ocurrio algo, cierra esta ventana e intenta de nuevo',
        url: '',
        textButtom: 'Volver a Intentar',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  private messageNoContinue() {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'E',
        title: '¡Lo sentimos!',
        message: '¡No podemos atender tu solicitud por este canal!',
        url: '',
        textButtom: 'Salir',
        clearUser: false,
        feedback: true,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }
}
