// Imports
import async from 'async';
import { Injectable } from '@angular/core';

// Declarations
import { environment } from '../../../../environments/environment';

import { CustomerDecisionEnum } from '../../enums/customer-decision-type.enum';
import { DecisionEnum } from '../../../shared/enums/decision-type.enum';
import { RedirectNewFrontTypeEnum } from '../../enums/redirect-new-front-type.enum';
import { ValidationMethodEnum } from '../../enums/validation-method.enum';

import { User } from '../../interfaces/user.interface';

import { CipherService } from './cipher.service';
import { CreditFlowService } from './credit-flow.service';
import { PreselectService } from './preselect.service';

@Injectable()
export class UserService {
  private user: User;
  private userInfo: any;
  private documentListByUserType: any[] = [];

  constructor(
    private cipherService: CipherService,
    private creditFlowService: CreditFlowService,
    private preselectService: PreselectService
  ) {
    this.clearUser();
  }

  get authMethod(): ValidationMethodEnum {
    return this.user.validationMethod === undefined
      ? ValidationMethodEnum.WITHOUT_VALIDATION_METHOD
      : this.user.validationMethod;
  }

  get responseAuthMethod(): string {
    return this.user.validationMethod === undefined
      ? ValidationMethodEnum.WITHOUT_VALIDATION_METHOD
      : this.user.validationMethod === ValidationMethodEnum.DECRIM
      ? this.user.idCaseDecrim
      : this.user.idCaseRnec;
  }

  public getUser(): User {
    return this.user;
  }

  public setUser(user: User) {
    this.user = user;
  }

  public getUserInfo(): User {
    return this.userInfo;
  }

  async setExternalUser(externalUser: any) {
    this.user = externalUser.advisor;
    this.user.administrativeOffice = externalUser.advisor.administrativeOffice
      ? externalUser.advisor.administrativeOffice.code
      : undefined;
    this.user.administrativeOfficeName = externalUser.advisor
      .administrativeOffice
      ? externalUser.advisor.administrativeOffice.name
      : undefined;
    this.user.cognitoToken = externalUser.advisor.cognitoToken;
    this.user.decisionType = externalUser.advisor.decisionType;
    this.user.federatedAuthClientId =
      externalUser.advisor.federatedAuthClientId;
    this.user.federatedAuthToken = externalUser.advisor.federatedAuthToken;
    this.user.officeCode = externalUser.advisor.office.idoffice;
    this.user.officeName = externalUser.advisor.office.name_office;
    this.user.sellerIdNumber = externalUser.advisor.sellerIdNumber;
    this.user.decrimFiles = externalUser.customer.decrimFiles;
    this.user.documentId = Number(externalUser.customer.documentId);
    this.user.documentType = externalUser.customer.documentType;
    this.user.colombianNationality = externalUser.customer.colombianNationality;
    this.user.cellphone = externalUser.customer.cellphone;
    this.user.customerDecisionType = externalUser.customer.decisionType;
    this.user.customerType = externalUser.customer.customerType;
    this.user.debitCard = externalUser.customer.debitCard;
    this.user.email = externalUser.customer.email;
    this.user.lastName = externalUser.customer.lastName;
    this.user.name = externalUser.customer.name;
    this.user.nameShort = externalUser.customer.nameShort;
    this.user.simPendingAuthentication =
      externalUser.customer.simPendingAuthentication;
    this.user.authenticationType = externalUser.customer.authenticationType;
    this.user.feedbackEnd = externalUser.customer.feedbackEnd;
    this.user.generatedId = externalUser.customer.generatedId;
    this.user.salesJourneyId = externalUser.customer.salesJourneyId;
    this.user.idCaseDecrim = externalUser.customer.idCaseDecrim;
    this.user.idCaseRnec = externalUser.customer.idCaseRnec;
    this.user.updateCellphone = externalUser.customer.updateCellphone;
    this.user.updateEmail = externalUser.customer.updateEmail;
    this.user.validationMethod = externalUser.customer.validationMethod;

    if (externalUser.preloan) {
      this.user.creditFlowType = externalUser.preloan.loanFlow;
      this.user.customerPayrollCheckId = externalUser.preloan.payrollCheckId;
      this.user.transactionId = externalUser.preloan.transactionId;

      this.preselectService.setPreselect({
        accidentInsurancePrice: externalUser.preloan.accidentInsurancePrice,
        adjustmentInsurancePremium:
          externalUser.preloan.adjustmentInsurancePremium,
        adjustmentInterests: externalUser.preloan.adjustmentInterests,
        advancedInsurancePremium: externalUser.preloan.advancedInsurancePremium,
        advancedInterests: externalUser.preloan.advancedInterests,
        almostPaidOff: externalUser.preloan.almostPaidOff,
        anualRate: externalUser.preloan.anualRate,
        authMethod: this.authMethod,
        bankInsurance: externalUser.preloan.bankInsurance,
        creditStudy: externalUser.preloan.creditStudy,
        customerInBureauTrail: externalUser.preloan.customerInBureauTrail,
        disbursement: externalUser.preloan.disbursement,
        discounts: externalUser.preloan.discounts,
        feeAmount: externalUser.preloan.feeAmount,
        feeNumber: externalUser.preloan.feeNumber,
        interestRate: externalUser.preloan.interestRate,
        loanQuota: externalUser.preloan.loanQuota,
        maxCoverage: externalUser.preloan.maxCoverage,
        maxFeeNumberRule: externalUser.preloan.maxFeeNumberRule,
        maxLoanAmount: externalUser.preloan.maxLoanAmount,
        maxLoanTerm: externalUser.preloan.maxLoanTerm,
        minLoanAmount: externalUser.preloan.minLoanAmount,
        minLoanTerm: externalUser.preloan.minLoanTerm,
        missingLoanQuotas: externalUser.preloan.missingLoanQuotas,
        nominalRate: externalUser.preloan.nominalRate,
        obligationBalance: externalUser.preloan.obligationBalance,
        obligationId: externalUser.preloan.obligationId,
        obligationInitialValue: externalUser.preloan.obligationInitialValue,
        openingObligationDate: externalUser.preloan.openingObligationDate,
        otherAmountAccepted: externalUser.preloan.otherAmountAccepted,
        payerId: externalUser.preloan.payerId,
        payerLocationCode: externalUser.preloan.payerLocationCode,
        payerName: externalUser.preloan.payerName,
        payerNit: externalUser.preloan.payerNit,
        payerUniqueName: externalUser.preloan.payerUniqueName,
        payerWeb: externalUser.preloan.payerWeb,
        platformAuthorizationUrl: externalUser.preloan.platformAuthorizationUrl,
        rates: externalUser.preloan.rates,
        requesAmount: externalUser.preloan.requesAmount,
        responseAuthMethod: this.responseAuthMethod,
        salesProcessType: externalUser.preloan.salesProcessType,
        sectorNumber: externalUser.preloan.sectorNumber,
        spouse: externalUser.preloan.spouse,
        totalDebt: externalUser.preloan.totalDebt,
      });

      this.creditFlowService.setCreditFlowType(externalUser.preloan.loanFlow);
      this.creditFlowService.setModalityType(externalUser.preloan.modalityType);
    }

    this.encryptUserData();
  }

  public clearUser() {
    this.user = {};
  }

  public getDocumentListByUserType() {
    return this.documentListByUserType;
  }

  public setDocumentListByUserType(documentList: any[]) {
    this.documentListByUserType = documentList;
  }

  public isCustomerNewPayrollLoan() {
    return (
      this.user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
    );
  }

  public isNewPayrollLoanByPhoneCall() {
    return (
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
    );
  }

  public isNewPayrollLoan() {
    return this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN;
  }

  public isPhoneSaleIsNewLoan() {
    return (
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
      this.user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
    );
  }

  public requireAuthenticationInOffice(): boolean {
    return (
      this.isNewPayrollLoanByPhoneCall() &&
      (this.user.simPendingAuthentication || this.user.updateCellphone)
    );
  }

  public goToDecisionPage(redirectNewFrontTypeEnum: RedirectNewFrontTypeEnum) {
    window.name = btoa(JSON.stringify(this.getUser()));
    window.location.href = environment.serverUrlNew + redirectNewFrontTypeEnum;
  }

  private encryptUserData() {
    async.parallel(
      [
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.documentType)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.documentId.toString())
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.officeCode)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.sellerIdNumber.toString())
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.administrativeOffice)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.officeName)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.administrativeOffice)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
        (callback: any) => {
          this.cipherService
            .cipherStringRSAOAEP512(this.user.advisorJourneyId)
            .subscribe(
              (data) => callback(null, data),
              (error) => this.cipherStringRSAOAEP512Error(error)
            );
        },
      ],
      // optional callback
      (err: any, results: any) => {
        this.userInfo = {
          documentType: results[0],
          documentNumber: results[1],
          officeCode: results[2],
          businessAdvisorDocument: results[3],
          administrativeOfficeCode: results[4],
          officeName: results[5],
          administrativeOfficeName: results[6],
          advisorJourneyId: results[7],
        };
      }
    );
  }

  private cipherStringRSAOAEP512Error(error: any) {
    console.log(
      'LoginComponent - cipherStringRSAOAEP512Error, Error: ' +
        JSON.stringify(error)
    );
  }
}
