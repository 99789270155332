import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoRemoteService {
  constructor(private http: HttpClient) {}

  getTokenLegacy(): Observable<any> {
    const auth = `grant_type=client_credentials&client_id=${environment.cognitoLegacy.clientId}&client_secret=${environment.cognitoLegacy.clientSecret}`;
    return this.http.post(environment.cognitoLegacy.url, auth);
  }
}
