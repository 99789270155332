// Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as _throw } from 'rxjs';

// Declarations
import { environment } from '../../../../environments/environment';
import { IUploadFileRequest } from '../../interfaces/upload-file-request.interface';
import { Preselect } from '../../interfaces/preselect.interface';
import { User } from '../../interfaces/user.interface';
import { UserData } from '../../interfaces/user-data.interface';
import { SimulationRequestInterface } from '../../interfaces/simulation-request.interface';
import { PlatformBindingsRequestInterface } from '../../interfaces/platform-bindings-request.interface';
import { PlatformOfferRequestInterface } from '../../interfaces/platform-offer-request.interface';
import { SendLinkUploadFileRequestInterface } from '../../interfaces/send-link-upload-file-request.interface';
import { ViabilityResponseInterface } from '../../interfaces/viability-response.interface';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  searchActiveLoanCustomer(customerInfo: object): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'customer/search',
      customerInfo
    );
  }

  searchLoansCustomer(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/customers/loans/' +
        user.documentType +
        '/' +
        user.documentId
    );
  }

  searchLoansValidate(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/customers/' +
        user.documentType +
        '/' +
        user.documentId +
        '/loans/status'
    );
  }

  searchDataCustomer(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/customers/' +
        user.documentType +
        '/' +
        user.documentId +
        '/info'
    );
  }

  searchDataAccounts(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/customers/' +
        user.documentType +
        '/' +
        user.documentId +
        '/accounts'
    );
  }

  validNewLoan(preselect: Preselect): Observable<object> {
    preselect.obligationBalance =
      Math.ceil(preselect.obligationBalance / 1000.0) * 1000;
    return this.http.post(
      environment.serverUrl + 'accounts/customers/loans/validate',
      JSON.stringify(preselect)
    );
  }

  acceptLoan(preselect: Preselect): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'accounts/customers/loans/accept',
      JSON.stringify(preselect)
    );
  }

  saveDataCustomer(userData: UserData): Observable<object> {
    return this.http.put(
      environment.serverUrl + 'accounts/customers',
      JSON.stringify(userData)
    );
  }

  getViability(
    documentNumber: number,
    typeDocument
  ): Observable<ViabilityResponseInterface> {
    return this.http.post<ViabilityResponseInterface>(
      environment.serverUrl + 'peace-safe/loan/business-rules',
      { documentNumber, typeDocument }
    );
  }

  getComboOccupation(): Observable<object> {
    return this.http.get(
      environment.serverUrl + 'accounts/customers/combos/OccupationOptions'
    );
  }

  getComboMaritalStatus(): Observable<object> {
    return this.http.get(
      environment.serverUrl + 'accounts/customers/combos/MaritalStatusOptions'
    );
  }

  getComboCity(city: string): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/customers/combos/CityOptions/?cityFilter=' +
        city
    );
  }

  setFile(uploadFileRequest: IUploadFileRequest): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'payrollCheck',
      JSON.stringify(uploadFileRequest)
    );
  }

  getFileCustomer(
    id: string,
    obligationId: string,
    user: User,
    queryParamsCustomerNew?: string
  ): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'payrollCheck/' +
        id +
        '/' +
        obligationId +
        '/' +
        user.documentType +
        '/' +
        user.documentId +
        (queryParamsCustomerNew || '')
    );
  }

  searchRecalculateValuesAsync(
    requestRecalculate: SimulationRequestInterface
  ): Observable<object> {
    return this.http.put(
      environment.serverUrl + 'offering',
      requestRecalculate
    );
  }

  askForRecalculateValuesAsyncAnswer(
    requestRecalculate: any
  ): Observable<object> {
    return this.http.get(
      environment.serverUrl + 'offering/' + requestRecalculate.simulateTicket
    );
  }

  validateCustomerCellPhone(customerInfo: object): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'auth/phoneNumber',
      customerInfo
    );
  }

  validateCustomerViability(customerId: any): Observable<any> {
    return this.http.post(
      environment.serverUrl + 'validations/customer/internal-viability',
      customerId
    );
  }

  getPlatformBindings(
    platformBindings: PlatformBindingsRequestInterface
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'payer/platform/bindings',
      platformBindings
    );
  }

  generatePlatformOffer(
    platformOffer: PlatformOfferRequestInterface
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'payer/platform/offer',
      JSON.stringify(platformOffer)
    );
  }

  sendLinkForUploadFile(
    requestSendLink: SendLinkUploadFileRequestInterface
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'payrollCheck/uploads',
      JSON.stringify(requestSendLink)
    );
  }
}
