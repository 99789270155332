import { Injectable } from '@angular/core';
import { ValidationMethodEnum } from '../../enums/validation-method.enum';

import { Preselect } from '../../interfaces/preselect.interface';
import { SalesProcessTypeEnum } from '../../enums/sales-process-type.enum';

@Injectable()
export class PreselectService {
  preselect: Preselect;
  preselectPersonalized: Preselect;

  constructor() {}

  getPreselect() {
    return Object.assign({}, this.preselect);
  }

  setPreselect(_preselect: Preselect) {
    this.preselect = _preselect;
  }

  getPreselectPersonalized() {
    return Object.assign({}, this.preselectPersonalized);
  }

  setPreselectPersonalized(_preselectPersonalized: Preselect) {
    this.preselectPersonalized = _preselectPersonalized;
  }

  setPreselectInfoPayer(
    preselectParam: Preselect,
    infoPayer: any,
    authMethod: ValidationMethodEnum,
    responseAuthMethod: string
  ) {
    this.setPreselect({
      documentNumber: 0,
      documentType: '',
      adjustmentInterests: 0,
      adjustmentInsurancePremium: 0,
      advancedInterests: 0,
      advancedInsurancePremium: 0,
      almostPaidOff: infoPayer.almostPaidOff,
      anualRate: 0,
      authMethod: authMethod,
      bankInsurance: [],
      creditStudy: 0,
      customerInBureauTrail: infoPayer.customerInBureauTrail,
      discounts: 0,
      disbursement: 0,
      feeAmount: 0,
      feeNumber: 0,
      interestRate: 0,
      loanQuota: 0,
      maxLoanAmount: 0,
      minLoanAmount: 0,
      minLoanTerm: 0,
      maxLoanTerm: 0,
      nominalRate: 0,
      payerId: infoPayer.payerId,
      payerLocationCode: infoPayer.payerLocationCode,
      payerName: infoPayer.originalPayerName,
      payerNit: infoPayer.payerNit,
      payerUniqueName: infoPayer.payerUniqueName,
      payerWeb: infoPayer.payerWeb,
      platformAuthorizationUrl: infoPayer.platformAuthorizationUrl,
      obligationBalance: infoPayer.currentDeb,
      obligationId: infoPayer.obligationId,
      obligationInitialValue: preselectParam.obligationInitialValue,
      openingObligationDate: infoPayer.openingObligationDate,
      otherAmountAccepted: null,
      salesProcessType: infoPayer.salesProcessType,
      rates: null,
      requesAmount: 0,
      responseAuthMethod: responseAuthMethod,
      sectorNumber: infoPayer.sectorNumber,
      totalDebt: 0,
    });
  }
}
