import {
  AfterViewChecked,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { InsurabilityQuestionEnum } from '../../enums/insurability-question.enum';
import { InsurabilityResponseEnum } from '../../enums/insurability-response.enum';

import { CommonService } from '../../services/local/common.service';

import { InsurabilityService } from '../../services/remote/insurability.service';

@Component({
  selector: 'app-insurability-question',
  templateUrl: './insurability-question.component.html',
  styleUrls: ['./insurability-question.component.css'],
})
export class InsurabilityQuestionComponent implements OnInit, AfterViewChecked {
  authorizationResponse: string;
  certifyResponse: string;
  checkedInsurability: boolean[];
  countPage = 1;
  classButtonValue: string;
  disableButtonValue: boolean;
  enabledOneClickButton = true;
  illSelection: boolean;
  insurabilityOptions: any[];
  initScrollTop = 0;
  messageAdvisorStatement: string;
  messageAuthorizationMedicalHistory: string;
  optionsAuthorization = [
    InsurabilityQuestionEnum.AFFIRMATIVE_AUTHORIZATION,
    InsurabilityQuestionEnum.NEGATIVE_AUTHORIZATION,
  ];
  optionsCertify = [
    InsurabilityQuestionEnum.AFFIRMATIVE_CERTIFY,
    InsurabilityQuestionEnum.NEGATIVE_CERTIFY,
  ];
  selectedInsurabilityOption: any[] = [];
  textButton = 'Continuar';

  @ViewChild('descriptionScroll', { static: false })
  descriptionScroll: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<InsurabilityQuestionComponent>,
    public insurabilityService: InsurabilityService
  ) {}

  ngOnInit() {
    this.clearInsurability();
    this.getInsurabilityOptions();
    this.disableButton();
  }

  ngAfterViewChecked(): void {
    if (this.initScrollTop <= 5) {
      this.topScroll();
    }
  }

  clickAnswer() {
    if (this.countPage === 1) {
      this.nextStep();
    } else {
      this.sendAnswer();
    }
  }

  close() {
    this.dialogRef.close(InsurabilityResponseEnum.CLOSE);
  }

  checkInsurability(checkId: number, checkValue: boolean) {
    this.selectedInsurabilityOption = [];
    if (checkId === this.insurabilityOptions.length - 1 && !checkValue) {
      this.illSelection = false;

      this.cleanInsurabilityExceptNothing();
      this.selectedInsurabilityOption.push(
        this.insurabilityOptions[this.insurabilityOptions.length - 1].value
      );
    } else if (checkId !== this.insurabilityOptions.length - 1) {
      this.illSelection = true;

      for (let i = 0; i < 4; i++) {
        if (this.checkedInsurability[i]) {
          this.selectedInsurabilityOption.push(
            this.insurabilityOptions[i].value
          );
        }
      }

      if (!checkValue) {
        this.selectedInsurabilityOption.push(
          this.insurabilityOptions[checkId].value
        );
      } else {
        this.selectedInsurabilityOption.splice(
          this.selectedInsurabilityOption.indexOf(
            this.insurabilityOptions[checkId].value
          ),
          1
        );
      }

      this.checkedInsurability[this.checkedInsurability.length - 1] = false;
    }

    this.disableButton();
  }

  disableButton() {
    if (this.countPage === 1) {
      this.classButtonValue =
        this.selectedInsurabilityOption.length === 0
          ? 'popUpBackButtonDisabled'
          : 'popUpBackButton';
      this.disableButtonValue = this.selectedInsurabilityOption.length === 0;
    } else {
      this.classButtonValue =
        this.authorizationResponse === undefined ||
        this.certifyResponse === undefined
          ? 'popUpBackButtonDisabled'
          : 'popUpBackButton';
      this.disableButtonValue =
        this.authorizationResponse === undefined ||
        this.certifyResponse === undefined;
    }
  }

  nextStep() {
    this.countPage = 2;
    this.textButton = 'Enviar';

    this.disableButton();

    this.messageAuthorizationMedicalHistory =
      this.commonService.getMessageAuthorizationMedicalHistory();
    this.messageAdvisorStatement =
      this.commonService.getMessageAdvisorStatement();
  }

  sendAnswer() {
    // tslint:disable-next-line:curly
    if (!this.enabledOneClickButton) return;
    this.enabledOneClickButton = false;

    this.insurabilityService
      .lifeInsurance({
        documentType: this.data.user.documentType,
        documentNumber: this.data.user.documentId.toString(),
        diseases: this.selectedInsurabilityOption,
        medicalHistoryAuthorization:
          this.authorizationResponse ===
          InsurabilityQuestionEnum.AFFIRMATIVE_AUTHORIZATION,
        advisorLegalStatement:
          this.certifyResponse === InsurabilityQuestionEnum.AFFIRMATIVE_CERTIFY,
      })
      .subscribe(
        () =>
          this.dialogRef.close(
            this.authorizationResponse ===
              InsurabilityQuestionEnum.NEGATIVE_AUTHORIZATION ||
              this.certifyResponse === InsurabilityQuestionEnum.NEGATIVE_CERTIFY
              ? InsurabilityResponseEnum.NO_ACCEPTANCE_CONDITIONS
              : this.illSelection
              ? InsurabilityResponseEnum.OK_WITH_ILL
              : InsurabilityResponseEnum.OK_WITHOUT_ILL
          ),
        () => this.dialogRef.close(InsurabilityResponseEnum.ERROR)
      );
  }

  private cleanInsurabilityExceptNothing() {
    for (let i = 0; i < 4; i++) {
      this.checkedInsurability[i] = false;
    }
  }

  private clearInsurability() {
    this.checkedInsurability = [];
    for (let i = 0; i < 5; i++) {
      this.checkedInsurability.push(false);
    }
  }

  private getInsurabilityOptions() {
    this.insurabilityOptions = [
      {
        value: 'Cáncer',
      },
      {
        value: 'Insuficiencia renal',
      },
      {
        value: 'Infarto al miocardio',
      },
      {
        value: 'Accidente cerebro vascular',
      },
      {
        value: 'Ninguna',
      },
    ];
  }

  private topScroll() {
    this.descriptionScroll.nativeElement.scrollTop = 0;
    this.initScrollTop++;
  }
}
