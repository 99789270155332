import { Component, OnInit, Input } from '@angular/core';
import { LoadingTypeEnum } from '../../enums/loading-type.enum';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() visible: boolean;
  @Input() title: string;
  @Input() subtitle: string;

  atomSpinnerShow: boolean;
  pixelSpinnerShow: boolean;
  radarSpinnerShow: boolean;

  constructor() { }

  ngOnInit() {
    this.changeSpinner(LoadingTypeEnum.ATOM_SPINNER_SHOW);
  }

  changeSpinner(spinner: LoadingTypeEnum) {
    this.atomSpinnerShow = false;
    this.pixelSpinnerShow = false;
    this.radarSpinnerShow = false;

    switch (spinner) {
      case LoadingTypeEnum.ATOM_SPINNER_SHOW:
        this.atomSpinnerShow = true;

        break;
      case LoadingTypeEnum.PIXEL_SPINNER_SHOW:
        this.pixelSpinnerShow = true;

        break;
      case LoadingTypeEnum.RADAR_SPINNER_SHOW:
        this.radarSpinnerShow = true;

        break;
      default:
        this.atomSpinnerShow = true;

        break;
    }
  }
}
