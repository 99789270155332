import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Simulation } from '../../../../shared/interfaces/simulation.interface';

@Component({
  selector: 'app-calculator-detail',
  templateUrl: './calculator-detail.component.html',
  styleUrls: ['./calculator-detail.component.css'],
})
export class CalculatorDetailComponent implements OnInit, OnChanges {
  @Input() inputSimulation: Simulation;
  @Input() tittle: string;
  @Input() isFormFinancialEntity: boolean;
  @Output() simulationSuccess = new EventEmitter<boolean>();
  simulation: Simulation;
  fieldDisabled = {
    amount: false,
    feeAmount: false,
    term: false,
    interestRate: false,
  };
  fieldEdit = { amount: true, feeAmount: true, term: true, interestRate: true };
  hiddenClean = true;

  currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 1,
    requireDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: true,
  });

  constructor() {}

  ngOnInit(): void {
    if (this.inputSimulation) {
      this.simulation = this.inputSimulation;
      this.simulation.interestRate = this.roundRate(
        this.simulation.interestRate
      );
      this.fieldDisabled.interestRate = true;
      this.fieldEdit.interestRate = false;
      this.calculateOther();
    } else {
      this.simulation = {
        amount: null,
        feeAmount: null,
        term: null,
        interestRate: null,
        feeXMillion: null,
        totalToPay: null,
      };
    }

    this.updateFieldDisabled();
    this.verifyShowClean();
  }

  ngOnChanges(): void {
    this.simulationSuccess.emit(false);
    if (this.isFormFinancialEntity && this.inputSimulation) {
      this.fieldDisabled.interestRate = true;
      this.fieldDisabled.term = true;
      this.fieldEdit.interestRate = false;
      this.fieldEdit.term = false;
      this.simulation = this.inputSimulation;
      this.simulation.interestRate = this.roundRate(
        this.simulation.interestRate
      );
      this.cleanForm();
    } else {
      if (this.simulation) {
        this.simulation = {
          amount: null,
          feeAmount: null,
          term: null,
          interestRate: null,
          feeXMillion: null,
          totalToPay: null,
        };
        this.fieldDisabled = {
          amount: false,
          feeAmount: false,
          term: false,
          interestRate: false,
        };
      }
    }
  }

  changeValue() {
    this.simulationSuccess.emit(false);

    if (
      this.simulation.interestRate &&
      this.simulation.interestRate > 0 &&
      this.simulation.amount &&
      this.simulation.amount > 0 &&
      this.simulation.term &&
      this.simulation.term > 0
    ) {
      this.simulation.feeAmount = this.calculateFee(
        this.simulation.amount,
        this.simulation.term,
        this.simulation.interestRate / 100
      );
    } else if (
      this.simulation.interestRate &&
      this.simulation.interestRate > 0 &&
      this.simulation.amount &&
      this.simulation.amount > 0 &&
      this.simulation.feeAmount &&
      this.simulation.feeAmount > 0
    ) {
      this.simulation.term = this.calculateTerm(
        this.simulation.amount,
        this.simulation.interestRate / 100,
        this.simulation.feeAmount
      );
    } else if (
      this.simulation.interestRate &&
      this.simulation.interestRate > 0 &&
      this.simulation.feeAmount &&
      this.simulation.feeAmount > 0 &&
      this.simulation.term &&
      this.simulation.term > 0
    ) {
      this.simulation.amount = this.calculateAmount(
        this.simulation.term,
        this.simulation.interestRate / 100,
        this.simulation.feeAmount
      );
    } else if (
      this.simulation.feeAmount &&
      this.simulation.feeAmount > 0 &&
      this.simulation.amount &&
      this.simulation.amount > 0 &&
      this.simulation.term &&
      this.simulation.term > 0
    ) {
      this.simulation.interestRate = this.roundRate(
        this.calculateRate(
          this.simulation.amount,
          this.simulation.term,
          this.simulation.feeAmount
        )
      );
    }

    this.calculateOther();
    this.updateFieldDisabled();
    this.verifyShowClean();
  }

  cleanForm() {
    this.simulationSuccess.emit(false);

    if (this.simulation) {
      this.simulation.amount = null;
      this.simulation.feeAmount = null;
      this.simulation.totalToPay = null;
      this.simulation.feeXMillion = null;
    }

    this.fieldDisabled = {
      amount: false,
      feeAmount: false,
      term: false,
      interestRate: false,
    };
    this.hiddenClean = true;

    if (this.inputSimulation) {
      if (this.inputSimulation.interestRate && !this.fieldEdit.interestRate) {
        this.fieldDisabled.interestRate = true;
      } else {
        this.simulation.interestRate = null;
      }

      if (this.inputSimulation.term && !this.fieldEdit.term) {
        this.fieldDisabled.term = true;
      } else {
        this.simulation.term = null;
      }
    } else {
      this.simulation.interestRate = null;
      this.simulation.term = null;
    }
  }

  private updateFieldDisabled() {
    if (
      this.simulation.amount &&
      this.simulation.amount > 0 &&
      this.simulation.feeAmount &&
      this.simulation.feeAmount > 0 &&
      this.simulation.term &&
      this.simulation.term > 0 &&
      this.simulation.interestRate &&
      this.simulation.interestRate > 0
    ) {
      this.fieldDisabled = {
        amount: true,
        feeAmount: true,
        term: true,
        interestRate: true,
      };
    }
  }

  private verifyShowClean() {
    if (this.inputSimulation) {
      if (
        this.fieldDisabled.amount &&
        this.fieldDisabled.feeAmount &&
        this.fieldDisabled.term
      ) {
        this.hiddenClean = false;
      }
    } else {
      if (
        this.fieldDisabled.amount &&
        this.fieldDisabled.feeAmount &&
        this.fieldDisabled.term &&
        this.fieldDisabled.interestRate
      ) {
        this.hiddenClean = false;
      }
    }
  }

  private calculateAmount(term: any, rate: any, fee: any): number {
    return (
      Math.round(fee * ((1 - Math.pow(1 + rate, -term)) / rate) * 100) / 100
    );
  }

  private calculateTerm(amount: any, rate: any, fee: any): number {
    return Math.ceil(
      -(Math.log(fee - amount * rate) - Math.log(fee)) / Math.log(1 + rate)
    );
  }

  private calculateRate(amount: any, term: any, fee: any): number {
    let rate = Math.round((fee / amount) * 1000000) / 1000000;
    let calculatedAmount = this.calculateAmount(term, rate, fee);

    if (calculatedAmount === amount) {
      return rate;
    }

    let counter = 0;
    let changeFactor = 0.1;
    const dir = calculatedAmount < amount ? -1 : 1;

    while (++counter < 100) {
      const tempRate =
        Math.round((rate + changeFactor * dir) * 1000000) / 1000000;
      calculatedAmount = this.calculateAmount(term, tempRate, fee);
      if (calculatedAmount === amount) {
        return tempRate;
      }

      const newDir = calculatedAmount < amount ? -1 : 1;
      if (newDir === dir) {
        rate = tempRate;
      } else {
        changeFactor = changeFactor / 10;
      }
    }
    return rate;
  }

  private calculateFee(amount: any, term: any, rate: any): number {
    return -Math.round((amount * rate) / (Math.pow(1 + rate, -term) - 1));
  }

  private quotePerMillion(amount: any, fee: any) {
    return Math.round(fee / (amount / 1000000));
  }

  private totalAmount(fee: any, term: any) {
    return fee * term;
  }

  private calculateOther() {
    if (
      this.simulation.amount &&
      this.simulation.amount > 0 &&
      this.simulation.feeAmount &&
      this.simulation.feeAmount > 0 &&
      this.simulation.term &&
      this.simulation.term > 0 &&
      this.simulation.interestRate &&
      this.simulation.interestRate > 0
    ) {
      this.simulation.totalToPay = this.totalAmount(
        this.simulation.feeAmount,
        this.simulation.term
      );
      this.simulation.feeXMillion = this.quotePerMillion(
        this.simulation.amount,
        this.simulation.feeAmount
      );
      this.simulationSuccess.emit(true);
    }
  }

  private roundRate(rate: number) {
    return parseFloat((rate * 100).toFixed(2));
  }

  returnValuesOffered() {
    return this.simulation;
  }
}
