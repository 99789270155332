// Imports
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.animeWelcome();
  }

  animeWelcome() {
    setTimeout(() => {
      window.location.href = environment.serverUrlNew;
    }, 4000);
  }
}
