import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

import { ApplicationConstantsEnum } from '../../enums/applications-constants.enum';
import { ChannelType } from '../../enums/channel-type.enum';
import { DecisionEnum } from '../../enums/decision-type.enum';
import { DecrimDocumentConceptEnum } from '../../enums/decrim-document-concept.enum';
import { FileExtensionEnum } from '../../enums/fileExtension.enum';

import { CreateDecrim } from '../../interfaces/create-decrim';
import { User } from '../../interfaces/user.interface';

import { AuthenticationService } from '../../services/remote/authentication.service';
import { DecrimFilesService } from '../../services/local/decrim-files.service';
import { FileManagerService } from '../../services/remote/file-manager.service';
import { UserService } from '../../services/local/user.service';

@Component({
  selector: 'app-decrim',
  templateUrl: './decrim.component.html',
  styleUrls: ['./decrim.component.css'],
})
export class DecrimComponent implements OnInit {
  createDecrim: CreateDecrim;
  dataDecrim: any;
  decrimDocumentConcept = DecrimDocumentConceptEnum;
  errorMessage: any[] = [undefined, undefined, undefined];
  errorMessageTechnical: string;
  files: any[] = [undefined, undefined, undefined];
  fileName: any[] = [undefined, undefined, undefined];
  loading: boolean;
  retryAttemps: number;
  textButtom: string;
  user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DecrimComponent>,
    private authenticationService: AuthenticationService,
    private decrimFilesService: DecrimFilesService,
    private fileManagerService: FileManagerService,
    private router: Router,
    private userService: UserService
  ) {
    this.dataDecrim = data;
    this.retryAttemps = 0;
    this.textButtom = 'Subir documentos';
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.setFacialIdentificationFile();
  }

  close() {
    this.dialogRef.close(false);
  }

  fileChange(event: any, index: number) {
    this.files[index] = event.target.files[0];
    this.fileName[index] = this.files[index].name;
    this.errorMessage[index] = '';

    const fileExtension = this.files[index].name.split('.').pop();

    if (
      !Object.values(FileExtensionEnum).includes(fileExtension.toLowerCase())
    ) {
      this.files[index] = undefined;
      this.fileName[index] = 'No se eligió archivo';
      this.errorMessage[index] = 'No se admite este tipo de archivo';
    } else if (
      this.files[index].size > ApplicationConstantsEnum.MAXIMUM_DECRIM_FILE_SIZE
    ) {
      this.files[index] = undefined;
      this.fileName[index] = 'No se eligio archivo';
      this.errorMessage[index] =
        'El tamaño del archivo no puede superar los 2MB';
    }
  }

  upload() {
    this.loading = true;
    this.createDecrim = {
      fullName: this.user.name,
      documentNumber: this.user.documentId.toString(),
      documentType: this.user.documentType === 'CC' ? '1' : '2',
      advisorId: this.user.sellerIdNumber.toString(),
      fileName1: '',
      fileType1: '',
      fileName2: '',
      fileType2: '',
      fileName3: '',
      fileType3: '',
      salesModel: DecisionEnum.NEW_PAYROLL_LOAN,
    };

    if (this.data.illegibleDocument === DecrimDocumentConceptEnum.DOCUMENT) {
      this.files[2] = this.decrimFilesService.getDecrimFiles()[2];
    } else if (
      this.data.illegibleDocument === DecrimDocumentConceptEnum.FOOTPRINT ||
      this.data.illegibleDocument ===
        DecrimDocumentConceptEnum.FACIAL_IDENTIFICATION
    ) {
      this.files[0] = this.decrimFilesService.getDecrimFiles()[0];
      this.files[1] = this.decrimFilesService.getDecrimFiles()[1];
    }

    if (
      this.validateFacialIdentificationFile() &&
      this.data.illegibleDocument !==
        DecrimDocumentConceptEnum.FACIAL_IDENTIFICATION
    ) {
      this.files[3] = this.decrimFilesService.getDecrimFiles()[3];
    }

    this.decrimFilesService.setDecrimFiles(this.files);

    if (navigator.onLine) {
      this.uploadFilesDecrim(this.files);
    } else {
      this.loading = false;
      this.retryAttemps++;
      this.errorMessageTechnical =
        '¡Falla conexión verifica el acceso a internet! Por favor intenta de nuevo';
      this.textButtom = 'Reintentar';
    }
  }

  async uploadFilesDecrim(files: File[]) {
    let index = 0;
    let loadCounter = 0;
    for (const file of files) {
      index++;
      const decrimFileName = this.getFileName(file, index);
      const decrimFileExtension = this.getFileExtension(file);

      this.createDecrim['fileName' + index] = decrimFileName;
      this.createDecrim['fileType' + index] = decrimFileExtension;

      await this.fileManagerService
        .uploadFile(
          decrimFileName,
          decrimFileExtension,
          file,
          ChannelType.DECRIM
        )
        .toPromise()
        .then(() => {
          loadCounter++;
          if (loadCounter === files.length) {
            this.createCaseDecrim();
          }
        })
        .catch((error) =>
          console.log(
            'DecrimComponent - setFileDecrimInS3 - File' + index + ', Error',
            JSON.stringify(error)
          )
        );
    }
  }

  createCaseDecrim() {
    this.authenticationService.createCaseDecrim(this.createDecrim).subscribe(
      (response: any) => {
        this.user.idCaseDecrim = response['idCase'];
        this.userService.setUser(this.user);

        this.loading = false;
        this.dialogRef.close(true);
      },
      (error: any) => {
        this.loading = false;
        this.retryAttemps++;

        if (error.status === 504) {
          this.errorMessageTechnical =
            '¡La carga de la información ha excedido el límite de tiempo! Por favor intenta de nuevo';
          this.textButtom = 'Reintentar';
        } else if (error.status === 500) {
          this.errorMessageTechnical =
            '¡Parece que hay un problema con la información cargada! Por favor revísala e intenta de nuevo';
          this.textButtom = 'Cargar de nuevo';
        } else {
          this.errorMessageTechnical =
            '¡Parece que hay un problema técnico! Por favor intenta de nuevo';
          this.textButtom = 'Reintentar';
        }

        if (this.retryAttemps === 3) {
          this.dialogRef.close(true);
          this.userService.clearUser();
          this.router.navigate(['/welcome']);
        }
      }
    );
  }

  getFileName(file: File, fileNumber: number): string {
    return `${this.user.documentType}_${
      this.user.documentId
    }_${fileNumber}.${this.getFileExtension(file)}`;
  }

  getFileExtension(file: File): string {
    return (
      file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) ||
      file.name
    );
  }

  private validateFacialIdentificationFile() {
    const decrimFiles = this.decrimFilesService.getDecrimFiles();

    return decrimFiles.length ===
      ApplicationConstantsEnum.NUMBER_DECRIM_FILES_WITH_FACIAL_IDENTIFICATION
      ? true
      : false;
  }

  private setFacialIdentificationFile() {
    if (this.validateFacialIdentificationFile()) {
      this.files.push(undefined);
      this.fileName.push(undefined);
      this.errorMessage.push(undefined);
    }
  }
}
