import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { ICustomerCertificate } from '../../interfaces/customer-certificate.interface';

@Injectable()
export class TrailsRemoteService {
  constructor(private httpClient: HttpClient) {}

  getCertificate(customerCertificate: ICustomerCertificate): Observable<any> {
    return this.httpClient.post(
      environment.serverUrl + 'certificate/send',
      customerCertificate
    );
  }
}
