import { DecrimComponent } from '../../../components/decrim/decrim.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { PopUpPepComponent } from '../../../components/pop-up/pop-up-pep.component';

import { CommonTextEnum } from '../../../enums/common-text.enum';
import { DecisionEnum } from '../../../enums/decision-type.enum';
import { DecrimDocumentConceptEnum } from '../../../enums/decrim-document-concept.enum';
import { DecrimMessagesEnum } from '../../../enums/decrim-messages.enum';
import { DecrimStatesEnum } from '../../../enums/decrim-states.enum';
import { EventCodeEnum } from '../../../enums/event-code.enum';
import { LoadingTypeEnum } from '../../../enums/loading-type.enum';

import { IValidateAuthentication } from './validate-authentication.interface';
import { MessageBox } from '../../../interfaces/message-box.interface';
import { User } from '../../../interfaces/user.interface';

import { AuthenticationService } from '../../remote/authentication.service';
import { CommonService } from '../common.service';
import { EventAnalyticsService } from '../event-analytics.service';
import { UserService } from '../user.service';

export class ValidateAuthenticationDecrim implements IValidateAuthentication {
  retryDecrim: number = 0;
  user: User;

  constructor(
    private appLoading: LoadingComponent,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private eventAnalyticsService: EventAnalyticsService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
  }

  validateAuthentication(): void {
    this.appLoading.visible = true;
    this.appLoading.title = 'Enviando documentos, un minuto por favor';
    this.appLoading.subtitle = this.commonService.getMessageLoadingDecrim();
    this.appLoading.changeSpinner(LoadingTypeEnum.PIXEL_SPINNER_SHOW);

    this.authenticationService
      .validateCaseDecrim({
        idCase: this.user.idCaseDecrim,
        fullName: this.user.name,
        documentNumber: this.user.documentId.toString(),
        documentType: this.user.documentType === 'CC' ? '1' : '2',
        idAdvisor: this.user.sellerIdNumber,
        office: this.user.officeCode,
        journeyId: this.user.advisorJourneyId,
        transactionId: this.user.transactionId,
        salesModel: DecisionEnum.NEW_PAYROLL_LOAN,
      })
      .subscribe(
        (data) => this.validateCaseDecrimSuccess(data),
        (error) =>
          this.validateCaseDecrimError(
            error,
            'Por favor intenta de nuevo',
            '/master-page/salary-loans-overview'
          )
      );
  }

  private showMessageIllegibleDocuments(
    illegibleDocument: string,
    message: string
  ) {
    const dialogRef = this.commonService.showMesssageBox(
      DecrimComponent,
      false,
      {
        illegibleDocument: illegibleDocument,
        message: message,
        title: 'Fotos de la cédula, firma y huella',
        hiddenButtonClose: true,
      },
      500
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.validateAuthentication();
      }
    });
  }

  private showMessageBox(messageBox: MessageBox) {
    this.appLoading.visible = false;
    return this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      messageBox,
      500
    );
  }

  private validateCaseDecrimError(
    error: any,
    message: string,
    url: string,
    buttonText = 'Reintentar',
    feedBack = false
  ) {
    console.log(
      'DataClientComponent - validateCaseDecrimError, Error: ' +
        JSON.stringify(error)
    );

    this.showMessageBox({
      icon: 'S',
      title: '',
      message: '¡Parece que hay un problema técnico! ' + message,
      url: url,
      img: '',
      textButtom: buttonText,
      clearUser: false,
      feedback: feedBack,
      routerDestination: '',
      stepId: 0,
      stepDescription: '',
    });
  }

  private validateCaseDecrimSuccess(data: any) {
    if (data.state) {
      switch (data.state) {
        case DecrimStatesEnum.CASE_RETURNED:
          if (this.retryDecrim <= 1) {
            this.retryDecrim++;

            if (
              data.footprintConcept ===
                DecrimStatesEnum.FOOTPRINT_CONCEPT_ILLEGIBLE_ON_FORM &&
              data.messageState.includes(
                DecrimStatesEnum.FACIAL_IDENTIFICATION_CODE
              )
            ) {
              this.showMessageIllegibleDocuments(
                DecrimDocumentConceptEnum.FACIAL_IDENTIFICATION,
                DecrimMessagesEnum.ERROR_FACIAL_IDENTIFICATION
              );
              this.eventAnalyticsService.sendEventAnalytics(
                EventCodeEnum.DECRIM_VALIDATION,
                '',
                DecrimMessagesEnum.ERROR_FACIAL_IDENTIFICATION
              );
            } else if (
              data.footprintConcept ===
                DecrimStatesEnum.FOOTPRINT_CONCEPT_ILLEGIBLE_ON_FORM ||
              data.footprintConcept ===
                DecrimStatesEnum.FOOTPRINT_CONCEPT_ILLEGIBLE_ON_IDENTIFICATION
            ) {
              this.showMessageIllegibleDocuments(
                DecrimDocumentConceptEnum.FOOTPRINT,
                DecrimMessagesEnum.ERROR_FOOTPRINT
              );
              this.eventAnalyticsService.sendEventAnalytics(
                EventCodeEnum.DECRIM_VALIDATION,
                '',
                DecrimMessagesEnum.ERROR_FOOTPRINT
              );
            } else if (
              data.documentConcept ===
              DecrimStatesEnum.DOCUMENT_CONCEPT_ILLEGIBLE
            ) {
              this.showMessageIllegibleDocuments(
                DecrimDocumentConceptEnum.DOCUMENT,
                DecrimMessagesEnum.ERROR_READING_IDENTIFICATION
              );
              this.eventAnalyticsService.sendEventAnalytics(
                EventCodeEnum.DECRIM_VALIDATION,
                '',
                DecrimMessagesEnum.ERROR_READING_IDENTIFICATION
              );
            } else {
              this.showMessageIllegibleDocuments(
                '',
                DecrimMessagesEnum.ERROR_DATA
              );
              this.eventAnalyticsService.sendEventAnalytics(
                EventCodeEnum.DECRIM_VALIDATION,
                '',
                DecrimMessagesEnum.ERROR_DATA
              );
            }
          } else {
            this.validateCaseDecrimError(
              'Intentos fallidos',
              CommonTextEnum.ERROR_ATTEMPTS,
              '/welcome',
              'Aceptar',
              true
            );
            this.eventAnalyticsService.sendEventAnalytics(
              EventCodeEnum.DECRIM_VALIDATION,
              '',
              CommonTextEnum.ERROR_ATTEMPTS
            );
          }
          break;
        case DecrimStatesEnum.CASE_REJECTED:
          this.showMessageBox({
            icon: 'R',
            title: '',
            message: DecrimMessagesEnum.ERROR_DECRIM_VALIDATION,
            url: '',
            img: '',
            textButtom: 'Volver',
            clearUser: false,
            feedback: true,
            routerDestination: '/welcome',
            stepId: 0,
            stepDescription: '',
          });
          this.eventAnalyticsService.sendEventAnalytics(
            EventCodeEnum.DECRIM_VALIDATION,
            '',
            DecrimMessagesEnum.ERROR_DECRIM_VALIDATION
          );

          break;
        case DecrimStatesEnum.CASE_APPROVED:
          this.showMessageBox({
            icon: 'I',
            title: '¡Podemos seguir!',
            message: DecrimMessagesEnum.SUCCESS,
            url: '',
            img: '',
            textButtom: 'Aceptar',
            clearUser: false,
            feedback: false,
            routerDestination: '',
            stepId: 0,
            stepDescription: '',
          });
          this.eventAnalyticsService.sendEventAnalytics(
            EventCodeEnum.DECRIM_VALIDATION,
            DecrimMessagesEnum.SUCCESS
          );

          break;
        default:
          setTimeout(() => {
            this.validateAuthentication();
          }, 10000);

          break;
      }
    }
  }
}
