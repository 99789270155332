import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { User } from '../../interfaces/user.interface';
import { SimulationRequestInterface } from '../../interfaces/simulation-request.interface';

@Injectable()
export class BurosService {
  constructor(public http: HttpClient) {}

  searchBurosCustomerAnyFee(
    simulationRequest: SimulationRequestInterface
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'offering',
      simulationRequest
    );
  }

  searchBurosCustomerSameFee(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'accounts/buros/preselecta/' +
        user.documentType +
        '/' +
        user.documentId +
        '/' +
        user.obligationId
    );
  }
}
