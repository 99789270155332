import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ChangeDetectorRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ApplicationConstantsEnum } from '../../../shared/enums/applications-constants.enum';
import { CommonTextEnum } from '../../../shared/enums/common-text.enum';
import { CreditFlowType } from '../../../shared/enums/credit-flow-type.enum';
import { DataClientType } from '../../../shared/enums/data-client-type.enum';
import { MinimunFinancialInputValuesEnum } from '../../../shared/enums/minimun-financial-input-values.enum';
import { ToggleEnum } from '../../../shared/enums/toggle-type.enum';
import { ValidationMethodEnum } from '../../../shared/enums/validation-method.enum';
import { ViabilityResponseEnum } from '../../../shared/enums/viability-response.enum';

import { MessageBox } from '../../../shared/interfaces/message-box.interface';
import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { Toggle } from '../../../shared/interfaces/toggle.interface';
import { User } from '../../../shared/interfaces/user.interface';
import { UserData } from '../../../shared/interfaces/user-data.interface';

import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

import { AuthenticationService } from '../../../shared/services/remote/authentication.service';
import { CustomerService } from '../../../shared/services/remote/customer.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

import { CommonService } from '../../../shared/services/local/common.service';
import { CreditFlowService } from '../../../shared/services/local/credit-flow.service';
import { EventAnalyticsService } from '../../../shared/services/local/event-analytics.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';
import { LoadingComponent } from '../../../shared/components/loading/loading.component';
import { ValidateAuthenticationStrategyService } from '../../../shared/services/local/strategy/validate-authentication-strategy.service';

@Component({
  selector: 'app-data-client',
  templateUrl: './data-client.component.html',
  styleUrls: ['./data-client.component.css'],
})
export class DataClientComponent
  implements AfterViewInit, AfterViewChecked, OnInit
{
  @Input() type: DataClientType;
  @ViewChild('txtCity', { static: false }) txtCity: NgSelectComponent;
  @ViewChild('appLoading', { static: false }) appLoading: LoadingComponent;

  preselect: Preselect;
  user: User;
  userData: UserData = {
    maritalStatus: '',
    cityId: '',
    streetType: '',
    phoneNumber: '',
    occupation: '',
    peopleInChargeOf: '',
    monthlyIncome: 0,
    additionalIncome: 0,
    monthlyExpenses: 0,
    assets: 0,
    debts: 0,
  };

  addressOne: string;
  addressTwo: string;
  addressThree: string;
  addressFour: string;
  addressFive: string;
  assetsMinValueError: boolean;
  cities: any;
  comboMaritalState: any;
  comboOccupation: any;
  comboPeopleCharge: any;
  comboAddressOne: any;
  comboComplement: any;
  disabledFieldDirection: boolean;
  editAddress: boolean;
  gasMenMinValueError: boolean;
  ingMenMinValueError: boolean;
  loading: boolean;
  minimunFinancialInputValuesEnum = MinimunFinancialInputValuesEnum;
  retryDecrim: number;
  selectedCity: any;
  streetType: any;
  telFijMinLengthError: boolean;
  titleButtom: string;
  toggleCrmUpdate: Toggle;
  toggleMdmService: Toggle;
  toggleOrderPaceSafe: Toggle;

  cityLoading = false;
  isDisabledButton = false;
  isHaveAddressOne = false;
  isHaveAddressTwo = false;
  isHaveAdressThree = false;
  isKeyupAdressOne = false;

  private validateAuthenticationStrategy: ValidateAuthenticationStrategyService;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private changeDetector: ChangeDetectorRef,
    private creditFlowService: CreditFlowService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.toggleCrmUpdate = {
      featureName: '',
      featureEnabled: false,
    };
    this.toggleMdmService = {
      featureName: '',
      featureEnabled: false,
    };
    this.toggleOrderPaceSafe = {
      featureName: '',
      featureEnabled: false,
    };
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.validateAuthenticationStrategy =
      new ValidateAuthenticationStrategyService(
        this.appLoading,
        this.authenticationService,
        this.commonService,
        this.eventAnalyticsService,
        this.userService
      );

    if (
      this.user.validationMethod === ValidationMethodEnum.DECRIM ||
      (this.user.validationMethod === ValidationMethodEnum.CERTIHUELLA &&
        this.toggleService.getToggle(ToggleEnum.CERTIHUELLA_FUNCTION)
          .featureEnabled)
    ) {
      this.validateAuthenticationStrategy
        .getValidateAuthenticationType(this.user.validationMethod)
        .validateAuthentication();
    } else {
      this.appLoading.visible = false;
    }

    this.toggleCrmUpdate = this.toggleService.getToggle(
      ToggleEnum.USE_NEW_CRM_UPDATE_SERVICE
    );

    this.toggleMdmService = this.toggleService.getToggle(
      ToggleEnum.USE_MDM_SERVICE_TOGGLE
    );

    this.toggleOrderPaceSafe = this.toggleService.getToggle(
      ToggleEnum.USE_TOGGLE_ORDER_PEACE_SAFE
    );

    if (this.toggleOrderPaceSafe.featureEnabled) {
      this.getViability();
    }
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.stepperService.setStep('DataClient');
    this.googleAnalyticsEventsService.emitEvent(
      'Actualización Datos',
      'Ingreso página actualización datos cliente',
      'Página data-client',
      Date.now()
    );

    this.loading = true;
    this.editAddress = false;
    this.retryDecrim = 0;
    this.chargeCombos();
    this.titleButtom = 'Actualiza tus datos';
    if (this.type === DataClientType.BYCAROUSEL) {
      this.preselect = this.preselectService.getPreselect();
      this.user.obligationId = this.preselect.obligationId;
      this.titleButtom = 'Muéstrame el aprobado';
    }
  }

  validateMinLength(txtInput: any) {
    if (txtInput.name === 'txtTelFij') {
      let strInput = new String(txtInput.value);

      this.telFijMinLengthError =
        strInput.length < ApplicationConstantsEnum.MINIMUM_PHONE_FIXED_LENGTH;
    }
  }

  validateMinValue(txtInput: any) {
    switch (txtInput.name) {
      case 'txtAssets':
        this.assetsMinValueError =
          txtInput.value < MinimunFinancialInputValuesEnum.ASSETS;
        break;
      case 'txtGasMen':
        this.gasMenMinValueError =
          txtInput.value < MinimunFinancialInputValuesEnum.MONTHLY_EXPENSES;
        break;
      case 'txtIngMen':
        this.ingMenMinValueError =
          txtInput.value < MinimunFinancialInputValuesEnum.MONTHLY_INCOME;
        break;
    }
  }

  validateMinValues(): boolean {
    return (
      !this.assetsMinValueError &&
      !this.gasMenMinValueError &&
      !this.ingMenMinValueError &&
      !this.telFijMinLengthError
    );
  }

  private chargeCombos() {
    this.comboPeopleCharge = this.commonService.getPeopleCharge();
    this.comboAddressOne = this.commonService.getElementAddress();
    this.streetType = this.setStreetType(CommonTextEnum.STREET_TYPE);
    this.comboComplement = this.commonService.getComplementAddress();
    this.customerService.getComboOccupation().subscribe(
      (data) => this.getComboOccupationSuccess(data),
      (error) => this.getComboOccupationError(error)
    );
    this.customerService.getComboMaritalStatus().subscribe(
      (data) => this.getComboMaritalStatusSuccess(data),
      (error) => this.getComboMaritalStatusError(error)
    );
    this.customerService.searchDataCustomer(this.user).subscribe(
      (data) => this.searchDataCustomerSuccess(data),
      (error) => this.searchDataCustomerError(error)
    );
  }

  private getComboMaritalStatusSuccess(data: any) {
    this.comboMaritalState = data;
  }

  private getComboMaritalStatusError(error: any) {
    console.log(
      'DataClientComponent - getComboMaritalStatusError, Error: ' +
        JSON.stringify(error)
    );
  }

  private getComboOccupationSuccess(data: any) {
    this.comboOccupation = data;
  }

  private getComboOccupationError(error: any) {
    console.log(
      'DataClientComponent - getComboOccupationError, Error: ' +
        JSON.stringify(error)
    );
  }

  private searchDataCustomerSuccess(data: any) {
    this.userData = data;
    this.userData.identityType = this.user.documentType;
    this.userData.identityNumber = this.user.documentId.toString();
    this.userData.monthlyIncome = null;
    this.userData.additionalIncome = null;
    this.userData.monthlyExpenses = null;
    this.addressFive =
      this.userData.fullAddress + ' ' + this.userData.complementAddress;

    this.getComboCity(this.userData.cityName);
  }

  private searchDataCustomerError(error: any) {
    console.log(
      'DataClientComponent - searchDataCustomerError, Error: ' +
        JSON.stringify(error)
    );
  }

  private setStreetType(type: string) {
    return (this.streetType = this.comboAddressOne.find(
      (data: any) => data.key === type
    ));
  }

  keyPress(event: any, maxlength: number) {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      return false;
    }

    if (event.srcElement.value.length + 1 > maxlength) {
      return false;
    }
  }

  isBotonDirectionActive() {
    if (this.addressOne === 'VE') {
      return this.userData.complementAddress !== ''
        ? 'buttonGreenScreen'
        : 'buttonGreenScreenDisabled';
    } else if (this.addressOne !== undefined && this.isHaveAddressOne) {
      return this.addressTwo !== '' &&
        this.addressThree !== '' &&
        this.addressFour !== ''
        ? 'buttonGreenScreen'
        : 'buttonGreenScreenDisabled';
    }

    return 'buttonGreenScreenDisabled';
  }

  isBotonDirectionDisable() {
    if (this.addressOne === 'VE') {
      return this.userData.complementAddress === '';
    } else if (this.addressOne !== undefined && this.isHaveAddressOne) {
      return (
        this.addressTwo === '' &&
        this.addressThree === '' &&
        this.addressFour === ''
      );
    }

    return true;
  }

  searchCity() {
    if (this.txtCity.searchTerm.length !== null) {
      if (this.txtCity.searchTerm.length > 2) {
        this.getComboCity(this.txtCity.searchTerm);
      }
    }
  }

  setCityId(e) {
    this.userData.cityId = e.key + '-' + e.value;
  }

  private getComboCity(city: string) {
    this.customerService.getComboCity(city).subscribe(
      (data) => {
        this.getComboCitySuccess(data);
        this.cityLoading = false;
      },
      (error) => {
        this.getComboCityError(error);
        this.cityLoading = true;
      }
    );
  }

  private getComboCitySuccess(data: any) {
    this.cities = data;
  }

  private getComboCityError(error: any) {
    console.log(
      'DataClientComponent - getComboCityError, Error: ' + JSON.stringify(error)
    );
  }

  selectAddressType(event: any) {
    this.isHaveAddressOne = false;
    this.isHaveAdressThree = false;

    this.streetType = this.setStreetType(event);
    this.clearDirectionComplements();
    if (event === CommonTextEnum.SIDEWALD_TYPE) {
      this.disabledFieldDirection = true;
      this.isKeyupAdressOne = false;
    } else {
      this.disabledFieldDirection = false;
      this.isKeyupAdressOne = true;
    }
  }

  private clearDirectionComplements() {
    this.addressTwo = '';
    this.addressThree = '';
    this.addressFour = '';
    this.isHaveAddressOne = false;
    this.isHaveAddressTwo = false;
  }

  setIsAddressTwo() {
    this.isHaveAddressOne = true;
    this.isHaveAddressTwo = true;
  }

  setIsAddressThree() {
    this.isHaveAdressThree = true;
  }

  viewAddressEdit() {
    this.editAddress = !this.editAddress;
  }

  saveDirection() {
    if (this.streetType.key === CommonTextEnum.SIDEWALD_TYPE) {
      this.userData.fullAddress = this.streetType.value;
    } else {
      this.userData.fullAddress =
        this.streetType.value +
        ' ' +
        this.addressTwo +
        ' ' +
        this.addressThree +
        ' - ' +
        this.addressFour;
    }
    this.addressFive =
      this.userData.fullAddress + ' ' + this.userData.complementAddress;
  }

  save() {
    this.isDisabledButton = true;
    this.loading = true;
    this.userService.setUser(this.user);
    this.googleAnalyticsEventsService.emitEvent(
      'Actualización Datos',
      'Actualiza datos cliente',
      this.type === 1
        ? 'Botón Actualiza Tus Datos'
        : 'Botón Muestrame el Aprobado',
      Date.now()
    );

    this.customerService.saveDataCustomer(this.userData).subscribe(
      (data) => this.saveDataCustomerSuccess(),
      (error) => {
        this.isDisabledButton = true;
        this.saveDataCustomerError(error);
      }
    );
  }

  getViability() {
    this.customerService
      .getViability(this.user.documentId, this.user.documentType)
      .subscribe((responseViability) => {
        if (!responseViability.clientViability) {
          this.showErrorViability(responseViability.messageViable, true);
          return;
        }

        if (
          responseViability.peaceAndSafe ===
          ViabilityResponseEnum.PEACE_AND_SAFE
        ) {
          const messageBoxPeaceAndSafe = this.showErrorViability(
            responseViability.messageViable
          );
          messageBoxPeaceAndSafe.afterClosed().subscribe(() => {
            this.showConfirmPeaceAndSafe();
          });
        }
      });
  }
  private saveDataCustomerSuccess() {
    if (this.type === DataClientType.BYMENU) {
      this.showMessageBox({
        icon: 'O',
        title: '',
        message: '¡Tus datos fueron actualizados exitosamente!',
        url: '',
        img: '',
        textButtom: 'Aceptar',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      });
    } else if (this.type === DataClientType.BYCAROUSEL) {
      this.googleAnalyticsEventsService.emitEvent(
        'Preselecta',
        'Inicio consulta preselecta',
        'Botón Muestrame el Aprobado',
        Date.now()
      );
      this.creditFlowService.getPreselect(this.preselect, this.user).subscribe(
        (data) => this.getPreselectSuccess(data),
        (error) => {
          this.isDisabledButton = true;
          this.getPreselectError(error);
        }
      );
    }
  }

  private getPreselectSuccess(data: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Preselecta',
      'Contesta consulta preselecta',
      'Botón Muestrame el Aprobado',
      Date.now()
    );

    if (data === 'valid') {
      this.isDisabledButton = false;
      this.router.navigate(['/master-page/credit-personalization']);
    }
  }

  private getPreselectError(error: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Preselecta',
      'No contesta consulta preselecta',
      'Botón Muestrame el Aprobado',
      Date.now()
    );
    this.msgError(error.code !== undefined ? error.code : error.message);
  }

  private saveDataCustomerError(error: any) {
    this.msgError(
      error.error.code !== undefined ? error.error.code : error.message
    );
  }

  private msgError(code: string) {
    let messageBox: MatDialogRef<any, any>;
    let url = '';
    let title = 'Por favor vuelve a intentarlo más tarde';
    let message =
      'En estos momentos no podemos actualizar tus datos, por favor vuelve a intentarlo más tarde';

    if (this.type === DataClientType.BYCAROUSEL) {
      url = 'master-page/salary-loans-overview';

      switch (code) {
        case 'CustomerManagement006':
        case 'CustomerManagement007':
          url = 'master-page/data-client-pep';
          title = 'Lo sentimos';
          message =
            'El proceso de preselecta generó un error, debe volver a procesar la operación';
          break;
        case 'CustomerManagement008':
          title = 'Lo sentimos';
          message =
            'Cliente no es viable de tramitar por este canal - presenta un reporte por hábitos de pago en centrales';
          break;
        case 'CustomerManagement028':
          url = '/welcome';
          title = 'Lo sentimos';
          message =
            'Cliente no es viable de tramitar por este canal - No tiene capacidad de pago';
          break;
        case 'GrowthAmount001':
          title = 'Lo sentimos';
          message =
            'Cliente no cumple con la política de crecimiento definida para canal digital';
          this.creditFlowService.setCreditFlowType(CreditFlowType.ANYFEE);
          break;
        case 'CustomerManagement9999':
          title = 'Por favor vuelve a intentarlo más tarde';
          message =
            'Algo inesperado acaba de ocurrir haciendo tu análisis financiero, por favor vuelve a intentarlo';
          break;
        case 'PayrollChecks9999':
          url = '/welcome';
          title = 'Lo sentimos';
          message = 'El proceso de preselecta generó un error';
          break;
      }
    }

    messageBox = this.showMessageBox({
      icon: 'I',
      title: title,
      message: message,
      url: url,
      img: '',
      textButtom: 'Aceptar',
      clearUser: false,
      feedback: false,
      routerDestination: '',
      stepId: 0,
      stepDescription: '',
    });

    messageBox.afterClosed().subscribe(() => {
      this.isDisabledButton = false;
    });
  }

  private showConfirmPeaceAndSafe() {
    const config = this.commonService.getMessageBoxConfirmHavePeaceAndSave();
    return this.showMessageBox(config);
  }

  private showErrorViability(messageViable: string, feedback = false) {
    const config = this.commonService.getMessageFromErrorCodeViability(
      messageViable,
      feedback
    );
    return this.showMessageBox(config);
  }

  private showMessageBox(messageBox: MessageBox) {
    this.loading = false;
    return this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      messageBox,
      500
    );
  }
}
