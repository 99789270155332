import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-rnec',
  templateUrl: './rnec.component.html',
  styleUrls: ['./rnec.component.css']
})
export class RnecComponent implements OnInit {
  codeRnec = '';
  msgError: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RnecComponent>,
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  sendCode() {
    if (isNaN(Number(this.codeRnec)) && this.validateTwoNumbers(this.codeRnec)) {
      this.dialogRef.close(this.codeRnec);
    }
    else {
      this.msgError = 'Error al validar el código';
    }
  }

  validateTwoNumbers(code: string): boolean {
    const numbers = '0123456789';
    let counter = 0;

    for ( let i = 0; i < code.length; i++) {
      if (numbers.indexOf(code.charAt(i), 0) !== -1) {
        counter++;
        if (counter >= 2) {
          return true;
        }
      }
    }
  }

}
