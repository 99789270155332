// Imports
import { Injectable } from '@angular/core';

// Declarations
import { ValidateResponse } from '../../interfaces/validate-response.interface';

@Injectable()
export class ValidateResponseService {
  private validateResponse: ValidateResponse;

  public setResponse(validateResponse: ValidateResponse) {
    this.validateResponse = validateResponse;
  }

  public getResponse() {
    return this.validateResponse;
  }
}
