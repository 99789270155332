import { Injectable } from '@angular/core';
import { RequestFile } from './../../interfaces/request-file.interface';

@Injectable()
export class RequestFileService {
  private requestFile: RequestFile;

  constructor() { }

  public setRequestFile(requestFile: RequestFile) {
    this.requestFile = requestFile;
  }

  public getRequestFile() {
    return this.requestFile;
  }
}
