import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { PassedCourse } from '../../interfaces/passed-course.interface';
import { AwsSignatureInputData } from '../../interfaces/aws-input.interface';
import { AwsSignatureService } from '../local/aws-signature.service';
import { UserService } from '../local/user.service';

@Injectable()
export class TrainingService {

  awsInputData: AwsSignatureInputData = new AwsSignatureInputData();

  constructor(
    private http: HttpClient,
    private AwsSignature: AwsSignatureService,
    private userService: UserService) {
    this.awsInputData.requestParameters = '';
    this.awsInputData.contentType = 'application/json';
    this.awsInputData.canonicalQuerystring = '';
  }

  setHeaders(method: string, canonicalUri: string, contentType: string, requestParameters?: any) {
    const userInfo = this.userService.getUser();

    this.awsInputData.method = method;
    this.awsInputData.canonicalUri = canonicalUri;
    this.awsInputData.host = environment.awsCognitoData.host;
    this.awsInputData.region = environment.awsCognitoData.region;
    this.awsInputData.service = environment.awsCognitoData.service;
    this.awsInputData.accessKey = userInfo.accessKey;
    this.awsInputData.secretKey = userInfo.secretKey;
    this.awsInputData.contentType = contentType;
    this.awsInputData.requestParameters = requestParameters ? requestParameters : '';

    const signature = this.AwsSignature.generateSignature(this.awsInputData);

    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': signature['Authorization'],
      'X-Amz-Security-Token': userInfo.sessionToken,
      'X-Amz-Date': signature['X-Amz-Date']
    });
  }

  searchCourse(sellerIdNumber: number): Observable<object> {
    const headers = this.setHeaders('GET', `/training-manager/courses/${sellerIdNumber}`, 'application/x-www-form-urlencoded');

    return this.http.get(environment.serverUrlTraining + 'courses/' + sellerIdNumber, { headers });
  }

  savePassedCourse(passedCourse: PassedCourse): Observable<object> {
    const headers = this.setHeaders('PUT', '/training-manager/courses/vendor/' + passedCourse.idVendor, 'application/json', JSON.stringify(passedCourse));

    return this.http.put(environment.serverUrlTraining + 'courses/vendor/' + passedCourse.idVendor, JSON.stringify(passedCourse), { headers });
  }
}
