import {
  AfterContentInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';

import { CommonService } from '../../../../shared/services/local/common.service';
import { CustomerService } from '../../../../shared/services/remote/customer.service';
import { GoogleAnalyticsEventsService } from '../../../../shared/services/remote/google-analytics-events.service';
import { PreselectService } from '../../../../shared/services/local/preselect.service';
import { User } from '../../../../shared/interfaces/user.interface';

@Component({
  selector: 'app-salary-loans-overview-graph',
  templateUrl: './salary-loans-overview-graph.component.html',
  styleUrls: ['./salary-loans-overview-graph.component.css'],
})
export class SalaryLoansOverviewGraphComponent
  implements OnInit, AfterContentInit
{
  @Input() user: User;
  @Output() loadingEmit = new EventEmitter();
  @Output() activeTabEmit = new EventEmitter();
  @Output() errorShowInformationEmit = new EventEmitter();

  @ViewChild('divScrollBar', { static: false }) divScrBar: ElementRef;
  @ViewChild('divGraph', { static: false }) divGraph: ElementRef;

  public carouselOne: NguCarousel<any>;

  classCss: string;
  countTabs = 0;
  currentSlide = 0;
  days = [];
  externalUser: any;
  numberErrorShowInformation = 0;
  tabIndex: number;
  tabsSlide = [];
  widthTabs = 0;

  carouselBanner = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 800,
    interval: 4000,
    point: {
      visible: false,
    },
    load: 1,
    loop: false,
    touch: true,
  };

  constructor(
    private commonService: CommonService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.searchLoansCustomer();
  }

  private searchLoansCustomer() {
    this.days = this.commonService.getDays();

    this.customerService.searchLoansCustomer(this.user).subscribe(
      (response) => this.searchLoansCustomerSuccess(response),
      (error) => this.searchLoansCustomerError(error)
    );
  }

  private searchLoansCustomerSuccess(response: any) {
    response[0].active = true;
    response[0].last = false;

    for (let i = 0; i < response.length; i++) {
      if (i > 0) {
        response[i].active = false;
        response[i].last = true;
      }

      if (response[i].payerName.length > 18) {
        response[i].originalPayerName = response[i].payerName;
        response[i].payerName =
          response[i].payerName.substring(0, 15).trim() + '...';
      }

      this.calculatingResizeGraph(response[i]);
      this.convertDateView(response[i]);
      response[i].index = i.toString();
    }

    this.tabsSlide = response;
    this.countTabs = this.tabsSlide.length + 2;
    this.widthTabs = 165 * this.countTabs;

    setTimeout(() => {
      this.activateTab(response[0].obligationId, true);
    }, 800);
  }

  private calculatingResizeGraph(response: any) {
    const calDay: number = Math.round(
      (+response.missingLoanQuotas / +response.loanQuotas) * 100
    );
    if (calDay === 100) {
      response.graphSize = '0';
      response.circleStyle = {
        top: '78%',
        left: '0',
        transform: 'translate(0, -78%)',
      };
    } else if (calDay >= 66 && calDay <= 99) {
      response.graphSize = '25';
      response.circleStyle = {
        top: '85%',
        left: '24%',
        transform: 'translate(-24%, -85%)',
      };
      response.polygonPoint = '0,100 360,100 0,0';
    } else if (calDay >= 33 && calDay <= 65) {
      response.graphSize = '50';
      response.circleStyle = {
        top: '92%',
        left: '50%',
        transform: 'translate(-50%, -92%)',
      };
      response.polygonPoint = '0,100 200,100 0,0';
    } else if (calDay >= 1 && calDay <= 32) {
      response.graphSize = '75';
      response.circleStyle = {
        top: '98%',
        left: '76%',
        transform: 'translate(-76%, -98%)',
      };
      response.polygonPoint = '0,100 130,100 0,0';
    } else if (calDay === 0) {
      response.graphSize = '100';
      response.circleStyle = {
        top: '100%',
        left: '100%',
        transform: 'translate(-100%, -100%)',
      };
      response.polygonPoint = '0,100 100,100 0,0';
    }
  }

  private convertDateView(response: any) {
    response.initialDate =
      this.days[+response.initialDate.substring(5, 7) - 1] +
      '/' +
      response.initialDate.substring(0, 4);
    response.finalLoanDate =
      this.days[+response.finalLoanDate.substring(5, 7) - 1] +
      '/' +
      response.finalLoanDate.substring(0, 4);
    response.nextQuotaDate =
      this.days[+response.nextQuotaDate.substring(5, 7) - 1] +
      '/' +
      response.nextQuotaDate.substring(0, 4);
  }

  onMoveFn(data: NguCarouselStore) {
    this.activateTab(this.tabsSlide[data.currentSlide].obligationId, false);
  }

  activateTab(obligaId: string, firtsInteraction: boolean) {
    this.googleAnalyticsEventsService.emitEvent(
      'Historial Libranzas',
      'Seleccionar Pagaduría',
      'Carrusel Movimiento',
      Date.now()
    );
    this.divScrBar.nativeElement.scrollLeft = 0;

    this.tabsSlide.forEach((tab) => {
      document.getElementById(tab.obligationId).className =
        'aTabs_disabled float-left text-center ' +
        (!tab.last ? 'marginRight4' : '');

      if (!firtsInteraction) {
        this.activeTabEmit.emit({
          obligationId: tab.obligationId,
          classCss: 'd-none',
        });
      }

      if (tab.obligationId === obligaId) {
        this.currentSlide = tab.index;
        document.getElementById(tab.obligationId).className =
          'aTabs float-left text-center ' + (!tab.last ? 'marginRight4' : '');
        this.divScrBar.nativeElement.scrollLeft =
          ((this.widthTabs - this.divGraph.nativeElement.clientWidth) /
            this.countTabs) *
          (+tab.index + (this.countTabs > 1 ? 1.5 : 1));

        if (!firtsInteraction) {
          this.activeTabEmit.emit({
            obligationId: tab.obligationId,
            classCss: 'd-block',
          });
        }
      }

      this.preselectService.setPreselect({
        obligationInitialValue: tab.loanAmount,
        obligationBalance: tab.currentDeb,
        feeAmount: tab.currentQuotaAmount,
        loanQuota: tab.loanQuotas,
        missingLoanQuotas: tab.missingLoanQuotas,
      });
    });
  }

  private searchLoansCustomerError(error: any) {
    this.loadingEmit.emit({ loading: false });

    if (error.status === 500 || error.status === 504) {
      if (this.numberErrorShowInformation > 3) {
        this.errorShowInformationEmit.emit({ type: 'G' });
      } else {
        this.numberErrorShowInformation++;

        setTimeout(() => {
          this.searchLoansCustomer();
        }, 4000);
      }
    }
  }

  private reloadSalaryLoans() {
    this.loadingEmit.emit({ loading: true });
    this.searchLoansCustomer();
  }
}
