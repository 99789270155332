// Imports
import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Components
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

// Enum
import { CustomerDecisionEnum } from '../../../shared/enums/customer-decision-type.enum';
import { CreditFlowTypeValue } from '../../../shared/enums/credit-flow-type.enum';
import { DocumentsOnBaseType } from '../../../shared/enums/documents-on-base-type.enum';
import { EventCodeEnum } from '../../../shared/enums/event-code.enum';
import { ValidationMethod } from '../../../shared/enums/validation-method.enum';

//Interface
import { IDataAccount } from '../../../shared/interfaces/data-account.interface';
import { Event } from '../../../shared/interfaces/event.interface';
import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { User } from '../../../shared/interfaces/user.interface';

// Declaration
import { ApiService } from '../../../shared/services/remote/api.service';
import { CommonService } from '../../../shared/services/local/common.service';
import { CustomerService } from '../../../shared/services/remote/customer.service';
import { EventAnalyticsService } from '../../../shared/services/local/event-analytics.service';
import { EventService } from '../../../shared/services/remote/event.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';
import { PayrollPayerService } from '../../../shared/services/remote/payroll-payer.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { RequestFileService } from '../../../shared/services/local/request-file.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';

@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.css'],
})
export class AccountSelectionComponent implements OnInit {
  @ViewChild('chkReptAccept', { static: false }) chkAcc: any;

  allDocumentsChecked: boolean;
  haveSinglePromissoryNote = false;
  hideMessage = true;
  icon: string;
  loading: boolean;
  preselect: Preselect;
  products: IDataAccount[];
  requiredTelephoneSale: boolean;
  retryAttemps: number;
  selectedAccountId: string;
  title: string;
  titleButton: string;
  titleDocuments: string;

  private event: Event;
  private user: User;

  documentList: any[] = [];

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private eventAnalytics: EventAnalyticsService,
    private eventService: EventService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private payrollPayerService: PayrollPayerService,
    private preselectService: PreselectService,
    private requestFileService: RequestFileService,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.preselect = this.preselectService.getPreselectPersonalized();
    this.retryAttemps = 0;
    this.user = this.userService.getUser();
  }

  ngOnInit() {
    this.icon = 'S';
    this.title = '¿En qué cuenta deseas tu desembolso?';
    this.titleButton = 'Finalizar';
    this.titleDocuments = 'Documentos del cliente';
    this.stepperService.setStep('AccountSelection');
    this.googleAnalyticsEventsService.emitEvent(
      'Cuenta Desembolso',
      'Ingreso página selecionar la cuenta del desembolso',
      'Página account-selection',
      Date.now()
    );

    this.loading = true;
    this.customerService.searchDataAccounts(this.user).subscribe(
      (data: IDataAccount[]) => this.searchDataCustomerSuccess(data),
      (error) => this.searchDataCustomerError(error)
    );
  }

  confirmUploadDocuments() {
    this.googleAnalyticsEventsService.emitEvent(
      'Cuenta Desembolso',
      'Selecciona y confirma cuenta',
      'Botón La Quiero en Esta en Cuenta',
      Date.now()
    );

    if (this.selectedAccountId) {
      this.loading = true;
      this.event = {
        documentType: this.user.documentType,
        documentNumber: this.user.documentId,
        event: 'ACCOUNT_SELECTED',
        name: this.user.name,
        eventText: this.selectedAccountId,
      };

      this.eventService.pushEvent(this.event).subscribe(
        () => this.acceptanceDataSuccess(),
        (error) => this.messageError(error)
      );
      this.eventAnalytics.sendEventAnalytics(
        EventCodeEnum.ACCOUNT_SELECTED,
        'Cuenta para desembolso y tipos documentales seleccionados con éxito'
      );
    } else {
      this.hideMessage = false;
    }
  }

  formatAccount(id: string) {
    return id.replace(/-/gi, '').trim();
  }

  onClick() {
    if (
      this.decisionTypeIsNewPayrollLoanByPhoneCall() &&
      !this.requiredTelephoneSale
    ) {
      this.confirmUploadDocuments();
    } else {
      const dialog = this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'E',
          title: '',
          message:
            'Recuerda que si los documentos no se suben a tiempo, es probable que tu Libranza sea rechazada en Fábrica.',
          url: '',
          textButtom: 'Ya subí los documentos',
          clearUser: false,
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
        },
        500
      );

      dialog.afterClosed().subscribe((res) => {
        this.confirmUploadDocuments();
      });
    }
  }

  popUpCapacitation() {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: '',
        title: 'Mejoras para este periodo',
        message:
          'En la pantalla de datos de desembolso ahora encontraras un Checklist de documentos requeridos por la pagaduría de tu cliente',
        img: '/assets/prestaya_mejoras.gif',
        url: '',
        textButtom: 'Entendí',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  checkDocument(index: number) {
    let documentListRequire = 0;
    this.allDocumentsChecked = false;

    this.documentList[index].checked = !this.documentList[index].checked;

    for (let indexList = 0; indexList < this.documentList.length; indexList++) {
      if (
        this.documentList[indexList].checked ||
        this.documentList[indexList].onbase.documentAlreadyInCloud
      ) {
        documentListRequire = documentListRequire + 1;
      }
    }

    if (documentListRequire === this.documentList.length) {
      this.allDocumentsChecked = true;
    }
  }

  checkReportDocument() {
    this.allDocumentsChecked = !this.chkAcc.checked;
  }

  decisionTypeIsNewPayrollLoanByPhoneCall() {
    return this.userService.isNewPayrollLoanByPhoneCall();
  }

  decisionTypeIsNewPayrollLoan() {
    return this.userService.isNewPayrollLoan();
  }

  isCheckeableForNoteDocument(cucCode) {
    return cucCode === DocumentsOnBaseType.SINGLE_PROMISSORY_NOTE
      ? this.decisionTypeIsNewPayrollLoanByPhoneCall()
      : false;
  }

  isSinglePromissoryNoteDocument(index: number, isChecked: boolean): boolean {
    return (
      isChecked &&
      this.documentList[index].onbase &&
      this.documentList[index].onbase.cucCode ===
        DocumentsOnBaseType.SINGLE_PROMISSORY_NOTE
    );
  }

  showMessageAuthenticationInOffice(): boolean {
    return this.userService.requireAuthenticationInOffice();
  }

  private acceptanceDataSuccess() {
    this.setFiles();

    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente',
      'Aceptar Autenticación Otp',
      'Ok Acepto',
      Date.now()
    );

    this.sendTermsAndConditions();

    if (
      this.toggleService.getToggle('ADL_TEAM_CLIENT_VALIDATION').featureEnabled
    ) {
      this.customerService
        .validateCustomerCellPhone(this.userService.getUserInfo())
        .subscribe(
          (result) => {
            this.user.simPendingAuthentication =
              result['status'] === 'AUTHENTICATION_SIM_PENDING';

            this.getCodeOtpSuccess();
          },
          (error) => this.messageError(error)
        );
    }

    this.apiService
      .generateRenewalConfirmationCode(this.user.obligationId, {
        documentType: this.user.documentType,
        documentNumber: this.user.documentId,
        confirmationCodeType: 'sms',
      })
      .subscribe(
        () => this.getCodeOtpSuccess(),
        (error) => this.messageError(error)
      );
  }

  private getDocumentPayerSuccess(dataDocumentPayer: any) {
    this.loading = false;
    this.requiredTelephoneSale = dataDocumentPayer.requiredTelephoneSale;

    if (
      !dataDocumentPayer.onBaseServiceActive &&
      this.toggleService.getToggle('ONBASE_FEATURE_TOGGLE').featureEnabled
    ) {
      this.getDocumentPayerError('non conection');
    }

    this.documentList = dataDocumentPayer.documents.filter((item: any) => {
      return item.nameDocument;
    });

    if (!this.requiredTelephoneSale) {
      this.requiredTelephoneSale =
        this.decisionTypeIsNewPayrollLoanByPhoneCall();
    }

    this.allDocumentsChecked =
      (this.decisionTypeIsNewPayrollLoanByPhoneCall() &&
        !this.requiredTelephoneSale) ||
      this.documentList.length === 0;
    this.userService.setDocumentListByUserType(
      this.requiredTelephoneSale ? this.documentList : []
    );
  }

  private getCodeOtpSuccess() {
    this.router.navigate(['master-page/confirm-otp', { type: 'ATH' }]);
  }

  private getDocumentPayerError(error: any) {
    this.loading = false;
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'W',
        title: 'Algo no salió bien',
        message:
          'En este momento tenemos inconvenientes técnicos con onbase. Por favor, solicita todos los documentos',
        url: '',
        textButtom: 'Entendido',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  private messageError(error: any) {
    this.loading = false;

    if (error.status === 403) {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'E',
          title: '',
          message: 'Ocurrio algo, cierra esta ventana e intenta de nuevo',
          url: '',
          textButtom: 'Volver a Intentar',
          clearUser: false,
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
        },
        500
      );
    }
  }

  private setFiles() {
    let onBaseDocuments = [...this.documentList];
    if (this.haveSinglePromissoryNote) {
      onBaseDocuments = onBaseDocuments.filter(
        (document) =>
          document.onbase.cucCode !== DocumentsOnBaseType.SINGLE_PROMISSORY_NOTE
      );
    }

    this.requestFileService.setRequestFile({
      idCase: this.user.idCaseDecrim,
      identificationType: this.user.documentType,
      identificationNumber: this.user.documentId.toString(),
      officeCode: this.user.officeCode,
      sellerIdNumber: this.user.sellerIdNumber,
      accountType: this.products.find(
        (x) => x.accountId === this.selectedAccountId
      ).accountType,
      accountNumber: this.selectedAccountId,
      generatedId: this.user.generatedId,
      insuranceCode: this.preselect.bankInsurance.code,
      renewalConfirmationCode: undefined,
      validationMethod: this.user.validationMethod,
      onBaseDocuments: onBaseDocuments,
      coverageSpouse: this.preselect.spouse,
      maxCoverage: this.preselect.maxCoverage,
      creditStudy: this.preselect.creditStudy,
      adjustmentInterests: this.preselect.adjustmentInterests,
      adjustmentInsurancePremium: this.preselect.advancedInsurancePremium,
      accidentInsurancePrice: this.preselect.accidentInsurancePrice,
      totalDiscounts: this.preselect.discounts,
    });

    this.userService.setDocumentListByUserType(onBaseDocuments);
  }

  private searchDataCustomerSuccess(dataProducts: IDataAccount[]) {
    this.products = dataProducts.filter((dataProduct, index) => {
      for (let i = dataProducts.length - 1; i >= 0; i--) {
        if (
          this.formatAccount(dataProducts[i].accountId) ===
          this.formatAccount(dataProduct.accountId)
        ) {
          return index === i;
        }
      }
      return false;
    });

    const channel = this.preselect.authMethod
      ? ValidationMethod.getChannel(this.preselect.authMethod)
      : 0;

    this.payrollPayerService
      .getPayrollDocuments({
        payerUniqueName:
          this.user.customerDecisionType ===
          CustomerDecisionEnum.NEW_PAYROLL_LOAN
            ? this.preselect.payerName
            : this.preselect.payerUniqueName,
        idChannel: channel,
        documentType: this.preselect.documentType,
        documentNumber: this.preselect.documentNumber.toString(),
        openingObligationDate: this.preselect.openingObligationDate,
        creditFlowType: CreditFlowTypeValue.getCreditFlowType(this.user),
        obligationId: this.preselect.obligationId,
        salesProcessType: this.preselect.salesProcessType,
        journeyId: this.user.salesJourneyId,
      })
      .subscribe(
        (data: any) => {
          this.getDocumentPayerSuccess(data);
        },
        (error: any) => {
          this.getDocumentPayerError(error);
        }
      );
  }

  private sendRequestFileError(error: any) {
    this.retryAttemps++;

    const errorCode =
      this.retryAttemps === 3 ? 'AttempsRetry' : error.error.code;
    this.loading = false;
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      this.commonService.getMessageFromErrorCodePayerOpen(
        errorCode,
        this.preselect.platformAuthorizationUrl
      ),
      500
    );
  }

  private sendRequestFileSuccess() {
    this.router.navigate(['/master-page/success-credit']);
  }

  private sendTermsAndConditions() {
    this.apiService
      .sendTermsAndConditions(
        this.user.obligationId,
        this.requestFileService.getRequestFile()
      )
      .subscribe(
        () => console.log('sendTermsAndConditions OK'),
        (error) => this.sendRequestFileError(error)
      );
  }

  private searchDataCustomerError(error) {
    this.loading = false;
    console.log(
      'AccountSelectionComponent - searchDataCustomerError, Error: ' +
        JSON.stringify(error)
    );
  }
}
