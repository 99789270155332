import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingTypeEnum } from '../../shared/enums/loading-type.enum';
import { UploadsFileOpenType } from '../../shared/enums/upload-file-open-type.enum';

import { User } from '../../shared/interfaces/user.interface';

import { ApiService } from '../../shared/services/remote/api.service';
import { CommonService } from '../../shared/services/local/common.service';
import { DecrimFilesService } from '../../shared/services/local/decrim-files.service';
import { PayrollCheckService } from '../../shared/services/local/payroll-check.service';
import { PreselectService } from '../../shared/services/local/preselect.service';
import { ToggleService } from '../../shared/services/local/toggle.service';
import { UserService } from '../../shared/services/local/user.service';

import { UploadFileComponent } from '../../shared/components/upload-file/upload-file.component';

@Component({
  selector: 'app-redirect-from-new-front',
  templateUrl: './redirect-from-new-front.component.html',
})
export class RedirectFromNewFrontComponent implements OnInit, OnDestroy {
  @ViewChild('load', { static: false }) objLoad: any;

  externalUser: any;
  retryLimitSeconds = 600;
  retryIntervalSeconds = 30;
  user: User;

  private dialogRef: any;

  constructor(
    private commonService: CommonService,
    private decrimFilesService: DecrimFilesService,
    private payrokllCheckService: PayrollCheckService,
    private preselectService: PreselectService,
    private router: Router,
    private userService: UserService,
    private toggleService: ToggleService
  ) {
    // Redirect of redesign
    if (this.userService.getUser().accessKey === undefined) {
      if (window.name === '') {
        this.router.navigate(['/welcome']);
      } else {
        let infoNewFront = JSON.parse(atob(window.name));
        this.externalUser = infoNewFront.externalUser;
        this.toggleService.setToggle(infoNewFront.toogleList);
      }

      window.name = '';
    }
  }

  async ngOnInit() {
    await this.initUser();
    this.validateCustomerType();
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  async initUser() {
    if (this.userService.getUser().accessKey === undefined) {
      await this.userService.setExternalUser(this.externalUser);
    }

    this.user = this.userService.getUser();

    if (this.user.decrimFiles) {
      await this.decrimFilesService.setExternalDecrimFiles();
    }
  }

  private checkPayrollCheckInProgress() {
    const preselect = this.preselectService.getPreselect();
    this.dialogRef = this.commonService.showMesssageBox(
      UploadFileComponent,
      true,
      {
        title: 'Sube tu desprendible de pago de ' + preselect.payerName,
        message:
          'Si no lo tienes, reclámalo en la oficina de ' +
          preselect.payerName.toLocaleLowerCase() +
          ' y sube aquí un PDF del desprendible',
        textButtom: 'Subir desprendible',
        type: UploadsFileOpenType.INSALARYLOANSNEW,
      },
      500
    );
  }

  private validateCustomerType() {
    if (
      this.userService.isNewPayrollLoanByPhoneCall() &&
      this.userService.isCustomerNewPayrollLoan()
    ) {
      this.validateNewPayrollLoanByPhoneCall();
    } else if (this.userService.isCustomerNewPayrollLoan()) {
      this.checkPayrollCheckInProgress();
    } else {
      this.router.navigate(['master-page/salary-loans-overview']);
    }
  }

  private validateNewPayrollLoanByPhoneCall() {
    const preselect = this.preselectService.getPreselect();

    this.objLoad.visible = true;
    this.objLoad.title = this.userService.isPhoneSaleIsNewLoan()
      ? 'Estamos validando si el cliente ya subió el desprendible'
      : 'Estamos procesando tu solicitud';
    this.objLoad.subtitle = this.commonService.getMessageLoadingCustomer();
    this.objLoad.changeSpinner(LoadingTypeEnum.PIXEL_SPINNER_SHOW);

    this.payrokllCheckService
      .waitForPayrollCheck(
        {
          payerName: preselect.payerName,
          payerNit: preselect.payerNit,
          payerUniqueName: preselect.payerUniqueName,
          payerWeb: '',
          obligationId: preselect.obligationId,
          originalPayerName: preselect.payerName,
          sectorNumber: preselect.sectorNumber,
        },
        this.user
      )
      .subscribe(
        () => {},
        () => {},
        () => {
          this.objLoad.visible = false;
        }
      );
  }
}
