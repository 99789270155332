export enum ValidationMethodEnum {
  CERTIHUELLA = "Certihuella",
  DECRIM = "Decrim",
  WITHOUT_VALIDATION_METHOD = ""
}

export class ValidationMethod {
  static getChannel(validationMethodEnum: ValidationMethodEnum): number {
    return validationMethodEnum === ValidationMethodEnum.CERTIHUELLA
      ? 1
      : validationMethodEnum === ValidationMethodEnum.DECRIM
      ? 2
      : undefined;
  }
}
