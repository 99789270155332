import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { InsuranceData } from '../../interfaces/insurance-data.interface';
import { User } from '../../interfaces/user.interface';
import { timeout } from 'rxjs/operators';

@Injectable()
export class BankInsuranceService {
  constructor(private http: HttpClient) {}

  getBankInsuranceData(
    user: User,
    insuranceData: InsuranceData
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl +
        'banking-insurances/offerings/' +
        user.documentType +
        '/' +
        user.documentId,
      insuranceData
    );
  }
}
