import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { User } from '../../interfaces/user.interface';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BackOfficeService {
  constructor(
    public http: HttpClient) { }

  getPayrollLoanStatus(user: User): Observable<object> {
    return this.http.get(environment.serverUrl + 'api/back-office/payrollloans/' + user.sellerIdNumber + '/' + user.documentType + '/' + user.documentId + '/status');
  }
}
