import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { TransactionAudit } from '../../interfaces/transaction-audit.interface';

@Injectable()
export class AuditService {
  constructor(private http: HttpClient) { }

  saveTransactionalAuditEvent(
    transactionAudit: TransactionAudit,
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'transaction-audit',
      transactionAudit,
    );
  }

  saveTermsAndConditions(
    transactionAudit: TransactionAudit,
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl +
      'terms-and-conditions/' +
      transactionAudit.documentType +
      '/' +
      transactionAudit.documentNumber,
      transactionAudit,
    );
  }

  saveCreditBureauReporting(
    transactionAudit: TransactionAudit,
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl +
      'credit-bureau-reporting/' +
      transactionAudit.documentType +
      '/' +
      transactionAudit.documentNumber,
      transactionAudit,
    );
  }

  saveTransactionalAuditEventAnalytics(
    transactionAudit: TransactionAudit,
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + 'transaction-audit-analytics/', transactionAudit,
    );
  }
}
