import { of as observableOf, Observable } from 'rxjs';

import { retryWhen, catchError, map, delay } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { User } from '../../../shared/interfaces/user.interface';

import { CreditFlowType } from '../../enums/credit-flow-type.enum';
import { CustomerTypeEnum } from '../../enums/customer-type.enum';
import { DecisionEnum } from '../../enums/decision-type.enum';
import { EventCodeEnum } from '../../enums/event-code.enum';
import { UploadsFileOpenType } from '../../enums/upload-file-open-type.enum';

import { UserService } from './user.service';

import { CommonService } from './common.service';
import { EventAnalyticsService } from './event-analytics.service';
import { PreselectService } from './preselect.service';

import { CustomerService } from '../remote/customer.service';
import { GoogleAnalyticsEventsService } from '../remote/google-analytics-events.service';

import { PopUpPepComponent } from '../../components/pop-up/pop-up-pep.component';
import { UploadFileComponent } from '../../components/upload-file/upload-file.component';

@Injectable()
export class PayrollCheckService {
  retryIntervalSeconds = 30;
  retryLimitSeconds = 600;

  constructor(
    private commonService: CommonService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private userService: UserService
  ) {}

  waitForPayrollCheck(infoPayer: any, user: User): Observable<any> {
    return this.checkPayrollCheckLoaded(infoPayer, user).pipe(
      map((dataCheckPayrollCheckLoaded: any) => {
        this.customerUploadPayrollCheckSuccess(
          dataCheckPayrollCheckLoaded,
          infoPayer,
          user
        );

        return true;
      }),
      catchError((error: any) => {
        this.customerUploadPayrollCheckError(error, infoPayer, user);
        return observableOf(error);
      })
    );
  }

  checkPayrollCheckLoaded(infoPayer: any, user: User) {
    let retryCount = 0;

    const queryParamsCustomerNew = this.userService.isPhoneSaleIsNewLoan()
      ? `?payer-nit=${infoPayer.payerNit}&sector-number=${infoPayer.sectorNumber}&client-type=${CustomerTypeEnum.NEW_KNOWN_CUSTOMER}`
      : null;

    return this.customerService
      .getFileCustomer(
        'unknown',
        infoPayer.obligationId,
        user,
        queryParamsCustomerNew
      )
      .pipe(
        retryWhen((attempts) =>
          attempts.pipe(
            map((error: any) => {
              if (
                error.error.code === 'PayrollChecks001' &&
                ++retryCount * this.retryIntervalSeconds <
                  this.retryLimitSeconds
              ) {
                return error;
              } else {
                throw error;
              }
            }),
            delay(this.retryIntervalSeconds * 1000)
          )
        )
      );
  }

  uploadFile(infoPayer: any, user: User) {
    this.googleAnalyticsEventsService.emitEvent(
      'Ofertas Libranzas',
      'Subir desprendible',
      '	Muestrame el Aprobado',
      Date.now()
    );

    const preselect = this.preselectService.getPreselect();

    this.selectWorkflow(CreditFlowType.ANYFEE, user);
    this.preselectService.setPreselectInfoPayer(
      preselect,
      infoPayer,
      this.userService.authMethod,
      this.userService.responseAuthMethod
    );

    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      infoPayer.originalPayerName
    );

    this.commonService.showMesssageBox(
      UploadFileComponent,
      true,
      {
        title: 'Sube tu desprendible de pago de ' + infoPayer.payerUniqueName,
        message:
          infoPayer.payerWeb === ''
            ? 'Si no lo tienes, reclámalo en la oficina de ' +
              infoPayer.payerName.toLocaleLowerCase() +
              ' y sube aquí un PDF del desprendible'
            : 'Si no lo tienes, puedes descargarlo en la página de ' +
              infoPayer.payerName.toLocaleLowerCase() +
              ' <a class="labelGreen" href="' +
              infoPayer.payerWeb +
              '" target="_blank">aquí</a>',
        textButtom: 'Subir desprendible',
        type: this.userService.isPhoneSaleIsNewLoan()
          ? UploadsFileOpenType.INSALARYLOANSNEW
          : UploadsFileOpenType.INSALARYLOANS,
      },
      500
    );
  }

  selectWorkflow(creditFlowType: CreditFlowType, user: User) {
    if (user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL) {
      user.creditFlowType = CreditFlowType.CELLPHONE;
    } else {
      user.creditFlowType = creditFlowType;
    }
  }

  private customerUploadPayrollCheckSuccess(
    dataCheckPayrollCheckLoaded: any,
    infoPayer: any,
    user: User
  ) {
    this.processCustomerPayrollCheck(
      dataCheckPayrollCheckLoaded.id,
      infoPayer,
      user
    );
  }

  private customerUploadPayrollCheckError(
    errorCheckPayrollCheckLoaded: any,
    infoPayer: any,
    user: User
  ) {
    if (errorCheckPayrollCheckLoaded.error.code === 'PayrollChecks001') {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        false,
        {
          icon: 'L',
          title: 'El desprendible no ha sido cargado',
          message:
            'Tu cliente no ha cargado el desprendible. Te recomendamos hacer seguimiento al caso',
          url: '',
          clearUser: this.userService.isPhoneSaleIsNewLoan(),
          feedback: this.userService.isPhoneSaleIsNewLoan(),
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
          textButtom: 'Entendido',
        },
        500
      );
    } else if (
      errorCheckPayrollCheckLoaded.error.code === 'PayrollCheckUpload002'
    ) {
      this.processCustomerPayrollCheck(
        errorCheckPayrollCheckLoaded.id,
        infoPayer,
        user
      );
    } else {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'E',
          title: 'Lo sentimos',
          message:
            'Se presento un problema técnico, por favor vuelve a ingresar',
          url: '/welcome',
          textButtom: 'Volver a Intentar',
          clearUser: true,
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
        },
        500
      );
    }
  }

  private processCustomerPayrollCheck(id: any, infoPayer: any, user: User) {
    const dialog = this.commonService.showMesssageBox(
      PopUpPepComponent,
      false,
      {
        icon: 'O',
        title: 'Desprendible cargado',
        message:
          'El cliente cargó su información y fue exitoso, puedes continuar con el proceso',
        url: '',
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
        textButtom: 'Continuar',
      },
      500
    );
    dialog.afterClosed().subscribe(() => {
      user.customerPayrollCheckId = id;
      this.userService.setUser(user);

      this.uploadFile(infoPayer, user);
    });
  }
}
