import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { CreditFlowType } from '../../../shared/enums/credit-flow-type.enum';
import { CustomerDecisionEnum } from '../../../shared/enums/customer-decision-type.enum';
import { CustomerTypeEnum } from '../../../shared/enums/customer-type.enum';
import { ModalityType } from '../../../shared/enums/modality-type.enum';

import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { Toggle } from '../../../shared/interfaces/toggle.interface';
import { User } from '../../../shared/interfaces/user.interface';

import { CommonService } from '../../../shared/services/local/common.service';
import { CreditFlowService } from '../../../shared/services/local/credit-flow.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';
import { ValidateResponseService } from '../../../shared/services/local/validate-response.service';

import { EventService } from '../../../shared/services/remote/event.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

import { CalculatorComponent } from '../calculator/calculator.component';
import { CustomerFeedbackComponent } from '../../../shared/components/customer-feedback/customer-feedback.component';
import { PopUpCertificateComponent } from '../../../shared/components/pop-up-certificate/pop-up-certificate.component';
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

@Component({
  selector: 'app-credit-personalization',
  templateUrl: './credit-personalization.component.html',
  styleUrls: ['./credit-personalization.component.css'],
})
export class CreditPersonalizationComponent
  implements AfterViewInit, AfterViewChecked, AfterContentInit, OnInit, OnDestroy
{
  @ViewChild('txtValueCredit', { static: false }) txtValueCredit: ElementRef;
  @ViewChild('txtTermCredit', { static: false }) txtTermCredit: ElementRef;
  @ViewChild('btnOk', { static: false }) btnOk: ElementRef;
  @ViewChild('appLoading', { static: false }) appLoading: any;

  loading: boolean;
  loyaltyAnyFeeToggle: Toggle;
  loyaltySameFeeToggle: Toggle;
  preselect: Preselect;
  quote: number;
  term: number;
  terErrorMax: boolean;
  terErrorMin: boolean;
  terValue: boolean;
  timBtnOk: any;
  timTxtTerCre: any;
  timTxtValCre: any;
  useFootprintsNewServiceToggle: boolean;
  valErrorMin: boolean;
  valErrorMax: boolean;
  value: number;
  valValue: boolean;

  private user: User;
  private interestRateMax: number;

  constructor(
    private commonService: CommonService,
    private creditFlowService: CreditFlowService,
    private changeDetector: ChangeDetectorRef,
    private eventService: EventService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private renderer2: Renderer2,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService,
    private validateResponseService: ValidateResponseService
  ) {
    this.user = this.userService.getUser();
    this.preselect = this.preselectService.getPreselect();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.showMessageMaxFeeNumberRule();
  }

  ngAfterContentInit() {
    this.timTxtValCre = setTimeout(() => {
      this.renderer2
        .selectRootElement(this.txtValueCredit.nativeElement)
        .focus();
      this.renderer2
        .selectRootElement(this.txtValueCredit.nativeElement)
        .select();
    }, 500);
  }

  ngBlurText(firstInteraction: number, focus: boolean) {
    this.valErrorMax = false;
    this.valErrorMin = false;
    this.terErrorMax = false;
    this.terErrorMin = false;

    if (firstInteraction === 1) {
      this.googleAnalyticsEventsService.emitEvent(
        'Personalizar Oferta',
        'Define monto requerido',
        'Texto Valor Crédito',
        Date.now()
      );

      this.valValue = false;
      if (
        this.txtValueCredit.nativeElement.value !== '' &&
        this.value >= this.preselect.minLoanAmount &&
        this.value <= this.preselect.maxLoanAmount
      ) {
        if (this.value % 1000 !== 0) {
          this.value = Math.floor(this.value / 1000) * 1000;
        }

        if (this.value > this.preselect.maxLoanAmount) {
          this.valErrorMax = true;
        } else {
          this.term = undefined;
          this.valValue = true;
          this.timTxtTerCre = setTimeout(() => {
            this.renderer2
              .selectRootElement(this.txtTermCredit.nativeElement)
              .focus();
            this.renderer2
              .selectRootElement(this.txtTermCredit.nativeElement)
              .select();
          }, 500);

          this.calculateValues();
        }
      } else if (
        this.value !== 0 &&
        this.value > this.preselect.maxLoanAmount
      ) {
        this.valErrorMax = true;
      } else if (
        this.value !== 0 &&
        this.value < this.preselect.minLoanAmount
      ) {
        this.valErrorMin = true;
      }

      this.resetRequiredTerm();
    } else if (firstInteraction === 2) {
      this.googleAnalyticsEventsService.emitEvent(
        'Personalizar Oferta',
        'Define plazo requerido',
        'Texto Plazo Crédito',
        Date.now()
      );

      this.terValue = false;
      if (
        this.txtTermCredit.nativeElement.value !== '' &&
        this.term >= this.preselect.minLoanTerm &&
        this.term <= this.preselect.maxLoanTerm
      ) {
        this.terValue = true;
        this.loading =
          this.creditFlowService.getCreditFlowType() === CreditFlowType.ANYFEE;

        this.timBtnOk = setTimeout(() => {
          const btn = this.btnOk.nativeElement;
          btn.focus();
        }, 500);

        this.calculateQuote();
      } else if (this.term !== 0 && this.term > this.preselect.maxLoanTerm) {
        this.terErrorMax = true;
      } else if (this.term !== 0 && this.term < this.preselect.minLoanTerm) {
        this.terErrorMin = true;
      }
    }
  }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      'Personalizar Oferta',
      'Ingreso página personalización crédito',
      'Página credit-personalization',
      Date.now()
    );

    this.quote = 0;
    this.stepperService.setStep('CreditPersonalization');
    this.loyaltyAnyFeeToggle = this.toggleService.getToggle('LOYALTY_ANY_FEE');
    this.loyaltySameFeeToggle =
      this.toggleService.getToggle('LOYALTY_SAME_FEE');
    this.interestRateMax = this.preselect.interestRate;
    this.useFootprintsNewServiceToggle = this.toggleService.getToggle(
      'USE_FOOTPRINTS_NEW_SERVICE_TOGGLE'
    ).featureEnabled;
  }

  ngOnDestroy() {
    this.clearTimers();
  }

  makeBlur(event: any) {
    event.target.blur();
  }

  private calculateValues() {
    this.calculateQuote();
    this.calculateMinLoanTerm();
  }

  private calculateMinLoanTerm() {
    this.preselect.minLoanTerm = this.getMinTerm(
      this.preselect.loanQuota,
      this.preselect.obligationBalance + this.value,
      this.interestRateMax
    );
  }

  private getMinTerm(
    feeAmount: number,
    currentDebt: number,
    interestRate: number
  ) {
    const up =
      Math.log(feeAmount - currentDebt * interestRate) - Math.log(feeAmount);
    const down = Math.log(1 + interestRate);
    const minTerm = Math.ceil(Math.abs((isNaN(up) ? 0 : up) / down));

    return minTerm < 12 ? 12 : minTerm;
  }

  private calculateQuote() {
    this.quote = 0;

    if (
      this.value !== null &&
      !isNaN(this.value) &&
      this.value >= this.preselect.minLoanAmount &&
      this.value <= this.preselect.maxLoanAmount
    ) {
      if (
        this.term !== undefined &&
        this.term !== null &&
        this.term >= this.preselect.minLoanTerm &&
        this.term <= this.preselect.maxLoanTerm
      ) {
        this.loading = true;
        this.appLoading.title = 'Estamos recalculando tu oferta';
        this.appLoading.subtitle = 'Este proceso puede tardar unos minutos';

        this.creditFlowService
          .getCalculateValues(this.preselect, this.user, this.value, this.term)
          .subscribe(
            (data: Preselect) => this.getCalculateValuesSuccess(data, false),
            (error) => this.getCalculateValuesError(false)
          );
      }
    }
  }

  private getCalculateValuesSuccess(
    newPreselect: Preselect,
    updateMaxValues: boolean
  ) {
    this.loading = false;
    this.appLoading.title = undefined;
    this.appLoading.subtitle = undefined;
    this.preselectService.setPreselect(newPreselect);
    this.quote = newPreselect.feeAmount;

    this.setValidateResponse({
      creditStudy: newPreselect.creditStudy,
      adjustmentInterests: newPreselect.advancedInterests,
      insurancePremiumAdjustment: newPreselect.advancedInsurancePremium,
      discounts: newPreselect.discounts,
      disbursement: newPreselect.disbursement,
    });

    if (updateMaxValues) {
      this.interestRateMax = newPreselect.interestRate;
      this.resetRequiredAmountAndTerm();
    }
  }

  private resetRequiredAmountAndTerm() {
    this.resetRequiredAmount();
    this.resetRequiredTerm();
  }

  private resetRequiredAmount() {
    this.value = undefined;
    this.valValue = false;
  }

  private resetRequiredTerm() {
    this.term = undefined;
    this.terValue = false;
  }

  private getCalculateValuesError(backToOrdinary: boolean) {
    this.loading = false;
    this.appLoading.title = undefined;
    this.appLoading.subtitle = undefined;

    let tittle = 'No hemos podido calcular tu cuota';
    const message = 'Cierra esta ventana e intenta de nuevo';

    if (backToOrdinary) {
      tittle = 'Ocurrió un error asignando tasas';
      this.creditFlowService.setModalityType(ModalityType.ORDINARY);
    } else {
      this.resetRequiredAmountAndTerm();

      this.timTxtValCre = setTimeout(() => {
        this.renderer2
          .selectRootElement(this.txtValueCredit.nativeElement)
          .focus();
        this.renderer2
          .selectRootElement(this.txtValueCredit.nativeElement)
          .select();
      }, 500);
    }

    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'E',
        title: tittle,
        message: message,
        url: '',
        textButtom: 'Volver a Intentar',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  accept() {
    this.googleAnalyticsEventsService.emitEvent(
      'Personalizar Oferta',
      'Personalizo su oferta',
      'Botón Si, Es Mi Crédito',
      Date.now()
    );

    this.loading = true;
    this.preselect.clientType =
      this.user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
        ? CustomerTypeEnum.NEW_KNOWN_CUSTOMER
        : this.user.customerType;
    this.preselect.feeAmount = this.quote;
    this.preselect.feeNumber = +this.txtTermCredit.nativeElement.value;
    this.preselect.requesAmount = this.value;
    this.preselect.totalDebt =
      Math.ceil((this.preselect.obligationBalance + this.value) / 1000) * 1000;

    this.preselectService.setPreselectPersonalized(this.preselect);
    this.saveEventAws();
  }

  private setValidateResponse(validateResponse: any) {
    this.validateResponseService.setResponse({
      discounts: validateResponse.discounts,
      creditStudy: validateResponse.creditStudy,
      disbursement: validateResponse.disbursement,
      creditStudyIVA: validateResponse.creditStudyIVA,
      adjustmentInterests: validateResponse.adjustmentInterests,
      commissionCreditStudy: validateResponse.commissionCreditStudy,
      insurancePremiumAdjustment: validateResponse.insurancePremiumAdjustment,
    });
  }

  saveEventAws() {
    this.eventService
      .pushEvent({
        documentType: this.user.documentType,
        documentNumber: this.user.documentId,
        event: 'SELECT_LOAN_CONDITIONS',
        name: this.user.name,
        eventText:
          'Valor: ' +
          this.txtValueCredit.nativeElement.value +
          ', Plazo: ' +
          this.txtTermCredit.nativeElement.value +
          ', Cuota: ' +
          Math.round(this.quote) +
          ', Sí, es mi crédito',
      })
      .subscribe(
        () => {},
        (error) => this.pushEventError(error)
      );

    this.router.navigate(['master-page/credit-detail']);
  }

  private pushEventError(error: any) {
    console.log(
      'CreditPersonalizacion - pushEventError, Error: ' + JSON.stringify(error)
    );
  }

  enableLoyalty(almostPaidOff?: boolean) {
    this.googleAnalyticsEventsService.emitEvent(
      'Personalizar Oferta',
      'Da click botón cliente con oferta especial',
      'Botón cliente con oferta especial',
      Date.now()
    );
    if (almostPaidOff) {
      this.preselect.almostPaidOff = true;
    }

    if (this.user.loyaltyAttempts > 0 || almostPaidOff) {
      this.creditFlowService.setModalityType(ModalityType.LOYALTY);

      this.loading = true;
      this.appLoading.title = 'Estamos recalculando tu oferta';
      this.appLoading.subtitle = 'Este proceso puede tardar unos minutos';

      this.creditFlowService
        .getCalculateValues(this.preselect, this.user, 0, 0)
        .subscribe(
          (data: Preselect) => this.getCalculateValuesSuccess(data, true),
          () => this.getCalculateValuesError(true)
        );
    }
  }

  isCustomerNewPayrollLoan(): boolean {
    return this.userService.isCustomerNewPayrollLoan();
  }

  clickHere() {
    this.creditFlowService.setCreditFlowType(CreditFlowType.ANYFEE);
    this.router.navigate(['master-page/salary-loans-overview']);
  }

  private clearTimers() {
    clearTimeout(this.timTxtValCre);
    clearTimeout(this.timTxtTerCre);
    clearTimeout(this.timBtnOk);
  }

  showDoCountButton(valValue: any, terValue: any) {
    let doCountEnabled = false;
    if (valValue && terValue && !this.userService.isCustomerNewPayrollLoan()) {
      doCountEnabled = true;
    }
    return doCountEnabled;
  }

  showLoyaltyButton(valValue: any, terValue: any) {
    let loyaltyEnabled = false;
    if (
      valValue &&
      terValue &&
      this.showSpecialOfferButton() &&
      (this.creditFlowService.getCreditFlowType() === CreditFlowType.ANYFEE ||
        this.creditFlowService.getCreditFlowType() ===
          CreditFlowType.SAMEFEE) &&
      this.loyaltyAnyFeeToggle.featureEnabled &&
      this.creditFlowService.getModalityType() === ModalityType.ORDINARY &&
      !this.userService.isCustomerNewPayrollLoan()
    ) {
      loyaltyEnabled = true;
    }
    return loyaltyEnabled;
  }

  showCalculator() {
    this.googleAnalyticsEventsService.emitEvent(
      'Personalizar Oferta',
      'Da click botón Hagamos cuentas',
      'Botón Hagamos cuentas',
      Date.now()
    );
    this.commonService
      .showMesssageBox(
        CalculatorComponent,
        true,
        {
          amount:
            Math.ceil((this.preselect.obligationBalance + this.value) / 1000) *
            1000,
          feeAmount: this.quote,
          term: this.txtTermCredit.nativeElement.value,
          interestRate: this.preselect.interestRate,
          agreedAmount: this.value,
        },
        600
      )
      .afterClosed()
      .subscribe((response: any) => this.actionFromCalculator(response));
  }

  actionFromCalculator(response: any) {
    switch (response.flowType) {
      case 'certificate':
        this.showPopUpGenerateCertificate();
        break;
      case 'dismiss':
        this.logoutUser();
        break;
      case 'loyalty':
        this.enableLoyalty(response.loyalty);
        break;

      default:
        break;
    }
  }

  logoutUser() {
    this.commonService.showMesssageBox(
      CustomerFeedbackComponent,
      true,
      {
        clear: true,
        stepId: 3,
        stepDescription: 'Menu',
      },
      600
    );
  }

  showMessageNeedHigherAmount(): boolean {
    return (
      !this.isCustomerNewPayrollLoan() &&
      !this.valValue &&
      !this.valErrorMax &&
      !this.valErrorMin
    );
  }

  showMessageMaxFeeNumberRule() {
    if (this.preselect.maxLoanTerm < this.preselect.maxFeeNumberRule) {
      this.loading = false;
      this.appLoading.title = undefined;
      this.appLoading.subtitle = undefined;

      this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'I',
          title: 'Valide el plazo máximo',
          message:
            'El plazo máximo para realizar la libranza es ' +
            this.preselect.maxLoanTerm +
            ' meses',
          url: '',
          textButtom: 'Aceptar',
          clearUser: false,
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
        },
        500
      );
    }
  }

  showSpecialOfferButton(): boolean {
    return (
      this.preselect.almostPaidOff ||
      (this.preselect.customerInBureauTrail &&
        !this.useFootprintsNewServiceToggle)
    );
  }

  private showPopUpGenerateCertificate() {
    this.commonService
      .showMesssageBox(
        PopUpCertificateComponent,
        true,
        {
          documentNumber: this.user.documentId,
          documentType: this.user.documentType,
          emailCustomer: this.user.email,
          hiddenButtonClose: true,
          obligationNumber: this.user.obligationId,
          textButtom: 'Continuar',
          title: '¿Necesitas certificación para pago de deuda?',
          type: 'I',
        },
        400
      )
      .afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.logoutUser();
        }
      });
  }
}
