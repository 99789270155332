import {
  HttpInterceptor,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorService } from '@avaldigitallabs/bpop-security-frontend-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommonService } from '../../shared/services/local/common.service';
import { PopUpPepComponent } from '../../shared/components/pop-up/pop-up-pep.component';
import { UserService } from '../../shared/services/local/user.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private commonService: CommonService,
    private interceptorService: InterceptorService,
    private userService: UserService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldEncryptUrl(req)) {
      return this.interceptorService.handlerInterceptor(
        this.buildAdditionalParams(req),
        next
      );
    } else {
      return next.handle(this.buildAdditionalParams(req)).pipe(
        tap(
          (event) => {
            if (event instanceof HttpResponse) {
              if (
                event.status === 206 &&
                !event.url.includes('security-manager/auth/certihuella')
              ) {
                this.messageBox(
                  'Se presento un problema técnico, por favor vuelve a ingresar'
                );
              }
            }
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401 || error.status === 403) {
                this.messageBox(
                  'La sesión ha expirado, por favor vuelve a ingresar'
                );
              }
            }
          }
        )
      );
    }
  }

  private messageBox(message: string) {
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'T',
        title: 'Lo sentimos',
        message: message,
        url: '',
        textButtom: 'Volver a Intentar',
        clearUser: false,
        feedback: true,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );
  }

  private buildAdditionalParams(req: HttpRequest<any>): any {
    if (!req.headers.get('X-Amz-Security-Token')) {
      let accessToken = '';
      const userInfo = this.userService.getUser();

      if (userInfo && userInfo.accessToken) {
        accessToken = userInfo.accessToken;
      }

      if (req.url.includes('/oauth2/token')) {
        return req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        });
      }

      if (req.url.substring(0, 35) === 'https://s3.us-east-2.amazonaws.com/') {
        return req;
      } else {
        const cognitoToken = req.headers.get('cognitoToken');
        const authorization = cognitoToken || accessToken;

        return req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: authorization,
          }),
        });
      }
    } else {
      return req;
    }
  }

  private shouldEncryptUrl(req: HttpRequest<any>): boolean {
    return (
      environment.encryptUrls.filter(
        (encryptUrl: any) => encryptUrl === req.url
      ).length !== 0
    );
  }
}
