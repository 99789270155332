export enum EventCodeEnum {
  ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS',
  ACCOUNT_SELECTED = 'ACCOUNT_SELECTED',
  BOARD_MEMBER_AFFIRMATIVE_DECLARATION = 'BOARD_MEMBER_AFFIRMATIVE_DECLARATION',
  BOARD_MEMBER_DECLARATION = 'BOARD_MEMBER_DECLARATION',
  BOARD_MEMBER_NEGATIVE_DECLARATION = 'BOARD_MEMBER_NEGATIVE_DECLARATION',
  CERTIFICATE_REQUEST = 'CERTIFICATE_REQUEST',
  CLOSE_SESSION_BY_USER_STEP_ID = 'CLOSE_SESSION_BY_USER_STEP_ID',
  CLOSE_SESSION_DUE_TO_INACTIVITY_STEP_ID = 'CLOSE_SESSION_DUE_TO_INACTIVITY_STEP_ID',
  CUSTOMER_DATA_PROCESSING_AUTORIZATION_STEP_ID = 'CUSTOMER_DATA_PROCESSING_AUTORIZATION_STEP_ID',
  CUSTOMER_REJECTED_DATA_PROCESSING_AUTORIZATION_STEP_ID = 'CUSTOMER_REJECTED_DATA_PROCESSING_AUTORIZATION_STEP_ID',
  DECRIM_VALIDATION = 'DECRIM_VALIDATION',
  INTERNATIONAL_OPERATIONS_AFFIRMATIVE_DECLARATION = 'INTERNATIONAL_OPERATIONS_AFFIRMATIVE_DECLARATION',
  INTERNATIONAL_OPERATIONS_DECLARATION = 'INTERNATIONAL_OPERATIONS_DECLARATION',
  INTERNATIONAL_OPERATIONS_NEGATIVE_DECLARATION = 'INTERNATIONAL_OPERATIONS_NEGATIVE_DECLARATION',
  PAYROLLCHECK_DIGITALIZATION_NOT_COMPLETED = 'PAYROLLCHECK_DIGITALIZATION_NOT_COMPLETED',
  PEP_AFFIRMATIVE_DECLARATION = 'PEP_AFFIRMATIVE_DECLARATION',
  PEP_DECLARATION = 'PEP_DECLARATION',
  PEP_NEGATIVE_DECLARATION = 'PEP_NEGATIVE_DECLARATION',
  SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID = 'SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID',
  SEND_MAIL_WITH_CERTIFICATE_REQUEST = 'SEND_MAIL_WITH_CERTIFICATE_REQUEST',
  US_GAP_AFFIRMATIVE_DECLARATION = 'US_GAP_AFFIRMATIVE_DECLARATION',
  US_GAP_DECLARATION = 'US_GAP_DECLARATION',
  US_GAP_NEGATIVE_DECLARATION = 'US_GAP_NEGATIVE_DECLARATION',
}
