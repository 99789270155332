// Imports
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

// Declarations
import { CommonService } from './shared/services/local/common.service';
import { PopUpPepComponent } from './shared/components/pop-up/pop-up-pep.component';
import { SecurityService } from './shared/services/local/security.service';

@Injectable()
export class DocumentTypeAccessGuard implements CanActivate {
  constructor(
    private commonService: CommonService,
    private securityService: SecurityService) { }

  public canActivate() {
    const validation = this.securityService.validateSecurityAuthentication();

    if (!validation) {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        false,
        {
          icon: "E",
          title: "Sin permisos",
          message: "Debes autenticarte para poder ingresar a la aplicación",
          url: "/welcome",
          clearUser: false,
          feedback: false,
          textButtom: "Ir Autenticarte",
        },
        500
      );
    }

    return validation;
  }
}
