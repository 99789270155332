// Imports
import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterContentInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

// Interfaces
import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { RequestFile } from '../../../shared/interfaces/request-file.interface';
import { User } from '../../../shared/interfaces/user.interface';

// Enum
import { AdvisorType } from '../../../shared/enums/advisor-type.enum';
import { OtpInputsEnum } from '../../../shared/enums/otp-inputs.enum';

// Declarations
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

import { CommonService } from '../../../shared/services/local/common.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { RequestFileService } from '../../../shared/services/local/request-file.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';

import { CustomerService } from '../../../shared/services/remote/customer.service';
import { ApiService } from '../../../shared/services/remote/api.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

@Component({
  selector: 'app-confirm-otp',
  templateUrl: './confirm-otp.component.html',
  styleUrls: ['./confirm-otp.component.css'],
})
export class ConfirmOtpComponent implements AfterContentInit, OnInit {
  @ViewChild('focPassword1', { static: false }) focus1: ElementRef;
  @ViewChild('focPassword2', { static: false }) focus2: ElementRef;
  @ViewChild('focPassword3', { static: false }) focus3: ElementRef;
  @ViewChild('focPassword4', { static: false }) focus4: ElementRef;
  @ViewChild('focPassword5', { static: false }) focus5: ElementRef;
  @ViewChild('focPassword6', { static: false }) focus6: ElementRef;
  @ViewChild('focPassword7', { static: false }) focus7: ElementRef;
  @ViewChild('focPassword8', { static: false }) focus8: ElementRef;
  @ViewChild('inputPassword1', { static: false }) input1: ElementRef;
  @ViewChild('inputPassword2', { static: false }) input2: ElementRef;
  @ViewChild('inputPassword3', { static: false }) input3: ElementRef;
  @ViewChild('inputPassword4', { static: false }) input4: ElementRef;
  @ViewChild('inputPassword5', { static: false }) input5: ElementRef;
  @ViewChild('inputPassword6', { static: false }) input6: ElementRef;
  @ViewChild('inputPassword7', { static: false }) input7: ElementRef;
  @ViewChild('inputPassword8', { static: false }) input8: ElementRef;

  private dialogRef: any;

  adlTeamClientValidation: boolean;
  inputsCount: number;
  user: User;
  title: string;
  content: string;
  otpAth: boolean;
  loading: boolean;
  callOtp: boolean;
  retryMessage: any;
  hiddenMessage: any;
  preselect: Preselect;
  validityTimeMessage: string;
  requestFile: RequestFile;
  retryAttemps: number;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private renderer2: Renderer2,
    private requestFileService: RequestFileService,
    private route: ActivatedRoute,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.adlTeamClientValidation = this.toggleService.getToggle(
      'ADL_TEAM_CLIENT_VALIDATION'
    ).featureEnabled;

    this.preselect = this.preselectService.getPreselectPersonalized();
    this.requestFile = this.requestFileService.getRequestFile();
    this.retryAttemps = 0;
  }

  ngAfterContentInit() {
    this.getOtpByCallSuccess();
  }

  ngOnInit() {
    this.setAdlTeamClientValidation();
    this.setParams();
    this.setMessages();
    this.stepperService.setStep('otp');
    this.retryMessage = this.toggleService.getToggle('TWILIO_SMS_AND_CALL');
    this.retryMessage.featureEnabled = this.otpAth
      ? this.retryMessage.featureEnabled
      : false;
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Ingreso página ingreso código Otp ' + this.otpAth ? 'Ath' : 'WhatsApp',
      'Página otp',
      Date.now()
    );
  }

  setParams() {
    this.route.paramMap.subscribe((params: any) => {
      this.otpAth = params.params['type'] === 'ATH';
    });
  }

  setMessages() {
    this.user = this.userService.getUser();
    this.title = '¡Listo ' + this.user.nameShort + '!';
    this.validityTimeMessage = 'El código tiene 1 minuto de validez';
    this.content =
      'Solo hace falta que confirmes que deseas este crédito. Para esto, hemos enviado un código a tu celular ' +
      this.user.cellphone;
  }

  keyPress(event: any) {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      return false;
    }
  }

  keyUp(event: any, key: number) {
    if (key === 2) {
      if (
        this.renderer2.selectRootElement(this.input1.nativeElement).value ===
          '' ||
        isNaN(
          Number(
            this.renderer2.selectRootElement(this.input1.nativeElement).value
          )
        )
      ) {
        this.renderer2.selectRootElement(this.input1.nativeElement).value = '';
      } else {
        this.input1.nativeElement.disabled = true;
        this.input2.nativeElement.disabled = false;
        this.renderer2.selectRootElement(this.input2.nativeElement).focus();
      }
    } else if (key > 2) {
      if (event.keyCode === 8) {
        this['input' + (key - 2)].nativeElement.disabled = false;
        this['input' + (key - 1)].nativeElement.disabled = true;
        this.renderer2
          .selectRootElement(this['input' + (key - 2)].nativeElement)
          .focus();
        this.renderer2
          .selectRootElement(this['input' + (key - 2)].nativeElement)
          .select();
      } else if (
        this.renderer2.selectRootElement(
          this['input' + (key - 1)].nativeElement
        ).value === '' ||
        isNaN(
          Number(
            this.renderer2.selectRootElement(
              this['input' + (key - 1)].nativeElement
            ).value
          )
        )
      ) {
        this.renderer2.selectRootElement(
          this['input' + (key - 1)].nativeElement
        ).value = '';
      } else {
        this['input' + (key - 1)].nativeElement.disabled = true;
        this['input' + key].nativeElement.disabled = false;
        this.renderer2
          .selectRootElement(this['input' + key].nativeElement)
          .focus();
      }
    }
  }

  focusBlurText(key: number, display: boolean) {
    this.renderer2.removeClass(
      this['focus' + key].nativeElement,
      display ? 'd-none' : 'd-block'
    );
    this.renderer2.addClass(
      this['focus' + key].nativeElement,
      display ? 'd-block' : 'd-none'
    );
  }

  sendOtp(event: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Ingresar Código Otp ' + this.otpAth ? 'Ath' : 'WhatsApp',
      'Último dígito código',
      Date.now()
    );

    if (event.keyCode === 8) {
      this.adlTeamClientValidation
        ? this.setBeforeAndFinalInput(this.input7, this.input8)
        : this.setBeforeAndFinalInput(this.input5, this.input6);
    } else if (
      this.renderer2.selectRootElement(this.input6.nativeElement).value ===
        '' ||
      isNaN(
        Number(
          this.renderer2.selectRootElement(this.input6.nativeElement).value
        )
      )
    ) {
      this.renderer2.selectRootElement(this.input6.nativeElement).value = '';
    } else {
      this.adlTeamClientValidation
        ? (this.input8.nativeElement.disabled = true)
        : (this.input6.nativeElement.disabled = true);

      this.loading = true;

      let otpValue = '';
      for (let i = 1; i <= this.inputsCount; i++) {
        otpValue += this['input' + i].nativeElement.value;
      }

      if (this.otpAth) {
        this.sendFiles(otpValue);
      }
    }
  }

  sendNewOtpCode() {
    this.loading = true;
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Generar Nuevo Código',
      'Botón enviar un código nuevo',
      Date.now()
    );

    this.generateConfirmationCode('sms');
  }

  callToSendOtp() {
    this.callOtp = true;
    this.loading = true;
    this.retryMessage.featureEnabled = false;

    this.hiddenMessage = setTimeout(() => {
      this.callOtp = false;
      this.retryMessage.featureEnabled = true;
      clearTimeout(this.hiddenMessage);
    }, 30000);

    this.generateConfirmationCode('call');
  }

  private generateConfirmationCode(method: string) {
    const obligationId = this.user.obligationId;
    const request = {
      documentType: this.user.documentType,
      documentNumber: this.user.documentId,
      confirmationCodeType: method,
    };

    if (this.adlTeamClientValidation) {
      this.customerService
        .validateCustomerCellPhone(this.userService.getUserInfo())
        .subscribe(
          (result) => {
            this.user.simPendingAuthentication =
              result['status'] === 'AUTHENTICATION_SIM_PENDING';

            this.getOtpByCallSuccess();
          },
          () => this.getCodeOtpError()
        );
    }

    this.apiService
      .generateRenewalConfirmationCode(obligationId, request)
      .subscribe(
        () => this.getOtpByCallSuccess(),
        () => this.getCodeOtpError()
      );
  }

  private getCodeOtpSuccess() {
    this.loading = false;
    this.validityTimeMessage = 'El código tiene 1 minuto de validez';
    this.focusInit();
  }

  private getOtpByCallSuccess() {
    this.loading = false;

    setTimeout(() => {
      this.focusInit();
    }, 800);
  }

  private getCodeOtpError() {
    this.loading = false;

    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'E',
        title: 'Error al generar código de confirmación',
        message:
          'No hemos podido generar tu código para confirmar el crédito, por favor reintenta en unos minutos',
        url: '',
        textButtom: 'Volver a Intentar',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );

    this.focusInit();
  }

  private focusInit() {
    this.input1.nativeElement.disabled = false;
    this.renderer2.selectRootElement(this.input1.nativeElement).focus();

    for (let i = 1; i <= this.inputsCount; i++) {
      this['input' + i].nativeElement.value = '';
    }
  }

  private sendFiles(confirmationCode: string) {
    this.apiService
      .validateRenewalConfirmationCode({
        identificationNumber: this.requestFile.identificationNumber,
        identificationType: this.requestFile.identificationType,
        otpCode: confirmationCode,
        idRequest: this.requestFile.generatedId,
      })
      .subscribe(
        () => this.otpConfirmationSuccess(),
        (error) => this.sendRequestFileError(error)
      );
  }

  private otpConfirmationSuccess() {
    if (
      this.user.simPendingAuthentication &&
      this.user.advisorType === AdvisorType.OFFICE_SALES
    ) {
      this.loading = false;
      this.requestFile.renewalConfirmationCode = this.user.idCaseRnec;
    }

    this.sendRequestFile();
  }

  private sendRequestFile() {
    this.requestFile.idCase = this.user.idCaseDecrim;
    this.apiService
      .renewSalaryloan(
        this.user.obligationId,
        this.requestFileService.getRequestFile()
      )
      .subscribe(
        () => this.sendRequestFileSuccess(),
        (error) => this.sendRequestFileError(error)
      );
  }

  private sendRequestFileSuccess() {
    this.router.navigate(['/master-page/success-credit']);
  }

  private sendRequestFileError(error: any) {
    this.retryAttemps++;
    const errorCode =
      this.retryAttemps === 3
        ? 'AttempsRetry'
        : error.error && error.error.code
        ? error.error.code
        : '';
    this.loading = false;
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      this.commonService.getMessageFromErrorCodePayerOpen(
        errorCode,
        this.preselect.platformAuthorizationUrl
      ),
      500
    );

    this.focusInit();
  }

  private setAdlTeamClientValidation() {
    this.inputsCount = this.adlTeamClientValidation
      ? OtpInputsEnum.ADL_TEAM_CLIENT_VALIDATION
      : OtpInputsEnum.TWILIO;
  }

  private setBeforeAndFinalInput(
    beforeInput: ElementRef,
    finalInput: ElementRef
  ) {
    beforeInput.nativeElement.disabled = false;
    finalInput.nativeElement.disabled = true;
    this.renderer2.selectRootElement(beforeInput.nativeElement).focus();
    this.renderer2.selectRootElement(beforeInput.nativeElement).select();
  }
}
