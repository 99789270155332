import { Observable } from 'rxjs';

export class CastFileToBase64 {
    public CastFileToBase64() {}

    public castFile(file: File): Observable<any> {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        return Observable.create(observer => {
            fileReader.onload = () => observer.next(fileReader.result);
            fileReader.onerror = error => observer.error(error);
        });
    }
}
