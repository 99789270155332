// Imports
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationCard } from '../../interfaces/authentication-card.interface';
import { AuthenticationOtp } from '../../interfaces/authentication-otp.interface';
import { IResponseValidateCertihuella } from '../../interfaces/response-validate-certihuella.interface';
import { IRequestValidateCertihuella } from '../../interfaces/request-validate-certihuella.interface';
import { CreateDecrim } from '../../interfaces/create-decrim';
import { User } from '../../interfaces/user.interface';
import { AwsSignatureInputData } from '../../interfaces/aws-input.interface';

import { AwsSignatureService } from '../local/aws-signature.service';
import { CognitoService } from '../local/cognito.service';
import { UserService } from '../local/user.service';
import { Logger } from '../local/logger.service';

const log = new Logger('Authentication Service');

@Injectable()
export class AuthenticationService {
  awsInputData: AwsSignatureInputData = new AwsSignatureInputData();

  constructor(
    private AwsSignature: AwsSignatureService,
    private cognitoService: CognitoService,
    private http: HttpClient,
    private userService: UserService
  ) {
    this.awsInputData.requestParameters = '';
    this.awsInputData.contentType = 'application/json';
    this.awsInputData.canonicalQuerystring = '';
  }

  setHeaders(
    method: string,
    canonicalUri: string,
    contentType: string,
    requestParameters?: any
  ) {
    const userInfo = this.userService.getUser();

    this.awsInputData.method = method;
    this.awsInputData.canonicalUri = canonicalUri;
    this.awsInputData.host = environment.awsCognitoData.hostBackendSecurity;
    this.awsInputData.region = environment.awsCognitoData.region;
    this.awsInputData.service = environment.awsCognitoData.service;
    this.awsInputData.accessKey = userInfo.accessKey;
    this.awsInputData.secretKey = userInfo.secretKey;
    this.awsInputData.contentType = contentType;
    this.awsInputData.requestParameters = requestParameters
      ? requestParameters
      : '';

    const signature = this.AwsSignature.generateSignature(this.awsInputData);

    return new HttpHeaders({
      'Content-Type': contentType,
      Authorization: signature['Authorization'],
      'X-Amz-Security-Token': userInfo.sessionToken,
      'X-Amz-Date': signature['X-Amz-Date'],
    });
  }

  getCodeOtp(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'auth/otp/' +
        user.documentType +
        '/' +
        user.documentId
    );
  }

  getOtpCodeByCall(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'auth/otpCall/' +
        user.documentType +
        '/' +
        user.documentId
    );
  }

  otpAuthentication(
    user: User,
    authenticationOtp: AuthenticationOtp,
    eventHandler: any
  ) {
    this.http
      .post(environment.serverUrl + 'auth/otp', authenticationOtp)
      .subscribe(
        (data) => this.saveToken(user, data, eventHandler),
        (error) => {
          console.log(
            `AuthenticationService - otpAuthentication - Error`,
            JSON.stringify(error)
          );
          eventHandler.error(error);
        }
      );
  }

  debitCardAuthentication(
    user: User,
    authenticationCard: AuthenticationCard,
    eventHandler: any
  ) {
    this.http
      .post(environment.serverUrl + 'auth/debitCard', authenticationCard)
      .subscribe(
        (data) => this.saveToken(user, data, eventHandler),
        (error) => eventHandler.error(error)
      );
  }

  getWhatsAppOtp(user: User): Observable<object> {
    return this.http.get(
      environment.serverUrl +
        'auth/whatsapp/otp/' +
        user.documentType +
        '/' +
        user.documentId
    );
  }

  getWhatsAppConfiguration(): Observable<object> {
    return this.http.get(
      environment.serverUrl + 'auth/whatsapp/otp/configuration'
    );
  }

  whatsAppAuthentication(
    user: User,
    authenticationOtp: AuthenticationOtp,
    eventHandler: any
  ) {
    this.http
      .post(environment.serverUrl + 'auth/whatsapp/otp', authenticationOtp)
      .subscribe(
        (data) => this.saveToken(user, data, eventHandler),
        (error) => eventHandler.error(error)
      );
  }

  private saveToken(user: User, data: any, eventHandler: any) {
    user.accessToken = data.token;
    user.generatedId = data.generatedId;
    this.userService.setUser(user);

    eventHandler.success();
  }

  createCaseDecrim(createDecrim: CreateDecrim): Observable<object> {
    const headers = this.setHeaders(
      'POST',
      '/security-manager/auth/decrim/',
      'application/json',
      JSON.stringify(createDecrim)
    );

    return this.http.post(
      environment.serverUrlBackendSecurity + 'auth/decrim/',
      JSON.stringify(createDecrim),
      { headers }
    );
  }

  validateCaseDecrim(caseDecrim: any): Observable<object> {
    const headers = this.setHeaders(
      'PUT',
      '/security-manager/auth/decrim/',
      'application/json',
      JSON.stringify(caseDecrim)
    );

    return this.http.put(
      environment.serverUrlBackendSecurity + 'auth/decrim/',
      JSON.stringify(caseDecrim),
      { headers }
    );
  }

  validateCaseCertihuella(
    data: IRequestValidateCertihuella
  ): Observable<IResponseValidateCertihuella> {
    const headers = new HttpHeaders({
      cognitoToken:
        this.cognitoService.getTokenLegacy() != null
          ? this.cognitoService.getTokenLegacy().access_token
          : '',
    });
    return this.http.post(
      environment.serverUrlBackendSecurity + 'auth/certihuella',
      data,
      { headers }
    );
  }

  setLogout(): Observable<object> {
    return this.http.delete(environment.serverUrl + 'auth/user/session');
  }
}
