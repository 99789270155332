import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAutocompleteComponent),
      multi: true,
    },
  ],

  selector: 'app-text-autocomplete',
  templateUrl: './text-autocomplete.component.html',
  styleUrls: ['./text-autocomplete.component.css'],
})
export class TextAutocompleteComponent
  implements AfterViewInit, ControlValueAccessor, OnInit
{
  @ViewChild('cboAutoComplete', { static: false }) cboAutoComplete: any;
  @ViewChild('prbProgressBar', { static: false }) prbProgressBar: any;
  @Input() data: any;
  @Input() bindLabel: any;
  @Input() bindValue: any;
  @Input() searchFn: any;
  @Output() eventChange: EventEmitter<null> = new EventEmitter();
  @Output() eventSearchFn: EventEmitter<null> = new EventEmitter();

  private _dataId: any;
  @Input()
  set dataId(dataId: any) {
    this._dataId = dataId;
    this.propagateChange(this._dataId);
  }
  get dataId(): any {
    return this._dataId;
  }

  propagateChange = (_: any) => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cboAutoComplete.bindLabel = this.bindLabel;
    this.cboAutoComplete.bindValue = this.bindValue;
  }

  ngOnInit() {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  textFocus() {
    this.prbProgressBar.classProgressBar = 'progressBarFocus';
  }

  textBlur() {
    if (this.dataId === undefined || this.dataId === null) {
      this.prbProgressBar.classProgressBar = 'progressBar';
    }
  }

  textChange(param?: any) {
    this.eventChange.emit();
  }

  textSearchFn(param?: any, param2?: any) {
    this.eventSearchFn.emit();
  }

  writeValue(value: any) {
    this.dataId = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  filterFn(text: string, item: any) {
    const properties = Object.getOwnPropertyNames(item);
    text = text.toLocaleLowerCase();

    return (
      item[properties[0]].toString().toLocaleLowerCase().indexOf(text) > -1 ||
      item[properties[1]].toString().toLocaleLowerCase().indexOf(text) > -1
    );
  }

  focus() {
    const cbo = this.cboAutoComplete.nativeElement;
    cbo.focus();
  }
}
