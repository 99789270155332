import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {InternalViabilityRequestInterface} from '../../interfaces/internal-viability-request.interface';

import {UserService} from '../local/user.service';
import {AwsSignatureInputData} from '../../interfaces/aws-input.interface';
import {AwsSignatureService} from '../local/aws-signature.service';

@Injectable()
export class RiskManagerService {

  awsInputData: AwsSignatureInputData = new AwsSignatureInputData();

  constructor(private http: HttpClient,
              private userService: UserService,
              private AwsSignature: AwsSignatureService) { }

  validateCustomerInternalViability(internalViabilityRequest: InternalViabilityRequestInterface): Observable<any> {
    const headers = this.setHeaders('POST', '/risk-management/internal-viabilities', 'application/json', JSON.stringify(internalViabilityRequest));
    return this.http.post(environment.serverUrlRiskManager + 'internal-viabilities', internalViabilityRequest, {headers});
  }

  setHeaders(method: string, canonicalUri: string, contentType: string, requestParameters?: any) {
    const userInfo = this.userService.getUser();

    this.awsInputData.method = method;
    this.awsInputData.canonicalUri = canonicalUri;
    this.awsInputData.host = environment.awsCognitoData.hostRiskManager;
    this.awsInputData.canonicalQuerystring = '';
    this.awsInputData.region = environment.awsCognitoData.region;
    this.awsInputData.service = environment.awsCognitoData.service;
    this.awsInputData.accessKey = userInfo.accessKey;
    this.awsInputData.secretKey = userInfo.secretKey;
    this.awsInputData.contentType = contentType;
    this.awsInputData.requestParameters = requestParameters ? requestParameters : '';

    const signature = this.AwsSignature.generateSignature(this.awsInputData);

    return new HttpHeaders({
      'Content-Type': contentType,
      'Authorization': signature['Authorization'],
      'X-Amz-Security-Token': userInfo.sessionToken,
      'X-Amz-Date': signature['X-Amz-Date']
    });
  }
}
