import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BurosService } from '../remote/buros.service';
import { CreditFlowFactory } from './factory/credit-flow-factory';
import { CreditFlowType } from '../../enums/credit-flow-type.enum';
import { CustomerService } from '../remote/customer.service';
import { Preselect } from '../../interfaces/preselect.interface';
import { PreselectService } from './preselect.service';
import { User } from '../../interfaces/user.interface';
import { ModalityType } from '../../enums/modality-type.enum';

@Injectable()
export class CreditFlowService {
  private creditFlowType: CreditFlowType;
  private modalityType: ModalityType;

  constructor(
    private burosService: BurosService,
    private customerService: CustomerService,
    private preselectService: PreselectService
  ) {}

  getCreditFlowType() {
    return this.creditFlowType;
  }

  setCreditFlowType(_creditFlowType: CreditFlowType) {
    this.creditFlowType = _creditFlowType;
  }

  getModalityType() {
    return this.modalityType;
  }

  setModalityType(_modalityType: ModalityType) {
    this.modalityType = _modalityType;
  }

  getPreselect(_preselect: Preselect, _user: User): Observable<object> {
    return CreditFlowFactory.getPetition(
      this.creditFlowType,
      this.burosService,
      this.customerService,
      this.preselectService
    ).getPreselect(
      _preselect,
      _user,
      ModalityType[this.modalityType],
      this.creditFlowType === CreditFlowType.ANYFEE ? 'ANY_FEE' : 'SAME_FEE'
    );
  }

  getCalculateValues(
    _preselect: Preselect,
    _user: User,
    _value: number,
    _term: number
  ): Observable<object> {
    return CreditFlowFactory.getPetition(
      this.creditFlowType,
      this.burosService,
      this.customerService,
      this.preselectService
    ).getCalculateValues(
      _preselect,
      _user,
      _value,
      _term,
      ModalityType[this.modalityType],
      this.creditFlowType === CreditFlowType.ANYFEE ? 'ANY_FEE' : 'SAME_FEE'
    );
  }
}
