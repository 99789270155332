import { User } from '../interfaces/user.interface';

import { CustomerDecisionEnum } from './customer-decision-type.enum';
import { DecisionEnum } from './decision-type.enum';

export enum CreditFlowType {
  ANYFEE = 'ANYFEE',
  SAMEFEE = 'SAMEFEE',
  CELLPHONE = 'CELLPHONE',
  NEW_PAYROLL_LOAN = 'NEW_PAYROLL_LOAN',
}

export class CreditFlowTypeValue {
  static getCreditFlowType(user: User): number {
    let documentType: number;

    if (user.creditFlowType === CreditFlowType.ANYFEE) {
      documentType = 0;
    }

    if (user.creditFlowType === CreditFlowType.SAMEFEE) {
      documentType = 1;
    }

    if (user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL) {
      documentType = 2;
    }

    if (user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN) {
      documentType = 3;
    }

    return documentType;
  }
}
