import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
})
export class CardsComponent {
  @Input() groupCards: any;
  @Input() hasFooter = false;
  @Input() hasRadioFooter = false;
  @Input() subTitle = '';
  @Input() textFooter = '';

  @Output() selectedEmit = new EventEmitter();
  Array = Array;

  constructor() {}

  setSelect(id: any) {
    this.selectedEmit.emit(id);
  }

  getGroupCards() {
    return this.getGroupCards;
  }

  changeBoldElementText(description: string) {
    return description.split('*');
  }
}
