import { Component, OnInit, ViewChild } from '@angular/core';

import { CommonService } from '../../../shared/services/local/common.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { User } from '../../../shared/interfaces/user.interface';
import { UserService } from '../../../shared/services/local/user.service';

@Component({
  selector: 'app-salary-loans-overview',
  templateUrl: './salary-loans-overview.component.html',
  styleUrls: ['./salary-loans-overview.component.css'],
})
export class SalaryLoansOverviewComponent implements OnInit {
  @ViewChild('graph', { static: false }) objGraph: any;
  @ViewChild('validation', { static: false }) objValidation: any;

  user: User;
  visibleLoading = true;

  constructor(
    private commonService: CommonService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private stepperService: StepperService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
  }

  ngOnInit() {
    this.stepperService.setStep('SalaryLoansOverview');
    this.googleAnalyticsEventsService.emitEvent(
      'Historial Libranzas',
      'Ingreso página libranzas por pagaduría',
      'Página salary-loans-overview',
      Date.now()
    );
  }

  activeTab(event: any) {
    this.objValidation.stateTab(event.obligationId, event.classCss);
  }

  activeLoading(event: any) {
    this.visibleLoading = event.loading;
  }

  retryRefreshInformation(event: any) {
    const dialog = this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      {
        icon: 'E',
        title: '',
        message:
          'Algo inesperado acaba de ocurrir, por favor oprime el botón de reintentar para volver a cargar la información de tus libranzas',
        url: '',
        textButtom: 'Reintentar',
        clearUser: false,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
      },
      500
    );

    dialog.afterClosed().subscribe(() => {
      switch (event.type) {
        case 'G':
          this.objGraph.searchLoansCustomer();

          break;
        case 'V':
          this.objValidation.searchLoansValidate();

          break;
      }
    });
  }
}
