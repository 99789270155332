import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { User } from '../../interfaces/user.interface';

import { ToggleService } from "../../../shared/services/local/toggle.service";

import { SecurityRemoteService } from '../remote/security-remote.service';
import { UserService } from './user.service';

@Injectable()
export class SecurityService {

  constructor(
    private securityRemote: SecurityRemoteService,
    private userService: UserService,
    private toggleService: ToggleService, ) { }

  validateSecurityAuthentication(): boolean {
    const currentUser: User = this.userService.getUser();
    return currentUser.accessKey !== undefined && currentUser.federatedAuthToken !== undefined;
  }

  closeFederateAuthentication(clientId: string) {
    this.securityRemote.closeFederateAuthentication(clientId);
  }

  federatedAuthEnabled(): boolean {
    const federatedAuthToggle = this.toggleService.getToggle("FEDERATED_AUTHENTICATION");

    return (
      environment.federatedAuthEnabled && federatedAuthToggle.featureEnabled
    );
  }
}
