import { Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { PlatformOfferRequestInterface } from '../../interfaces/platform-offer-request.interface';
import { User } from '../../interfaces/user.interface';

import { ModalityType } from '../../enums/modality-type.enum';

import { CreditFlowService } from '../../services/local/credit-flow.service';
import { CustomerService } from '../../services/remote/customer.service';
import { ToggleService } from '../../services/local/toggle.service';
import { UserService } from '../../services/local/user.service';

@Component({
  selector: 'app-platform-bindings',
  templateUrl: './platform-bindings.component.html',
  styleUrls: ['./platform-bindings.component.css']
})
export class PlatformBindingsComponent implements OnInit {

  @Output() loadingEmit = new EventEmitter();
  bindings = [];
  platformOfferRequest: PlatformOfferRequestInterface;
  useFootprintsNewServiceToggle: boolean;
  user: User;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<PlatformBindingsComponent>,
      private creditFlowService: CreditFlowService,
      private customerService: CustomerService,
      private toggleService: ToggleService,
      private userService: UserService,
  ) {
    this.bindings = data.bindings;
    this.platformOfferRequest = data.platformOfferRequest;
    this.user = this.userService.getUser();
  }

  ngOnInit() {
    this.setUseFootprintsNewServiceToggle();
  }

  public selectBinding(bindingNumber: string) {
    this.loadingEmit.emit({ loading: true });
    this.platformOfferRequest.bindingNumber = bindingNumber;
    this.customerService.generatePlatformOffer(this.platformOfferRequest).subscribe(
      (response) => this.generatePlatformOfferSucces(response),
      (error) => this.generatePlatformOfferError(error)
    );
  }

  private generatePlatformOfferSucces(response: any) {
    this.user.transactionId = response.id;
    this.userService.setUser(this.user);
    if (
      response.modalityType === ModalityType.LOYALTY &&
      this.useFootprintsNewServiceToggle
    ) {
      this.creditFlowService.setModalityType(ModalityType.LOYALTY);
    }

    this.dialogRef.close({success: true, error: {}});
  }

  private generatePlatformOfferError(error: any) {
    this.dialogRef.close({success: false, error: error});
  }

  private setUseFootprintsNewServiceToggle() {
    this.useFootprintsNewServiceToggle = this.toggleService.getToggle(
      'USE_FOOTPRINTS_NEW_SERVICE_TOGGLE'
    ).featureEnabled;
  }

}
