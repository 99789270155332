import {
  Component,
  AfterContentInit,
  ContentChildren,
  QueryList,
  Input,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { SlideComponent } from '../slide/slide.component';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
})
export class SliderComponent implements AfterContentInit {
  @Input() textInSteps = '';

  @ContentChildren(SlideComponent, { read: ElementRef })
  items: QueryList<ElementRef>;
  @ViewChild('slides', { static: false }) slidesContainer: ElementRef;

  isActiveNext = true;
  isActivePrev = false;
  slidesIndex = 0;
  totalSlide = 0;

  constructor() {}
  ngAfterContentInit(): void {}

  ngAfterViewInit() {
    this.totalSlide = this.items.length;
    if (this.totalSlide == 1) {
      this.isActiveNext = false;
    }
  }

  get currentItem() {
    return this.items.find((item, index) => index === this.slidesIndex);
  }

  onClickLeft() {
    this.slidesContainer.nativeElement.scrollLeft -=
      this.currentItem.nativeElement.offsetWidth;

    if (this.slidesIndex > 0) {
      this.slidesIndex--;
      this.isActiveNext = true;
    }

    if (this.slidesIndex === 0) {
      this.isActivePrev = false;
    }
  }

  onClickRight() {
    this.slidesContainer.nativeElement.scrollLeft +=
      this.currentItem.nativeElement.offsetWidth;

    if (this.slidesIndex < this.items.length - 1) {
      this.slidesIndex++;
      this.isActivePrev = true;
      this.isActiveNext = true;
    }

    if (this.slidesIndex === this.items.length - 1) {
      this.isActiveNext = false;
    }
  }
}
