import { Injectable } from '@angular/core';

@Injectable()
export class MasterPageService {
  public title: string;

  constructor() { }

  getTitle(): string {
    return this.title;
  }

  setTitle(tit: string) {
    this.title = tit;
  }
}
