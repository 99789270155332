// Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Declarations
import { GoogleAnalyticsEventsService } from '../../services/remote/google-analytics-events.service';

@Component({
  selector: 'app-acceptance-data',
  templateUrl: './acceptance-data.component.html',
  styleUrls: ['./acceptance-data.component.css']
})
export class AcceptanceDataComponent implements OnInit {
  @Input() acceptanceDataObject: any;

  @Output() noAcceptData: EventEmitter<null> = new EventEmitter();
  @Output() acceptData: EventEmitter<null> = new EventEmitter();


  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent('Autorización Tratamiento Datos', 'Ingreso página aceptación de datos básicos', 'Página acceptance-data', Date.now());
  }

  acceptanceData() {
    this.googleAnalyticsEventsService.emitEvent('Autorización Tratamiento Datos', 'Aceptar', 'Boton Ok Acepto', Date.now());
    this.acceptData.emit();
  }

  noAcceptanceData() {
    this.googleAnalyticsEventsService.emitEvent('Autorización Tratamiento Datos', 'No aceptar', 'Boton No Gracias', Date.now());
    this.noAcceptData.emit();
  }
}
