import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ApplicationConstantsEnum } from '../../enums/applications-constants.enum';
import { CommonTextEnum } from '../../enums/common-text.enum';
import { EventCodeEnum } from '../../enums/event-code.enum';

import { EventAnalyticsService } from '../../services/local/event-analytics.service';

import { TrailsRemoteService } from '../../services/remote/trails-remote.service';

@Component({
  selector: 'app-pop-up-certificate',
  templateUrl: './pop-up-certificate.component.html',
  styleUrls: ['./pop-up-certificate.component.css'],
})
export class PopUpCertificateComponent implements OnInit {
  @ViewChild('newEmail', { static: false }) newEmail: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopUpCertificateComponent>,
    private eventAnalyticsService: EventAnalyticsService,
    private trailsRemoteService: TrailsRemoteService
  ) {}

  canSetNewEmail = false;
  classButton = 'popUpBackButtonDisabled';
  dataInit: any;
  email = '';
  emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  loading: boolean;
  noCertificate = false;
  retryAttemps: number =
    ApplicationConstantsEnum.NUMBER_ATTEMPTS_SEND_MAIL_WITH_CERTIFICATE_REQUEST;
  yesCertificate = false;

  ngOnInit() {}

  checkDesicion(checkType: string) {
    if (checkType === 'Y') {
      this.noCertificate = this.yesCertificate;
    } else {
      this.yesCertificate = this.noCertificate;
    }
  }

  close() {
    this.dialogRef.close(this.retryAttemps > 0 ? this.yesCertificate : false);
  }

  continue() {
    this.close();
  }

  disableButtonCertificateRequired() {
    let disable = !this.yesCertificate && !this.noCertificate;
    if (this.newEmail && this.yesCertificate) {
      const regexp = new RegExp(this.emailPattern);
      disable = this.newEmail
        ? !regexp.test(this.newEmail.nativeElement.value)
        : true;
    }

    this.classButton = disable ? 'popUpBackButtonDisabled' : 'popUpBackButton';

    return disable;
  }

  hasEmail() {
    return this.data.emailCustomer && this.data.emailCustomer !== '';
  }

  generateCertificate() {
    if (this.yesCertificate) {
      this.loading = true;
      this.setEventCertificateRequest(CommonTextEnum.SI);

      if (!this.dataInit) {
        this.dataInit = JSON.parse(JSON.stringify(this.data));
      }

      this.trailsRemoteService
        .getCertificate({
          documentNumber: this.data.documentNumber,
          documentType: this.data.documentType,
          email: this.email,
          obligationNumber: this.data.obligationNumber,
        })
        .subscribe(
          () => {
            this.loading = false;
            this.setEventSendMailCertificateRequest(
              JSON.stringify({
                EmailType:
                  this.email === ''
                    ? ApplicationConstantsEnum.EMAIL_CRM
                    : ApplicationConstantsEnum.EMAIL_UPDATE,
                SendMailState: 'SUCCESS',
              })
            );

            this.data.message =
              'El certificado fue enviado correctamente al correo electrónico ' +
              (this.email === '' ? this.data.emailCustomer : this.email);
            this.data.textButtom = 'Continuar';
            this.data.title = 'Certificado enviado';
            this.data.type = 'S';
          },
          (errorCertificate: any) => {
            this.loading = false;

            if (this.retryAttemps > 0) {
              this.data.message = `Tenemos inconvenientes técnicos para enviar el certificado, por favor intenta nuevamente. Tienes ${this.retryAttemps} intentos de 3.`;
              this.data.textButtom = 'Reintentar';
              this.data.title = 'Error enviando el certificado';
              this.data.type = 'E';

              this.retryAttemps--;

              this.setEventSendMailCertificateRequest(
                JSON.stringify({
                  EmailType:
                    this.email === ''
                      ? ApplicationConstantsEnum.EMAIL_CRM
                      : ApplicationConstantsEnum.EMAIL_UPDATE,
                  retryAttempts:
                    ApplicationConstantsEnum.NUMBER_ATTEMPTS_SEND_MAIL_WITH_CERTIFICATE_REQUEST -
                    this.retryAttemps,
                  SendMailState: 'ERROR',
                }),
                JSON.stringify(errorCertificate)
              );
            } else {
              this.data.message =
                'Superaste los intentos permitidos, ingresa más tarde y vuelve a intentarlo';
              this.data.textButtom = 'Continuar';
              this.data.title = 'Has superado el máximo de intentos';
              this.data.type = 'R';
            }

            console.log(
              'PopUpCertificateComponent - generateCertificate, Error: ' +
                JSON.stringify(errorCertificate)
            );
          }
        );
    } else {
      this.setEventCertificateRequest(CommonTextEnum.NO);
      this.close();
    }
  }

  retry() {
    this.data = JSON.parse(JSON.stringify(this.dataInit));
  }

  updateEmail() {
    this.canSetNewEmail = !this.canSetNewEmail;
    if (!this.canSetNewEmail) {
      this.email = '';
    }
  }

  private setEventCertificateRequest(response: string) {
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.CERTIFICATE_REQUEST,
      response
    );
  }

  private setEventSendMailCertificateRequest(
    response: string,
    errorCause?: string
  ) {
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SEND_MAIL_WITH_CERTIFICATE_REQUEST,
      response,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      errorCause
    );
  }
}
