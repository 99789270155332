import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css'],
})
export class CaptchaComponent implements OnInit {
  @Input() visible: boolean;
  @Output() sendCaptcha: EventEmitter<any> = new EventEmitter();
  @ViewChild('reCaptcha', { static: false }) reCap: any;

  constructor() {}

  ngOnInit() {}

  resolved(captchaResponse: string) {
    this.sendCaptcha.emit({ captchaResponse: captchaResponse });
  }

  reset() {
    this.reCap.reset();
  }
}
