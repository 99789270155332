export enum ApplicationConstantsEnum {
  EMAIL_CRM = 'EMAIL_CRM',
  EMAIL_UPDATE = 'EMAIL_UPDATE',
  FACE_TO_FACE_SALE = 'Venta Presencial',
  MAXIMUM_DECRIM_FILE_SIZE = 2090000,
  MINIMUM_PHONE_FIXED_LENGTH = 7,
  NUMBER_ATTEMPTS_SEND_MAIL_WITH_CERTIFICATE_REQUEST = 3,
  NUMBER_DECRIM_FILES_WITH_FACIAL_IDENTIFICATION = 4,
  STEP_LIMIT = 6,
  TELEPHONE_SALE = 'Venta Telefónica',
}
