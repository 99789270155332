// Imports
import { Injectable } from '@angular/core';

@Injectable()
export class StepperService {
  private steps = [];

  public setStep(step: string) {
    this.steps.push(step);
  }

  public getSteps() {
    return this.steps;
  }

  public resetSteps() {
    this.steps = [];
  }
}
