import { Injectable } from '@angular/core';
import { EventCodeEnum } from '../../enums/event-code.enum';
import { TransactionAudit } from '../../interfaces/transaction-audit.interface';
import { AuditService } from '../remote/audit.service';
import { UserService } from './user.service';
import { User } from '../../interfaces/user.interface';

@Injectable()
export class EventAnalyticsService {
  private user: User;
  private transactionAudit: TransactionAudit;

  constructor(
    private auditService: AuditService,
    private userService: UserService
  ) { }

  sendEventAnalytics(
    eventCode: EventCodeEnum,
    eventDescription: string,
    loanAmount?: string,
    currentBalance?: string,
    currentQuotaAmount?: string,
    loanQuotas?: string,
    missingLoanQuotas?: string,
    errorCause?: string
  ) {
    this.user = this.userService.getUser();
    this.transactionAudit = {
      documentType: this.user.documentType,
      documentNumber: this.user.documentId + '',
      acceptanceText: undefined,
      acceptance: undefined,
      officeId: this.user.officeCode,
      description: eventDescription,
      eventCode: eventCode,
      businessAdvisorNumber: this.user.sellerIdNumber
        ? this.user.sellerIdNumber.toString()
        : undefined,
      loanAmount: loanAmount,
      currentBalance: currentBalance,
      currentQuotaAmount: currentQuotaAmount,
      loanQuotas: loanQuotas,
      missingLoanQuotas: missingLoanQuotas,
      errorCause: errorCause,
    };
    this.auditService
      .saveTransactionalAuditEventAnalytics(this.transactionAudit)
      .subscribe(
        () => {},
        (error) => this.saveTransactionalAuditEventError(error)
      );
  }

  private saveTransactionalAuditEventError(error: any) {
    console.log(
      'sendEventAnalytics - saveTransactionalAuditEventError, Error: ' +
        JSON.stringify(error)
    );
  }
}
