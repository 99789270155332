export const environment = {
  production: false,
  envName: 'develop',

  serverUrlNew: 'https://gb-salaryloans-dev-front.avaldigitallabs.com/',
  serverUrl:
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/',
  serverUrlPayrollPayer:
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/payroll-payer/',
  serverUrlOrganizationManager:
    'https://xrq3hw3qs3.execute-api.us-east-2.amazonaws.com/organization-manager/',
  serverUrlOrganizationManagerNew:
    'https://bvmm7p1yva.execute-api.us-east-2.amazonaws.com/v1/organization/',
  serverUrlTraining:
    'https://lnypxqjh16.execute-api.us-east-2.amazonaws.com/training-manager/',
  serverUrlSecurityManager:
    'https://801b5y3fcj.execute-api.us-east-2.amazonaws.com/security-manager/',
  serverUrlBackendSecurity:
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/',

  serverUrlRiskManager:
    'https://p6gh609uq1.execute-api.us-east-2.amazonaws.com/risk-management/',

  codeGoogleAnalytics: 'UA-120789499-1',
  rsaOaep512PublicKey:
    'zCi8mi3DgzVwwbVEhb3oTvWr73RG3CKfkVirxjpA5DLjBRslXICgBtVlMW3zkLqcz75f_cz0B9GqL2y6weRtGkPyUTaOuea1ZkeuGmAXDfXEdYpmRFxQ6D5B-49S8x4iUe-Jd4nL4qWuQGtWXNPKSu8_mOPKj64Nw5eA5IKLOdPZd3D0hrfWxNXWWEzcvbArVrhA00xgmZb23V2z_HufHVsnlyFlWKNcXmepB_76wlW5RKoh2Ns0ZbOrASvbTYB4NqLHZy3YQ0mkimNH1JOUmtZ-XeGIPOUpKPM_bah0BTaFOzFq8UaNLhs2_kgOfzgZI5bFx__JmwOYbVBhR_MAJw',

  awsCognitoData: {
    host: 'lnypxqjh16.execute-api.us-east-2.amazonaws.com',
    hostBackendSecurity: '7kzbg9b5j1.execute-api.us-east-2.amazonaws.com',
    hostSecurity: '801b5y3fcj.execute-api.us-east-2.amazonaws.com',
    hostOrganizationManager: 'xrq3hw3qs3.execute-api.us-east-2.amazonaws.com',
    hostRiskManager: 'p6gh609uq1.execute-api.us-east-2.amazonaws.com',
    region: 'us-east-2',
    service: 'execute-api',
  },

  // Por favor no cambie este valor, esto es parte de un toggle compuesto para habilitar la autenticación federada
  federatedAuthEnabled: true,

  federatedAuthUrl:
    'https://adl-habilitadora-stg-employee-bpop-federation.auth.us-east-2.amazoncognito.com',
  federatedAuthRedirectUrl:
    'https://gb-salaryloans-dev-front.avaldigitallabs.com/loginadvisor',
  federatedAuthLogoutUrl:
    'https://gb-salaryloans-dev-front.avaldigitallabs.com/loginadvisor',

  security: {
    cipherPublicKeyUrl:
      'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/dev/cipher/public-key',
    symmetricKeyUrl:
      'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/dev/cipher/symmetric-key',
  },

  cognitoLegacy: {
    url: 'https://bpop-adl-dev.auth.us-east-2.amazoncognito.com/oauth2/token',
    clientId: '5mbe5hp40p7mtcc7l21jvflf9c',
    clientSecret: '162ulv2hjadh00jsl369tfg1qhj83m5l3gl9tatm56mcmodfjgse',
  },

  encryptUrls: [
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/offering',
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/v1/salaryloans-core/payer/platform/bindings',
    'https://7kzbg9b5j1.execute-api.us-east-2.amazonaws.com/security-manager/auth/certihuella',
  ],
};
