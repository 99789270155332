// Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Declarations
import { environment } from '../../../../environments/environment';

@Injectable()
export class EventService {
  constructor(
    private http: HttpClient) { }

  public pushEvent(object: object): Observable<any> {
    return this.http.post(environment.serverUrl + 'event', object);
  }
}
