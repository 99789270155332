// Imports
import {BurosService} from '../../remote/buros.service';
import {CreditFlowType} from '../../../enums/credit-flow-type.enum';
import {CustomerService} from '../../remote/customer.service';
import {PreselectService} from '../preselect.service';
// Interfaces
import {ICreditFlowFactory} from './credit-flow/Icredit-flow-factory.interface';
// Class
import {CreditFlowAnyFee} from './credit-flow/credit-flow-any-fee';

export abstract class CreditFlowFactory {
  public static getPetition(_creditFlowType: CreditFlowType, _burosService: BurosService, _customerService: CustomerService, _preselectService: PreselectService): ICreditFlowFactory {

    return new CreditFlowAnyFee(_burosService, _customerService, _preselectService);
  }
}
