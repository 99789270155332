import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { MessageBox } from '../../interfaces/message-box.interface';

import { CommonService } from '../../services/local/common.service';
import { EventAnalyticsService } from '../../services/local/event-analytics.service';
import { UserService } from '../../services/local/user.service';

import { CustomerFeedbackComponent } from '../customer-feedback/customer-feedback.component';
import { EventCodeEnum } from '../../enums/event-code.enum';
import { RedirectNewFrontTypeEnum } from '../../enums/redirect-new-front-type.enum';

@Component({
  selector: 'app-pop-up-pep',
  templateUrl: './pop-up-pep.component.html',
  styleUrls: ['./pop-up-pep.component.css'],
})
export class PopUpPepComponent {
  constructor(
    private commonService: CommonService,
    private eventAnalyticsService: EventAnalyticsService,
    private router: Router,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: MessageBox,
    public dialogRef: MatDialogRef<PopUpPepComponent>
  ) {}

  onNoClick(desition: boolean): void {
    this.dialogRef.close();

    if (this.data.termAndConditions && desition) {
      this.eventAnalyticsService.sendEventAnalytics(
        EventCodeEnum.ACCEPT_TERMS_AND_CONDITIONS,
        '',
        'No aceptó términos y condiciones'
      );
    }

    if (this.data.feedback) {
      let dialogFeed = this.commonService.showMesssageBox(
        CustomerFeedbackComponent,
        true,
        {
          clear: true,
          stepId: this.data.stepId,
          stepDescription: this.data.stepDescription,
        },
        500
      );

      dialogFeed.afterClosed().subscribe((_) => {
        this.redirectURL();
      });
    } else {
      this.redirectURL();
    }

    if (this.data.clearUser) {
      this.userService.clearUser();
    }
  }

  onClickCancelButton() {
    this.data.feedback = true;
    this.onNoClick(true);
  }

  private redirectURL(): void {
    if (this.data.url && this.data.url !== '') {
      this.userService.isCustomerNewPayrollLoan() &&
      this.data.url.includes('master-page/salary-loans-overview')
        ? this.userService.goToDecisionPage(RedirectNewFrontTypeEnum.NEWLOAN)
        : this.router.navigate([this.data.url]);
    }

    if (this.data.externalUrl && this.data.externalUrl !== '') {
      window.open(this.data.externalUrl, '_blank');
    }
  }
}
