import { Component, Input } from '@angular/core';
import { Event } from '../../interfaces/event.interface';

@Component({
  selector: 'app-acceptance-data-mock',
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class AcceptanceDataMock {
  @Input() nameShort: string;

  private event: Event;

  constructor() {}

  acceptanceData() {
    this.event = {
      documentType: 'CC',
      documentNumber: 227936,
      event: 'ACCEPTANCE_DATA',
      name: 'TYY',
      eventText: 'UUU',
    };
  }
}
