import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements AfterViewInit, OnInit {
  @Input() page: number;
  @ViewChild('pagOne', { static: false }) pagOne: ElementRef;
  @ViewChild('pagTwo', { static: false }) pagTwo: ElementRef;
  @ViewChild('pagThree', { static: false }) pagThree: ElementRef;
  @ViewChild('pagFour', { static: false }) pagFour: ElementRef;

  constructor(private renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    this.selectPage();
  }

  ngOnInit() {}

  selectPage() {
    if (this.page === 1) {
      this.addRemClass(this.pagOne, 'carouselActive', 'carouselInactive');
    } else if (this.page === 2) {
      this.addRemClass(this.pagTwo, 'carouselActive', 'carouselInactive');
    } else if (this.page === 3) {
      this.addRemClass(this.pagThree, 'carouselActive', 'carouselInactive');
    } else if (this.page === 4) {
      this.addRemClass(this.pagFour, 'carouselActive', 'carouselInactive');
    }
  }

  addRemClass(obj: ElementRef, claAdd: string, claRem: string) {
    this.renderer2.addClass(obj.nativeElement, claAdd);
    this.renderer2.removeClass(obj.nativeElement, claRem);
  }
}