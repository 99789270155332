import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { UserService } from '../local/user.service';
import { AwsSignatureService } from '../local/aws-signature.service';
import { AwsSignatureInputData } from '../../interfaces/aws-input.interface';

@Injectable()
export class OrganizationManagerService {
  awsInputData: AwsSignatureInputData = new AwsSignatureInputData();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private AwsSignature: AwsSignatureService
  ) {}

  searchOffices(
    toggleStatus: boolean = false,
    cognitoToken: string = ''
  ): Observable<object> {
    if (toggleStatus) {
      return this.callOfficesLambda(cognitoToken);
    }
    return this.http.get(environment.serverUrlOrganizationManager + 'offices');
  }

  searchAdministrativeOffice(
    toggleStatus: boolean = false,
    cognitoToken: string = ''
  ): Observable<object> {
    if (toggleStatus) {
      return this.callAdmnistrativeOfficeLambda(cognitoToken);
    }
    return this.http.get(
      environment.serverUrlOrganizationManager + 'administrativeOffices'
    );
  }

  searchBenchmarkingSalaryLoansLoyalty(
    toggleStatus: boolean = false,
    cognitoToken: string = ''
  ): Observable<object> {
    if (toggleStatus) {
      return this.callBenchmarkingLoyaltyLambda(cognitoToken);
    }

    const headers = this.setHeaders(
      'GET',
      '/organization-manager/benchmarking/salary-loans/loyalty',
      'application/x-www-form-urlencoded'
    );
    return this.http.get(
      environment.serverUrlOrganizationManager +
        'benchmarking/salary-loans/loyalty',
      { headers }
    );
  }

  setLoyaltyOffered(
    offered: any,
    toggleStatus: boolean = false,
    cognitoToken: string = ''
  ): Observable<object> {
    if (toggleStatus) {
      return this.callSurveyLoyaltyLambda(offered, cognitoToken);
    }

    const headers = this.setHeaders(
      'POST',
      '/organization-manager/survey/loyalty',
      'application/json',
      JSON.stringify(offered)
    );
    return this.http.post(
      environment.serverUrlOrganizationManager + 'survey/loyalty',
      offered,
      { headers }
    );
  }

  callAdmnistrativeOfficeLambda(cognitoToken: string): Observable<object> {
    const headers = new HttpHeaders({ cognitoToken: cognitoToken });
    return this.http.get(
      `${environment.serverUrlOrganizationManagerNew}administrative-offices`,
      { headers }
    );
  }

  callOfficesLambda(cognitoToken: string): Observable<object> {
    const headers = new HttpHeaders({ cognitoToken: cognitoToken });
    return this.http.get(
      `${environment.serverUrlOrganizationManagerNew}offices`,
      { headers }
    );
  }

  callBenchmarkingLoyaltyLambda(cognitoToken: string): Observable<object> {
    const headers = new HttpHeaders({ cognitoToken: cognitoToken });
    return this.http.get(
      `${environment.serverUrlOrganizationManagerNew}benchmarking-loyalty`,
      { headers }
    );
  }

  callSurveyLoyaltyLambda(
    offered: any,
    cognitoToken: string
  ): Observable<object> {
    const headers = new HttpHeaders({ cognitoToken: cognitoToken });
    return this.http.post(
      `${environment.serverUrlOrganizationManagerNew}survey/loyalty`,
      offered,
      { headers }
    );
  }

  setHeaders(
    method: string,
    canonicalUri: string,
    contentType: string,
    requestParameters?: any
  ) {
    const userInfo = this.userService.getUser();

    this.awsInputData.method = method;
    this.awsInputData.canonicalUri = canonicalUri;
    this.awsInputData.host = environment.awsCognitoData.hostOrganizationManager;
    this.awsInputData.canonicalQuerystring = '';
    this.awsInputData.region = environment.awsCognitoData.region;
    this.awsInputData.service = environment.awsCognitoData.service;
    this.awsInputData.accessKey = userInfo.accessKey;
    this.awsInputData.secretKey = userInfo.secretKey;
    this.awsInputData.contentType = contentType;
    this.awsInputData.requestParameters = requestParameters
      ? requestParameters
      : '';

    const signature = this.AwsSignature.generateSignature(this.awsInputData);

    return new HttpHeaders({
      'Content-Type': contentType,
      Authorization: signature['Authorization'],
      'X-Amz-Security-Token': userInfo.sessionToken,
      'X-Amz-Date': signature['X-Amz-Date'],
    });
  }
}
