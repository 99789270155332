import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ICognitoLegacy } from '../../interfaces/cognito.interface';

import { CognitoRemoteService } from './../remote/cognito-remote.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private cognitoTokenLegacy = new BehaviorSubject<ICognitoLegacy>(null);
  private currentCognitoTokenLegacy = this.cognitoTokenLegacy.asObservable();

  constructor(private cognitoRemoteService: CognitoRemoteService) {}

  getCognitoCredentialsLegacy(): Observable<object> {
    return this.cognitoRemoteService.getTokenLegacy();
  }

  getTokenLegacy(): ICognitoLegacy {
    return this.cognitoTokenLegacy.value;
  }

  getTokenLegacyObservable(): Observable<ICognitoLegacy> {
    return this.currentCognitoTokenLegacy;
  }

  setTokenLegacy(cognitoCredentialsLegacy: ICognitoLegacy): void {
    this.cognitoTokenLegacy.next(cognitoCredentialsLegacy);
  }
}
