import { throwError as observableThrowError, Observable } from 'rxjs';

import { delay, map, mergeMap, retryWhen } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { RequestFile } from '../../interfaces/request-file.interface';
import { OtpConfirm } from '../../interfaces/otp-confirm.interface';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  validAdvisor(advisor: any): Observable<object> {
    return this.http.post(environment.serverUrl + 'advisor/search', advisor);
  }

  generateRenewalConfirmationCode(
    obligationId: string,
    confirmationCodeRequest: any
  ): Observable<object> {
    return this.http.post(
      environment.serverUrl + obligationId + '/renewal-confirmation-code',
      confirmationCodeRequest
    );
  }

  validateRenewalConfirmationCode(otpConfirm: OtpConfirm): Observable<object> {
    return this.http.put(
      environment.serverUrl + 'validate-renewal-confirmation-code',
      otpConfirm
    );
  }

  renewSalaryloan(obligationId: string, requestFile: RequestFile) {
    return this.initRenewProcess(requestFile, obligationId).pipe(
      mergeMap((data: any) =>
        this.getRenewSalaryLoanResponse(obligationId, data.processId, 30, 4000)
      )
    );
  }

  sendTermsAndConditions(obligationId: string, requestFile: RequestFile) {
    return this.termsAndConditionsService(requestFile, obligationId);
  }

  private getRenewSalaryLoanResponse(
    obligationId: string,
    processId: string,
    retryMax: number,
    retryDelay: any
  ) {
    let retryCount = 0;

    return this.getRenewProcessResponse(obligationId, processId).pipe(
      map((data: any) =>
        data.status === 'RenewalProcessSuccessful'
          ? data
          : observableThrowError(data)
      ),
      retryWhen((attemps) =>
        attemps.pipe(
          map((error: any) => {
            if (error.error.code === 'InProgress' && ++retryCount < retryMax) {
              return error;
            } else {
              throw error;
            }
          }),
          delay(retryDelay)
        )
      )
    );
  }

  private initRenewProcess(requestFile: RequestFile, obligationId: string) {
    return this.http.post(
      environment.serverUrl + obligationId + '/renew-process',
      requestFile
    );
  }

  private getRenewProcessResponse(obligationId: string, processId: string) {
    return this.http.get(
      environment.serverUrl + obligationId + '/renew-process/' + processId
    );
  }

  private termsAndConditionsService(
    requestFile: RequestFile,
    obligationId: string
  ) {
    return this.http.post(
      environment.serverUrl + obligationId + '/terms-and-conditions',
      requestFile
    );
  }

  pushFeedback(feedback: any): Observable<object> {
    return this.http.post(environment.serverUrl + 'feedback', feedback);
  }

  toggleList(): Observable<object> {
    return this.http.get(environment.serverUrl + 'features/all');
  }

  async asyncToggleList() {
    return this.http.get(environment.serverUrl + 'features/all').toPromise();
  }
}
