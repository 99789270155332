import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationConstantsEnum } from '../../../shared/enums/applications-constants.enum';
import { RedirectNewFrontTypeEnum } from '../../../shared/enums/redirect-new-front-type.enum';

import { PreselectService } from '../../../shared/services/local/preselect.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { UserService } from '../../../shared/services/local/user.service';

import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

@Component({
  selector: 'app-carousel-steps',
  templateUrl: './carousel-steps.component.html',
  styleUrls: ['./carousel-steps.component.css'],
})
export class CarouselStepsComponent implements AfterViewInit, OnInit {
  @ViewChildren('child') childrenPage: QueryList<ElementRef>;

  @Input() isCarouselPages = true;
  @Input() size: number = ApplicationConstantsEnum.STEP_LIMIT;
  @Input() url: string;

  @Input('page')
  set setPage(page: number) {
    this.page = page;

    if (this.childrenPage) {
      this.selectPage();
    }
  }

  page: number;
  payerName: string;

  constructor(
    private chageDetectorRef: ChangeDetectorRef,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private preselectService: PreselectService,
    private renderer2: Renderer2,
    private router: Router,
    private stepperService: StepperService,
    private userService: UserService
  ) {}

  ngAfterViewInit(): void {
    if (this.isCarouselPages) {
      this.payerName = this.preselectService.getPreselect().payerName;

      if (this.payerName.length > 23) {
        this.payerName = this.payerName.substring(0, 20).trim() + '...';
      }
    }

    this.stepperService.setStep('CarouselSteps');
    this.selectPage();

    this.chageDetectorRef.detectChanges();
  }

  ngOnInit() {}

  selectPage() {
    for (let i = 0; i < this.page - 1; i++) {
      this.addRemClass(
        this.childrenPage.toArray()[i],
        'circleNumber-success',
        'circleNumber'
      );
    }

    this.addRemClass(
      this.childrenPage.toArray()[this.page - 1],
      'circleNumber-active',
      'circleNumber'
    );
  }

  addRemClass(obj: ElementRef, claAdd: string, claRem: string) {
    this.renderer2.addClass(obj.nativeElement, claAdd);
    this.renderer2.removeClass(obj.nativeElement, claRem);
  }

  back() {
    switch (this.page) {
      case 1:
        if (this.userService.isCustomerNewPayrollLoan()) {
          this.userService.goToDecisionPage(RedirectNewFrontTypeEnum.NEWLOAN);
        }

        this.googleAnalyticsEventsService.emitEvent(
          'Declaración Peps',
          'No quiere declarar',
          'Devolverse',
          Date.now()
        );

        break;
      case 2:
        this.googleAnalyticsEventsService.emitEvent(
          'Actualizacion Datos',
          'No quiere actualizar datos',
          'Devolverse',
          Date.now()
        );

        break;
      case 3:
        this.googleAnalyticsEventsService.emitEvent(
          'Personalizar Oferta',
          'Interrupir proceso personalizacion',
          'Devolverse',
          Date.now()
        );

        break;
      case 4:
        this.googleAnalyticsEventsService.emitEvent(
          'Personalizar Oferta',
          'Interrupe confirmacion de oferta',
          'Devolverse',
          Date.now()
        );

        break;
      case 5:
        this.googleAnalyticsEventsService.emitEvent(
          'Seleccionar Cuenta',
          'Interrupe seleccion de cuenta',
          'Devolverse',
          Date.now()
        );

        break;
      case 6:
        this.googleAnalyticsEventsService.emitEvent(
          'Confirmar Otp',
          'Interrupe confirmacion de Otp',
          'Devolverse',
          Date.now()
        );

        break;
    }

    this.router.navigate([this.url]);
  }
}
