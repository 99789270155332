import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { CreditFlowType } from '../../enums/credit-flow-type.enum';
import { SalesProcessTypeEnum } from '../../enums/sales-process-type.enum';

@Injectable()
export class PayrollPayerService {
  constructor(private http: HttpClient) {}

  getPayrollDocuments(dataRequired: any) {
    return this.http
      .post(`${environment.serverUrlPayrollPayer}documentsPayer`, dataRequired)
      .pipe(map((data) => data[0]));
  }
}
