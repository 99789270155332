import { LoadingComponent } from '../../../components/loading/loading.component';
import { PopUpPepComponent } from '../../../components/pop-up/pop-up-pep.component';

import { CertihuellaMessagesEnum } from '../../../enums/certihuella-messages.enum';
import { CertihuellaStatesEnum } from '../../../enums/certihuella-states.enum';
import { CommonTextEnum } from '../../../enums/common-text.enum';
import { IconsEnum } from '../../../enums/icons.enum';
import { LoadingTypeEnum } from '../../../enums/loading-type.enum';

import { MessageBox } from '../../../interfaces/message-box.interface';
import { IResponseValidateCertihuella } from '../../../interfaces/response-validate-certihuella.interface';
import { IValidateAuthentication } from './validate-authentication.interface';
import { User } from '../../../interfaces/user.interface';

import { AuthenticationService } from '../../remote/authentication.service';

import { CommonService } from '../common.service';
import { EventAnalyticsService } from '../event-analytics.service';
import { UserService } from '../user.service';

export class ValidateAuthenticationCertihuella
  implements IValidateAuthentication
{
  private user: User;
  private retryRnec = 0;

  constructor(
    private appLoading: LoadingComponent,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private eventAnalyticsService: EventAnalyticsService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
  }

  validateAuthentication(): void {
    this.appLoading.visible = true;
    this.appLoading.title = 'Validando Certihuella, un minuto por favor';
    this.appLoading.subtitle = this.commonService.getMessageLoadingDecrim();
    this.appLoading.changeSpinner(LoadingTypeEnum.PIXEL_SPINNER_SHOW);

    this.authenticationService
      .validateCaseCertihuella({
        documentType: this.user.documentType,
        documentNumber: this.user.documentId + '',
        nut: this.user.idCaseRnec,
        salesModel: this.user.decisionType,
      })
      .subscribe(
        (responseAuthRnec) => {
          this.validateReponseSucessful(responseAuthRnec);
        },
        (errorAuthRnec) => {
          this.validateResponseError(
            errorAuthRnec,
            CommonTextEnum.ERROR_TECHNICAL,
            '/master-page/salary-loans-overview'
          );
        }
      );
  }

  private showMessageBox(messageBox: MessageBox) {
    this.appLoading.visible = false;
    return this.commonService.showMesssageBox(
      PopUpPepComponent,
      true,
      messageBox,
      500
    );
  }

  private validateResponseError(
    error: any,
    message: string,
    url: string,
    buttonText = 'Reintentar',
    feedBack = false
  ) {
    console.log(
      'DataClientComponent - validateCaseCertihuellaError, Error: ' +
        JSON.stringify(error)
    );

    this.showMessageBox({
      icon: 'S',
      title: '',
      message: message,
      url: url,
      img: '',
      textButtom: buttonText,
      clearUser: false,
      feedback: feedBack,
      routerDestination: '',
      stepId: 0,
      stepDescription: '',
    });
  }

  private validateReponseSucessful(
    responseAuthCertihuella: IResponseValidateCertihuella
  ): void {
    let messageBox: MessageBox = {
      iconHeader: '',
      title: '!No podemos seguir!',
      message: '',
      url: '',
      img: '',
      textButtom: 'Aceptar',
      clearUser: false,
      feedback: true,
      routerDestination: '/welcome',
      stepId: 0,
      stepDescription: '',
    };
    this.appLoading.visible = false;

    let typeResponse = responseAuthCertihuella.response
      ? responseAuthCertihuella.response
      : responseAuthCertihuella.message
      ? responseAuthCertihuella.message
      : 'sin identificar';

    switch (typeResponse.toLowerCase()) {
      case CertihuellaStatesEnum.CASE_AUTHORIZED:
        messageBox.title = '!Podemos seguir!';
        messageBox.iconHeader = IconsEnum.TARGET;
        messageBox.routerDestination = '';
        messageBox.feedback = false;
        messageBox.message = CertihuellaMessagesEnum.SUCCESS;
        this.showMessageBox(messageBox);
        break;

      case CertihuellaStatesEnum.CASE_REJECTED_BY_TITULAR:
        messageBox.iconHeader = IconsEnum.OFFICE_WORKER;
        messageBox.message = CertihuellaMessagesEnum.FAILURE_BY_TITULAR;
        this.showMessageBox(messageBox);
        break;

      case CertihuellaStatesEnum.CASE_REJECTED_BY_DATE:
        messageBox.iconHeader = IconsEnum.CALENDAR;
        messageBox.message = CertihuellaMessagesEnum.FAILURE_BY_DATE;
        this.showMessageBox(messageBox);
        break;

      case CertihuellaStatesEnum.CASE_NO_DATA_FOUND:
        this.appLoading.visible = true;
        if (this.retryRnec <= 1) {
          this.retryRnec++;
          setTimeout(() => {
            this.validateAuthentication();
          }, 30000);
        } else {
          this.appLoading.visible = false;

          this.validateResponseError(
            'Intentos fallidos',
            CertihuellaMessagesEnum.NOT_FOUND,
            '/welcome',
            'Aceptar',
            true
          );
        }
        break;

      default:
      case CertihuellaStatesEnum.CASE_REJECTED:
        messageBox.iconHeader = IconsEnum.LOUD;
        messageBox.message = CertihuellaMessagesEnum.FAILURE;
        this.showMessageBox(messageBox);
        break;
    }
  }
}
