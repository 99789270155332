import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-special-offer-button',
  templateUrl: './special-offer-button.component.html',
  styleUrls: ['./special-offer-button.component.css'],
})
export class SpecialOfferButtonComponent {
  @Input() disable = false;
  @Output() clickEvent = new EventEmitter<boolean>();

  constructor() {}

  onClick() {
    this.clickEvent.emit(true);
  }
}
