import { Component, OnInit } from '@angular/core';

import { DataClientType } from '../../../shared/enums/data-client-type.enum';
import { StepperService } from '../../../shared/services/local/stepper.service';

@Component({
  selector: 'app-data-client-pep',
  templateUrl: './data-client-pep.component.html',
  styleUrls: ['./data-client-pep.component.css']
})
export class DataClientPepComponent implements OnInit {
  type: DataClientType;

  constructor(private stepperService: StepperService) {
    this.type = DataClientType.BYCAROUSEL;
  }

  ngOnInit() {
    this.stepperService.setStep('DataClientPep');
  }
}
