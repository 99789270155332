import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { PlatformBindingInterface } from '../../../../shared/interfaces/platform-binding';
import { PlatformBindingsRequestInterface } from '../../../../shared/interfaces/platform-bindings-request.interface';
import { PlatformOfferRequestInterface } from '../../../../shared/interfaces/platform-offer-request.interface';
import { SendLinkUploadFileRequestInterface } from '../../../../shared/interfaces/send-link-upload-file-request.interface';
import { User } from '../../../../shared/interfaces/user.interface';

import { CreditFlowType } from '../../../../shared/enums/credit-flow-type.enum';
import { DecisionEnum } from '../../../../shared/enums/decision-type.enum';
import { EventCodeEnum } from '../../../../shared/enums/event-code.enum';
import { ModalityType } from '../../../../shared/enums/modality-type.enum';
import { SalesProcessTypeEnum } from '../../../../shared/enums/sales-process-type.enum';

import { CommonService } from '../../../../shared/services/local/common.service';
import { CreditFlowService } from '../../../../shared/services/local/credit-flow.service';
import { EventAnalyticsService } from '../../../../shared/services/local/event-analytics.service';
import { PayrollCheckService } from '../../../../shared/services/local/payroll-check.service';
import { PreselectService } from '../../../../shared/services/local/preselect.service';
import { UserService } from '../../../../shared/services/local/user.service';

import { AuditService } from '../../../../shared/services/remote/audit.service';
import { CustomerService } from '../../../../shared/services/remote/customer.service';
import { GoogleAnalyticsEventsService } from '../../../../shared/services/remote/google-analytics-events.service';

import { PlatformBindingsComponent } from '../../../../shared/components/platform-bindings/platform-bindings.component';
import { PopUpPepComponent } from '../../../../shared/components/pop-up/pop-up-pep.component';

@Component({
  selector: 'app-salary-loans-overview-validation',
  templateUrl: './salary-loans-overview-validation.component.html',
  styleUrls: ['./salary-loans-overview-validation.component.css'],
})
export class SalaryLoansOverviewValidationComponent
  implements OnInit, AfterContentInit
{
  @Input() user: User;
  @Output() loadingEmit = new EventEmitter();
  @Output() errorShowInformationEmit = new EventEmitter();

  externalUser: any;
  numberErrorShowInformation = 0;
  tabsSlide = [];

  retryLimitSeconds = 600;
  retryIntervalSeconds = 30;

  constructor(
    private auditService: AuditService,
    private commonService: CommonService,
    private creditFlowService: CreditFlowService,
    private customerService: CustomerService,
    private eventAnalyticsService: EventAnalyticsService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private payrollCheckService: PayrollCheckService,
    private preselectService: PreselectService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.searchLoansValidate();
    this.creditFlowService.setModalityType(ModalityType.ORDINARY);
  }

  private searchLoansValidate() {
    this.loadingEmit.emit({ loading: true });
    this.customerService.searchLoansValidate(this.user).subscribe(
      (response) => this.searchLoansValidateSuccess(response),
      () => this.searchLoansValidateError()
    );
  }

  private searchLoansValidateSuccess(response: any) {
    for (let i = 0; i < response.length; i++) {
      if (response[i].payerName.length > 18) {
        response[i].originalPayerName = response[i].payerName;
        response[i].payerName =
          response[i].payerName.substring(0, 15).trim() + '...';
      }

      this.assignTitles(
        response.filter(
          (p: any) => p.loanRenewValidation === 'NOT_APPROVE_ACCOUNT_ISSUE'
        ).length,
        response[i]
      );

      this.assignSalesProccessType(response[i], this.user.decisionType);
    }

    this.tabsSlide = response;

    setTimeout(() => {
      this.stateTab(this.tabsSlide[0].obligationId, 'd-block');
      this.loadingEmit.emit({ loading: false });
    }, 800);
  }

  private assignSalesProccessType(response: any, decisionType: string) {
    if (
      decisionType === DecisionEnum.NEW_PAYROLL_LOAN &&
      response.displayButton
    ) {
      response.salesProcessType = SalesProcessTypeEnum.PRESENTIAL_SALE_MC;
    } else if (
      decisionType === DecisionEnum.NEW_PAYROLL_LOAN &&
      (response.displayButtonFile || response.displayButtonPlatform)
    ) {
      response.salesProcessType = SalesProcessTypeEnum.PRESENTIAL_SALE_TC;
    } else if (
      decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
      response.displayButton
    ) {
      response.salesProcessType = SalesProcessTypeEnum.TELEFONIC_SALE_MC;
    } else if (
      decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL &&
      (response.displayButtonPhoneCall ||
        response.displayButtonFile ||
        response.displayButtonPlatform)
    ) {
      response.salesProcessType = SalesProcessTypeEnum.TELEFONIC_SALE_TC;
    }
  }

  private assignTitles(countError: number, response: any) {
    response.displayButtonPhoneCall = false;
    if (countError > 0) {
      response.titleMsg =
        '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
      response.contentMsg =
        'El cliente presenta una cuenta en Banco Popular en estado embargado.';
      response.displayButton = false;
      response.displayButtonFile = false;
    } else {
      if (response.loanRenewValidation === 'NOT_APPROVE_BUSINESS_RULES') {
        response.titleMsg = '¡Haz seguimiento a tu crédito!';
        response.contentMsg =
          'Nos gusta ayudarte y que conozcas el estado de tu libranza. Puedes volver aquí para ver el comportamiento de tu crédito mes a mes.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation === 'NOT_APPROVE_MAX_AGE_EXCEEDED'
      ) {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'El cliente tiene una edad superior a la máxima definida para otorgamiento de libranza.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation === 'NOT_APPROVE_BURO_DENEGATION'
      ) {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'El cliente no es viable de tramitar por este canal porque presenta un reporte por hábitos de pago en centrales.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation === 'NOT_APPROVE_PAYER_NOT_SOPPORTED_ADL'
      ) {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'La Pagaduria no está incluida en el modelo digital, tramitar por el modelo tradicional.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation ===
        'NOT_APPROVE_MODALITY_OR_SEGMENT_NOT_SOPPORTED_ADL'
      ) {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'No contamos con suficiente información para procesar la solicitud por el modelo digital, tramitar por el modelo tradicional.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation === 'NOT_APPROVE_OTHER_LOAN_IN_PROCESS'
      ) {
        response.titleMsg = 'Tu Libranza ya está en trámite';
        response.contentMsg =
          'Actualmente tienes una libranza aprobada y su tramite para el desembolso puede tardar hasta 24 horas.';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (
        response.loanRenewValidation === 'NOT_APPROVE_EXTERNAL_VIABILITY'
      ) {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'El cliente presenta reporte de hábito de pago con otra entidad';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (response.loanRenewValidation === 'INVALID_PAYMENT_TYPE') {
        response.titleMsg =
          '¡Lo sentimos, no es posible atender tu solicitud por este canal!';
        response.contentMsg =
          'No es sujeto de ampliación, no ha recibido dos pagos por nómina';
        response.displayButton = false;
        response.displayButtonFile = false;
      } else if (response.loanRenewValidation === 'PAYERROLL_PLATFORM_ACTIVE') {
        response.titleMsg = response.customerInBureauTrail
          ? '¡Tienes una oferta especial!'
          : '¡Amplía tu crédito!';
        response.contentMsg =
          'Vamos a verificar tu disponible en tu plataforma de pago.';
        response.displayButton = false;
        response.displayButtonFile = false;
        response.displayButtonPlatform = true;
      } else if (
        response.loanRenewValidation ===
          'LOAN_NOT_GENERATE_TEN_PERCENTAGE_GROWTH' ||
        this.creditFlowService.getCreditFlowType() === CreditFlowType.ANYFEE
      ) {
        if (
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
        ) {
          this.showButtonSendLinkForUploadPayrollCheck(response);

          if (response.customerInBureauTrail) {
            response.titleMsg = '¡Tienes una oferta especial!';
          }
        } else {
          this.uploadPayrollCheckButton(response, false);
        }
      } else {
        this.showButtonSpecialOffer(response);

        if (!response.almostPaidOff && !response.customerInBureauTrail) {
          response.titleMsg = '¡Amplía tu crédito!';
        }
      }
    }
  }

  private searchLoansValidateError() {
    this.loadingEmit.emit({ loading: false });

    if (this.numberErrorShowInformation > 3) {
      this.errorShowInformationEmit.emit({ type: 'V' });
    } else {
      this.numberErrorShowInformation++;

      setTimeout(() => {
        this.searchLoansValidate();
      }, 4000);
    }
  }

  showPepComponent(tabInfoPayer: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Ofertas Libranzas',
      'Aceptar ver la oferta',
      '	Muestrame el Aprobado',
      Date.now()
    );

    const preselect = this.preselectService.getPreselect();
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      tabInfoPayer.originalPayerName,
      preselect.obligationInitialValue + '',
      preselect.obligationBalance + '',
      preselect.feeAmount + '',
      preselect.loanQuota + '',
      preselect.missingLoanQuotas + ''
    );

    this.preselectService.setPreselectInfoPayer(
      preselect,
      tabInfoPayer,
      this.userService.authMethod,
      this.userService.responseAuthMethod
    );
    this.payrollCheckService.selectWorkflow(CreditFlowType.SAMEFEE, this.user);

    this.auditService
      .saveTransactionalAuditEvent({
        documentType: this.user.documentType,
        documentNumber: this.user.documentId + '',
        acceptanceText: undefined,
        acceptance: undefined,
        officeId: this.user.officeCode,
        description: 'Payer name: ' + tabInfoPayer.originalPayerName,
        eventCode: EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
        businessAdvisorNumber: this.user.sellerIdNumber.toString(),
      })
      .subscribe(
        () => {},
        (error) => this.saveTransactionalAuditEventError(error)
      );

    this.creditFlowService.setCreditFlowType(CreditFlowType.SAMEFEE);
    this.router.navigate(['/master-page/peps']);
  }

  showPepComponentFromPlatform(tabInfoPayer: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Ofertas Libranzas',
      'Aceptar ver la oferta',
      '	Muestrame el Aprobado plataforma',
      Date.now()
    );

    const preselect = this.preselectService.getPreselect();

    this.preselectService.setPreselectInfoPayer(
      preselect,
      tabInfoPayer,
      this.userService.authMethod,
      this.userService.responseAuthMethod
    );
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      tabInfoPayer.originalPayerName
    );

    this.auditService
      .saveTransactionalAuditEvent({
        documentType: this.user.documentType,
        documentNumber: this.user.documentId + '',
        acceptanceText: undefined,
        acceptance: undefined,
        officeId: this.user.officeCode,
        description: 'Payer name: ' + tabInfoPayer.originalPayerName,
        eventCode: EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
        businessAdvisorNumber: this.user.sellerIdNumber.toString(),
      })
      .subscribe(
        () => {},
        (error) => this.saveTransactionalAuditEventError(error)
      );

    this.creditFlowService.setCreditFlowType(CreditFlowType.ANYFEE);
    this.router.navigate(['/master-page/peps']);
  }

  private saveTransactionalAuditEventError(error) {
    console.log(
      'SalaryLoansOverviewValidationComponent - saveTransactionalAuditEventError, Error: ' +
        JSON.stringify(error)
    );
  }

  checkAvailable(tabInfoPayer: any) {
    let platformBindingsRequest: PlatformBindingsRequestInterface;

    platformBindingsRequest = {
      payerName: tabInfoPayer.payerUniqueName,
      documentNumber: this.user.documentId + '',
      documentType: this.user.documentType,
    };

    this.payrollCheckService.selectWorkflow(CreditFlowType.ANYFEE, this.user);

    this.loadingEmit.emit({ loading: true });
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      tabInfoPayer.originalPayerName
    );

    this.customerService.getPlatformBindings(platformBindingsRequest).subscribe(
      (bindings: PlatformBindingInterface[]) =>
        this.getPlatformBindingsSuccess(bindings, tabInfoPayer),
      (error) => this.getPlatformBindingsError(error, tabInfoPayer)
    );
  }

  private getPlatformBindingsSuccess(
    bindings: PlatformBindingInterface[],
    tabInfoPayer: any
  ) {
    let platformOfferRequest: PlatformOfferRequestInterface;
    platformOfferRequest = {
      payerName: tabInfoPayer.payerUniqueName,
      documentNumber: this.user.documentId + '',
      documentType: this.user.documentType,
      obligationId: tabInfoPayer.obligationId,
    };

    if (bindings.length > 1) {
      const modal = this.commonService.showMesssageBox(
        PlatformBindingsComponent,
        false,
        {
          bindings: bindings,
          user: this.user,
          payerName: tabInfoPayer.payerUniqueName,
          platformOfferRequest: platformOfferRequest,
          tabInfoPayer: tabInfoPayer,
        },
        500
      );

      modal.afterClosed().subscribe((result) => {
        if (result.success) {
          this.showPepComponentFromPlatform(tabInfoPayer);
        } else {
          this.generatePlatformOfferError(
            result.error,
            tabInfoPayer,
            bindings.length
          );
        }
      });
    } else {
      platformOfferRequest.bindingNumber = bindings[0].bindingNumber;
      this.customerService
        .generatePlatformOffer(platformOfferRequest)
        .subscribe(
          (responsePlatformOffer) =>
            this.generatePlatformOfferSucces(
              responsePlatformOffer,
              tabInfoPayer
            ),
          (error) =>
            this.generatePlatformOfferError(
              error,
              tabInfoPayer,
              bindings.length
            )
        );
    }
  }

  private getPlatformBindingsError(error: any, tabInfoPayer: any) {
    this.loadingEmit.emit({ loading: false });

    const message =
      this.commonService.getMessageFromErrorCodePayerPlatformBindings(
        error.error.code,
        this.tabsSlide.length
      );
    this.commonService.showMesssageBox(
      PopUpPepComponent,
      false,
      {
        icon: message.icon,
        title: message.title,
        message: message.message,
        url: message.url,
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
        textButtom: message.textButtom,
        externalUrl:
          tabInfoPayer.platformAuthorizationUrl !== null
            ? tabInfoPayer.platformAuthorizationUrl
            : '',
      },
      500
    );
  }

  private generatePlatformOfferSucces(response: any, tabInfoPayer: any) {
    this.user.transactionId = response.id;
    this.userService.setUser(this.user);
    this.showPepComponentFromPlatform(tabInfoPayer);
  }

  private generatePlatformOfferError(
    error: any,
    tabInfoPayer: any,
    bindingsCount: number
  ) {
    this.loadingEmit.emit({ loading: false });

    if (error.error.code === 'PayerPlatform06') {
      this.showPepComponentFromPlatform(tabInfoPayer);
    } else {
      const message = this.commonService.getMessageFromErrorCodePayerPlatform(
        error.error.code === 'PayerPlatform09'
          ? bindingsCount > 1
            ? 'PayerPlatform09'
            : 'PayerPlatform10'
          : error.error.code
      );
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        false,
        {
          icon: message.icon,
          title: message.title,
          message: message.message,
          url: message.url,
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
          textButtom: message.textButtom,
          externalUrl:
            error.error.code === 'PayerPlatform05'
              ? tabInfoPayer.platformAuthorizationUrl
              : '',
        },
        500
      );
    }
  }

  stateTab(obligationId: string, classCss: string) {
    const msg = document.getElementById('msg' + obligationId);
    if (msg !== null) {
      msg.className = classCss;
    }
  }

  private uploadPayrollCheckButton(response: any, tenPercentError: boolean) {
    if (tenPercentError) {
      response.titleMsg = 'No podemos ampliar tu crédito';
      response.contentMsg =
        'Si deseas hacerlo, necesitas actualizar tu desprendible de pago.';
    } else {
      response.titleMsg =
        response.almostPaidOff || response.customerInBureauTrail
          ? '¡Tienes una oferta especial!'
          : 'Amplía tu crédito';
      response.contentMsg =
        'Si deseas hacerlo, necesitas actualizar tu desprendible de pago.';
    }

    response.displayButton = false;
    response.displayButtonFile = true;
    response.displayButtonPlatform = false;
  }

  private showButtonSpecialOffer(response: any) {
    response.titleMsg = '¡Tienes una oferta especial!';
    response.contentMsg =
      'Escoge las condiciones, con tu cuota actual y amplia tu crédito con ' +
      response.payerUniqueName +
      ' sin papeleo ¿te interesa?';
    response.displayButton = true;
    response.displayButtonFile = false;
  }

  private showButtonSendLinkForUploadPayrollCheck(response: any) {
    response.titleMsg = '¡Antes de continuar!';
    response.contentMsg =
      'El cliente debe subir su desprendible de pago. Le enviaremos un enlace a su email y celular registrados dónde podrá cargarlo.';
    response.displayButtonPhoneCall = true;
    response.displayButton = false;
    response.displayButtonFile = false;
    response.displayButtonPlatform = false;
  }

  sendLinkForUploadFile(tabInfoPayer: any) {
    let sendLinkUploadFileRequest: SendLinkUploadFileRequestInterface;

    sendLinkUploadFileRequest = {
      documentNumber: this.user.documentId + '',
      documentType: this.user.documentType,
      obligationId: tabInfoPayer.obligationId,
    };

    this.payrollCheckService.selectWorkflow(
      CreditFlowType.CELLPHONE,
      this.user
    );

    this.loadingEmit.emit({ loading: true });
    this.eventAnalyticsService.sendEventAnalytics(
      EventCodeEnum.SELECTED_SALARY_LOAN_PAYER_EXTENSION_STEP_ID,
      tabInfoPayer.originalPayerName
    );

    this.customerService
      .sendLinkForUploadFile(sendLinkUploadFileRequest)
      .subscribe(
        () => this.sendLinkForUploadFileSuccess(tabInfoPayer),
        (error) => this.sendLinkForUploadFileError(error, tabInfoPayer)
      );
  }

  private sendLinkForUploadFileSuccess(tabInfoPayer: any) {
    this.loadingEmit.emit({ loading: false });
    const dialog = this.commonService.showMesssageBox(
      PopUpPepComponent,
      false,
      {
        icon: 'I',
        title: 'Cargue del desprendible',
        message:
          'Podrás continuar con el proceso cuando tu cliente haya cargado su desprendible. Te recomendamos estar contactándolo/la.',
        url: '',
        feedback: false,
        routerDestination: '',
        stepId: 0,
        stepDescription: '',
        textButtom: 'Entendido',
      },
      500
    );
    dialog.afterClosed().subscribe(() => {
      this.loadingEmit.emit({ loading: true });
      this.payrollCheckService
        .waitForPayrollCheck(tabInfoPayer, this.user)
        .subscribe(
          () => {},
          () => {},
          () => {
            this.loadingEmit.emit({ loading: false });
          }
        );
    });
  }

  private sendLinkForUploadFileError(error: any, tabInfoPayer: any) {
    this.loadingEmit.emit({ loading: false });
    if (error.error.code === 'PayrollCheckUpload002') {
      const dialog = this.commonService.showMesssageBox(
        PopUpPepComponent,
        false,
        {
          icon: 'I',
          title: 'El cliente tiene un desprendible cargado',
          message:
            'Hemos encontrado un desprendible previamente cargado y lo utilizaremos para continuar el proceso',
          url: '',
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
          textButtom: 'Continuar',
        },
        500
      );
      dialog
        .afterClosed()
        .subscribe(() =>
          this.payrollCheckService.uploadFile(tabInfoPayer, this.user)
        );
    } else {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        false,
        {
          icon: 'E',
          title: 'Algo no salió bien...',
          message:
            'En este momento tenemos inconvenientes técnicos. Pronto estaremos funcionando. Por favor, vuelve en un momento',
          url: '',
          feedback: false,
          routerDestination: '',
          stepId: 0,
          stepDescription: '',
          textButtom: 'Volver a intentar',
        },
        500
      );
    }
  }
}
