import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CustomerFeedbackComponent } from '../../shared/components/customer-feedback/customer-feedback.component';

import { EventCodeEnum } from '../../shared/enums/event-code.enum';

import { User } from '../../shared/interfaces/user.interface';

import { AuditService } from '../../shared/services/remote/audit.service';

import { CognitoService } from '../../shared/services/local/cognito.service';
import { CommonService } from '../../shared/services/local/common.service';
import { MasterPageService } from '../../shared/services/local/master-page.service';
import { StepperService } from '../../shared/services/local/stepper.service';
import { UserService } from '../../shared/services/local/user.service';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css'],
})
export class MasterPageComponent implements OnInit {
  user: User;
  title: string;

  constructor(
    private auditService: AuditService,
    private cognitoService: CognitoService,
    private commonService: CommonService,
    private masterPageService: MasterPageService,
    private router: Router,
    private stepperService: StepperService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
  }

  ngOnInit() {
    this.title = this.masterPageService.getTitle();
    this.getCognitoLegacy();
  }

  redirect(site: number, menu: any) {
    menu.toggle();

    switch (site) {
      case 1:
        this.masterPageService.setTitle('Tus libranzas');
        this.title = this.masterPageService.getTitle();
        this.router.navigate(['master-page/salary-loans-overview']);

        break;
      case 2:
        this.masterPageService.setTitle('Tus datos');
        this.title = this.masterPageService.getTitle();
        this.router.navigate(['master-page/data-client-master']);

        break;
      case 3:
        if (!this.user.feedbackEnd) {
          this.commonService.showMesssageBox(
            CustomerFeedbackComponent,
            true,
            {
              clear: true,
              stepId: 3,
              stepDescription: 'Menu',
            },
            500
          );

          this.auditService
            .saveTransactionalAuditEvent({
              documentType: this.user.documentType,
              documentNumber: this.user.documentId + '',
              acceptanceText: undefined,
              acceptance: undefined,
              officeId: this.user.officeCode,
              description: this.stepperService.getSteps().toString(),
              eventCode: EventCodeEnum.CLOSE_SESSION_BY_USER_STEP_ID,
              businessAdvisorNumber: this.user.sellerIdNumber.toString(),
            })
            .subscribe(
              () => {},
              (error) => this.saveTransactionalAuditEventError(error)
            );
        } else {
          this.userService.clearUser();
          this.router.navigate(['/welcome']);
        }

        break;
    }
  }

  private getCognitoLegacy() {
    this.cognitoService.getCognitoCredentialsLegacy().subscribe((r: any) => {
      if (r != null && r.access_token) {
        this.cognitoService.setTokenLegacy(r);
      }
    });
  }

  private saveTransactionalAuditEventError(error: any) {
    console.log(
      'MasterPageComponent - saveTransactionalAuditEventError, Error: ' +
        JSON.stringify(error)
    );
  }
}
