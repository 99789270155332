import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable()
export class SecurityRemoteService {

  constructor() {}

  closeFederateAuthentication(clientId: string) {
    window.location.assign(`${environment.federatedAuthUrl}/logout?client_id=${clientId}&logout_uri=${environment.federatedAuthLogoutUrl}`);
  }
}
