// Imports
import { Injectable } from '@angular/core';

@Injectable()
export class TabsService {
  private tabs: any;

  constructor() {
  }

  public setTabs(tabs: any) {
    this.tabs = tabs;
  }

  public getTabs() {
    return this.tabs;
  }
}
