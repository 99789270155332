import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumeric } from 'rxjs/util/isNumeric';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextDocumentComponent),
      multi: true,
    },
  ],
  selector: 'app-text-document',
  templateUrl: './text-document.component.html',
  styleUrls: ['./text-document.component.css'],
})
export class TextDocumentComponent implements ControlValueAccessor, OnInit {
  @ViewChild('txtDocument', { static: false }) txtDocument: any;
  @Input() textDisabled: boolean;
  @Output() eventFocus: EventEmitter<null> = new EventEmitter();
  @Output() eventBlur: EventEmitter<null> = new EventEmitter();
  @Output() eventKeyUpEnter: EventEmitter<null> = new EventEmitter();

  private _documentId: number;
  @Input()
  set documentId(documentId: number) {
    this._documentId = documentId;
    this.propagateChange(this._documentId);
  }
  get documentId(): number {
    return this._documentId;
  }

  propagateChange = (_: any) => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  keyUp() {
    if (!isNumeric(this.txtDocument.nativeElement.value.replace(/ /g, ''))) {
      this.txtDocument.nativeElement.value = '';
    }
  }

  textFocus() {
    this.eventFocus.emit();
  }

  textBlur() {
    this.eventBlur.emit();
  }

  textKeyUpEnter(param?: number) {
    this.eventKeyUpEnter.emit();
  }

  writeValue(value: any) {
    this.documentId = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  blur() {
    const txt = this.txtDocument.nativeElement;
    txt.blur();
  }
}
