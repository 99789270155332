// Imports
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';

// Interfaces
import { User } from '../../../shared/interfaces/user.interface';

// Enums
import { AdvisorType } from '../../../shared/enums/advisor-type.enum';
import { AuthenticationType } from '../../../shared/enums/authentication-type.enum';

// Declarations
import { DecrimComponent } from '../../../shared/components/decrim/decrim.component';
import { PopUpPepComponent } from '../../../shared/components/pop-up/pop-up-pep.component';

// Services Local
import { CommonService } from '../../../shared/services/local/common.service';
import { MasterPageService } from '../../../shared/services/local/master-page.service';
import { StepperService } from '../../../shared/services/local/stepper.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';

// Services Remote
import { AuthenticationService } from '../../../shared/services/remote/authentication.service';
import { CustomerService } from '../../../shared/services/remote/customer.service';
import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';

@Component({
  selector: 'app-opt',
  templateUrl: './opt.component.html',
  styleUrls: ['./opt.component.css'],
})
export class OptComponent implements OnInit {
  @ViewChild('focPassword1', { static: false }) focus1: ElementRef;
  @ViewChild('focPassword2', { static: false }) focus2: ElementRef;
  @ViewChild('focPassword3', { static: false }) focus3: ElementRef;
  @ViewChild('focPassword4', { static: false }) focus4: ElementRef;
  @ViewChild('focPassword5', { static: false }) focus5: ElementRef;
  @ViewChild('focPassword6', { static: false }) focus6: ElementRef;
  @ViewChild('inputPassword1', { static: false }) input1: ElementRef;
  @ViewChild('inputPassword2', { static: false }) input2: ElementRef;
  @ViewChild('inputPassword3', { static: false }) input3: ElementRef;
  @ViewChild('inputPassword4', { static: false }) input4: ElementRef;
  @ViewChild('inputPassword5', { static: false }) input5: ElementRef;
  @ViewChild('inputPassword6', { static: false }) input6: ElementRef;

  private dialogRef: any;

  user: User;
  callOtp: boolean;
  content: string;
  externalUser: any;
  hiddenMessage: any;
  loading: boolean;
  retryAttemps: number;
  retryMessage: any;
  showOutOfTimeMessage = false;
  title: string;
  validityTimeMessage: string;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private masterPageService: MasterPageService,
    private renderer2: Renderer2,
    private router: Router,
    private stepperService: StepperService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Ingreso página ingreso código Otp Ath',
      'Página otp',
      Date.now()
    );

    this.retryAttemps = 0;
    this.retryMessage = this.toggleService.getToggle('TWILIO_SMS_AND_CALL');
    this.retryMessage.featureEnabled = true;

    this.setMessages();
    this.stepperService.setStep('otp');

    this.getOtpByCallSuccess();
  }

  setMessages() {
    this.user = this.userService.getUser();
    this.title = '¡Bien ' + this.user.nameShort + '!';
    this.validityTimeMessage = 'El código tiene 1 minuto de validez';
    this.content =
      'Hemos enviado un código a tu celular ' + this.user.cellphone;
  }

  keyPress(event: any) {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      return false;
    }
  }

  keyUp(event: any, key: number) {
    if (key === 2) {
      if (
        this.renderer2.selectRootElement(this.input1.nativeElement).value ===
          '' ||
        isNaN(
          Number(
            this.renderer2.selectRootElement(this.input1.nativeElement).value
          )
        )
      ) {
        this.renderer2.selectRootElement(this.input1.nativeElement).value = '';
      } else {
        this.input1.nativeElement.disabled = true;
        this.input2.nativeElement.disabled = false;
        this.renderer2.selectRootElement(this.input2.nativeElement).focus();
      }
    } else if (key > 2) {
      if (event.keyCode === 8) {
        this['input' + (key - 2)].nativeElement.disabled = false;
        this['input' + (key - 1)].nativeElement.disabled = true;
        this.renderer2
          .selectRootElement(this['input' + (key - 2)].nativeElement)
          .focus();
        this.renderer2
          .selectRootElement(this['input' + (key - 2)].nativeElement)
          .select();
      } else if (
        this.renderer2.selectRootElement(
          this['input' + (key - 1)].nativeElement
        ).value === '' ||
        isNaN(
          Number(
            this.renderer2.selectRootElement(
              this['input' + (key - 1)].nativeElement
            ).value
          )
        )
      ) {
        this.renderer2.selectRootElement(
          this['input' + (key - 1)].nativeElement
        ).value = '';
      } else {
        this['input' + (key - 1)].nativeElement.disabled = true;
        this['input' + key].nativeElement.disabled = false;
        this.renderer2
          .selectRootElement(this['input' + key].nativeElement)
          .focus();
      }
    }
  }

  focusBlurText(key: number, display: boolean) {
    this.renderer2.removeClass(
      this['focus' + key].nativeElement,
      display ? 'd-none' : 'd-block'
    );
    this.renderer2.addClass(
      this['focus' + key].nativeElement,
      display ? 'd-block' : 'd-none'
    );
  }

  sendOtp(event: any) {
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Ingresar Código Otp Ath',
      'Último dígito código',
      Date.now()
    );

    if (event.keyCode === 8) {
      this.input5.nativeElement.disabled = false;
      this.input6.nativeElement.disabled = true;
      this.renderer2.selectRootElement(this.input5.nativeElement).focus();
      this.renderer2.selectRootElement(this.input5.nativeElement).select();
    } else if (
      this.renderer2.selectRootElement(this.input6.nativeElement).value ===
        '' ||
      isNaN(
        Number(
          this.renderer2.selectRootElement(this.input6.nativeElement).value
        )
      )
    ) {
      this.renderer2.selectRootElement(this.input6.nativeElement).value = '';
    } else {
      this.input6.nativeElement.disabled = true;
      this.loading = true;

      let otpValue = '';
      for (let i = 1; i < 7; i++) {
        otpValue += this['input' + i].nativeElement.value;
      }

      this.setOtpAth(otpValue);
    }
  }

  private setOtpAth(otpValue: string) {
    this.authenticationService.otpAuthentication(
      this.user,
      {
        documentType: this.userService.getUser().documentType,
        documentNumber: this.userService.getUser().documentId.toString(),
        otpValue: otpValue,
      },
      {
        pageInstance: this,
        success: function () {
          this.pageInstance.setCodeOtpSuccess();
        },
        error: function (error: any) {
          this.pageInstance.setCodeOtpError(error);
        },
      }
    );
  }

  private setCodeOtpSuccess() {
    this.validityTimeMessage = 'El código es correcto';

    if (this.user.advisorType === AdvisorType.EXTERNAL_SALES_FORCE) {
      this.loading = false;
      this.dialogRef = this.commonService.showMesssageBox(
        DecrimComponent,
        true,
        {
          title: 'Fotos de la cédula, firma y huella',
          hiddenButtonClose: false,
        },
        500
      );

      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.goToSalaryLoans();
        }
      });
    } else {
      this.goToSalaryLoans();
    }
  }

  private goToSalaryLoans() {
    this.loading = true;
    this.user.authenticationType = AuthenticationType.OTP;

    setTimeout(() => {
      this.masterPageService.setTitle('Tus libranzas');
      this.router.navigate(['/master-page/salary-loans-overview']);
    }, 2000);
  }

  private setCodeOtpError(error: any) {
    this.loading = false;
    this.retryAttemps++;

    if (this.retryAttemps === 3) {
      this.commonService.showMesssageBox(
        PopUpPepComponent,
        true,
        {
          icon: 'E',
          title: 'Lo sentimos',
          message:
            'Has agotado tus intentos para ingresar.... Te recomendamos acercarte a una oficina o a una asesora para validar tus datos',
          url: '',
          textButtom: 'Finalizar',
          clearUser: false,
          feedback: true,
          routerDestination: '/welcome',
          stepId: 2,
          stepDescription: 'Authentication Otp',
        },
        500
      );

      this.googleAnalyticsEventsService.emitEvent(
        'Autenticación Cliente Otp',
        'Termina por intentos fallidos Otp Ath',
        'Último dígito código',
        Date.now()
      );
    } else {
      if (error.code === 'AthManagement0002') {
        this.googleAnalyticsEventsService.emitEvent(
          'Autenticación Cliente Otp',
          'Código incorrecto',
          'Último dígito código',
          Date.now()
        );
        this.validityTimeMessage = 'Código incorrecto';
        this.input6.nativeElement.disabled = true;

        this.focusInit();
      } else if (error.code === 'AthManagement0003') {
        this.googleAnalyticsEventsService.emitEvent(
          'Autenticación Cliente Otp',
          'El código ha expirado',
          'Último dígito código',
          Date.now()
        );
        this.validityTimeMessage = 'El código ha expirado';
        this.showOutOfTimeMessage = true;
      } else {
        this.googleAnalyticsEventsService.emitEvent(
          'Autenticación Cliente Otp',
          'Problemas con la conexión',
          'Último dígito código',
          Date.now()
        );
        this.validityTimeMessage = 'Ocurrió algo, intente más tarde';
        this.input6.nativeElement.disabled = true;

        this.focusInit();
      }
    }
  }

  sendNewOtpCode() {
    this.googleAnalyticsEventsService.emitEvent(
      'Autenticación Cliente Otp',
      'Generar Nuevo Código',
      'Botón enviar un código nuevo',
      Date.now()
    );

    if (
      this.toggleService.getToggle('ADL_TEAM_CLIENT_VALIDATION').featureEnabled
    ) {
      this.customerService
        .validateCustomerCellPhone(this.userService.getUserInfo())
        .subscribe((result) => {
          this.user.simPendingAuthentication =
            result['status'] === 'AUTHENTICATION_SIM_PENDING';
        });
    }

    this.authenticationService.getCodeOtp(this.user).subscribe(
      () => this.getCodeOtpSuccess(),
      (error: any) => this.getCodeOtpError(error)
    );
  }

  private getCodeOtpSuccess() {
    this.showOutOfTimeMessage = false;
    this.validityTimeMessage = 'El código tiene 1 minuto de validez';
    this.retryAttemps = 0;

    setTimeout(() => {
      this.focusInit();
    }, 500);
  }

  callToSendOtp() {
    this.callOtp = true;
    this.loading = true;
    this.retryMessage.featureEnabled = false;

    this.hiddenMessage = setTimeout(() => {
      this.callOtp = false;
      this.retryMessage.featureEnabled = true;
      clearTimeout(this.hiddenMessage);
    }, 30000);

    this.authenticationService.getOtpCodeByCall(this.user).subscribe(
      () => this.getOtpByCallSuccess(),
      (error: any) => this.getCodeOtpError(error)
    );
  }

  private getOtpByCallSuccess() {
    this.loading = false;

    setTimeout(() => {
      this.focusInit();
    }, 800);
  }

  private getCodeOtpError(error: any) {
    console.log(
      'OptComponent - getCodeOtpError, Error: ' + JSON.stringify(error)
    );
  }

  private focusInit() {
    this.input1.nativeElement.disabled = false;
    this.renderer2.selectRootElement(this.input1.nativeElement).focus();

    for (let i = 1; i < 7; i++) {
      this['input' + i].nativeElement.value = '';
    }
  }
}
