import { mergeMap } from 'rxjs/operators';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomerService } from '../../services/remote/customer.service';
import { Router } from '@angular/router';

import { ChannelType } from '../../enums/channel-type.enum';
import { CreditFlowType } from '../../enums/credit-flow-type.enum';
import { CustomerDecisionEnum } from '../../enums/customer-decision-type.enum';
import { CustomerTypeEnum } from '../../enums/customer-type.enum';
import { DecisionEnum } from '../../enums/decision-type.enum';
import { EventCodeEnum } from '../../enums/event-code.enum';
import { LoadingTypeEnum } from '../../enums/loading-type.enum';
import { ModalityType } from '../../enums/modality-type.enum';
import { RedirectNewFrontTypeEnum } from '../../enums/redirect-new-front-type.enum';
import { UploadFileButton } from '../../enums/upload-file-button.enum';
import { UploadsFileOpenType } from '../../enums/upload-file-open-type.enum';

import { IUploadFileRequest } from '../../interfaces/upload-file-request.interface';
import { Preselect } from '../../interfaces/preselect.interface';
import { User } from '../../interfaces/user.interface';

import { CommonService } from '../../services/local/common.service';
import { CreditFlowService } from '../../services/local/credit-flow.service';
import { MasterPageService } from '../../services/local/master-page.service';
import { PreselectService } from '../../services/local/preselect.service';
import { ToggleService } from '../../services/local/toggle.service';
import { UserService } from '../../services/local/user.service';

import { AuditService } from '../../services/remote/audit.service';
import { FileManagerService } from '../../services/remote/file-manager.service';

import { CastFileToBase64 } from './cast-file-to-base64';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent
  implements AfterViewInit, AfterViewChecked, OnInit
{
  @ViewChild('appLoading', { static: false }) appLoading: any;

  user: User;
  preselect: Preselect;
  buttonType: string;
  castFileToBase64: CastFileToBase64;

  attemptErrorNewLoan = 4;
  icon: string;
  files: any;
  fileName: string;
  loading: boolean;
  queryParamsCustomerNew: string;
  hiddenFile: boolean;
  hiddenButtonClose: boolean;
  errorMessage: string;
  verifyTapSolution: any;
  timeLimitRetry: number;
  useFootprintsNewServiceToggle: boolean;

  private timeInitUploadTap = 0;
  private timeRetryUploadTap = 60000;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFileComponent>,
    private auditService: AuditService,
    private changeDetector: ChangeDetectorRef,
    private commonService: CommonService,
    private creditFlowService: CreditFlowService,
    private customerService: CustomerService,
    private fileManagerService: FileManagerService,
    private masterPageService: MasterPageService,
    private preselectService: PreselectService,
    private router: Router,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.user = this.userService.getUser();
    this.preselect = this.preselectService.getPreselect();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.checkPayrollCheckInProgress();
  }

  ngOnInit() {
    this.fileName = 'No se eligio archivo';
    this.castFileToBase64 = new CastFileToBase64();

    this.loading = true;
    this.timeLimitRetry = 0;

    this.useFootprintsNewServiceToggle = this.toggleService.getToggle(
      'USE_FOOTPRINTS_NEW_SERVICE_TOGGLE'
    ).featureEnabled;
  }

  fileChange(event: any) {
    this.files = event.target.files;
    this.fileName = this.files[0].name;
    this.errorMessage = '';

    if (
      this.files[0].type === 'application/pdf' &&
      this.files[0].size > 400000
    ) {
      this.files = undefined;
      this.fileName = 'No se eligio archivo';
      this.errorMessage = 'El tamaño del archivo no puede superar los 400KB';
    } else if (
      this.files[0].type === 'image/jpeg' &&
      this.files[0].size > 10000000
    ) {
      this.files = undefined;
      this.fileName = 'No se eligio archivo';
      this.errorMessage = 'El tamaño de la imagen no puede superar las 10MB';
    }
  }

  goSalaryLoan() {
    this.dialogRef.close();
    this.masterPageService.setTitle('Tus libranzas');
    this.router.navigate(['/master-page/salary-loans-overview']);
  }

  goToNewLoanPage() {
    this.dialogRef.close();
    this.userService.goToDecisionPage(RedirectNewFrontTypeEnum.NEWLOAN);
  }

  continue() {
    this.dialogRef.close();
    this.router.navigate(['/master-page/peps']);
  }

  close() {
    this.userService.isCustomerNewPayrollLoan() ||
    this.userService.isPhoneSaleIsNewLoan()
      ? this.goToNewLoanPage()
      : this.dialogRef.close();
  }

  upload() {
    this.loading = true;
    this.appLoading.title = 'Enviando documentos, un minuto por favor';
    this.appLoading.subtitle = this.commonService.getMessageLoadingCustomer();
    this.appLoading.changeSpinner(LoadingTypeEnum.PIXEL_SPINNER_SHOW);

    const file = this.files[0];
    const fileExtension = this.fileManagerService.getFileExtension(file);
    const fileName = `${this.preselect.obligationId}_${this.user.documentType}_${this.user.documentId}.${fileExtension}`;

    this.castFileToBase64
      .castFile(file)
      .pipe(
        mergeMap((data) =>
          this.fileManagerService.uploadFile(
            fileName,
            fileExtension,
            data,
            ChannelType.PAYROLLCHECK
          )
        ),
        mergeMap(() =>
          this.customerService.setFile(this.getUploadFileRequest(fileName))
        )
      )
      .subscribe(
        (response) => this.uploadSuccess(response),
        (error) => this.uploadError(error)
      );
  }

  private uploadSuccess(response: any) {
    if (response.status === '1') {
      this.timeLimitRetry = 0;
      this.uploadTapSolution(response.id, false, this.timeInitUploadTap);
    } else {
      this.uploadTapSolutionError(response);
    }
  }

  private uploadTapSolution(
    id: string,
    everisProcess: boolean,
    timeRetry: number
  ) {
    if (this.timeLimitRetry >= 300000) {
      this.auditService
        .saveTransactionalAuditEvent({
          documentType: this.user.documentType,
          documentNumber: this.user.documentId + '',
          acceptanceText: undefined,
          acceptance: undefined,
          officeId: this.user.officeCode,
          description: 'Time Limit Retry Upload TapSolution',
          eventCode: EventCodeEnum.PAYROLLCHECK_DIGITALIZATION_NOT_COMPLETED,
          businessAdvisorNumber: this.user.sellerIdNumber.toString(),
        })
        .subscribe();

      this.uploadTapSolutionError({
        error: {
          code: 'PayrollChecks014',
        },
      });
    } else {
      this.timeLimitRetry += 10000;
      this.appLoading.title =
        'El desprendible que cargaste está en proceso, por favor espera';
      this.appLoading.subtitle = everisProcess
        ? 'La información fue adecuadamente procesada - En este momento estamos calculando la oferta a realizar acorde con su capacidad de descuento'
        : this.commonService.getMessageLoadingCustomer();

      if (this.userService.isCustomerNewPayrollLoan()) {
        this.queryParamsCustomerNew = `?payer-nit=${this.preselect.payerNit}&sector-number=${this.preselect.sectorNumber}&client-type=${CustomerTypeEnum.NEW_KNOWN_CUSTOMER}`;
        this.creditFlowService.setModalityType(ModalityType.ORDINARY);
        this.user.creditFlowType = CreditFlowType.NEW_PAYROLL_LOAN;
      }

      this.verifyTapSolution = setTimeout(() => {
        this.customerService
          .getFileCustomer(
            id,
            this.preselect.obligationId,
            this.user,
            this.queryParamsCustomerNew
          )
          .subscribe(
            (response) => this.uploadTapSolutionSuccess(response),
            (error) => this.uploadTapSolutionError(error)
          );
      }, timeRetry);
    }
  }

  private uploadTapSolutionSuccess(response: any) {
    if (
      response.code === 'PayrollChecks005' ||
      response.code === 'PayrollChecks012'
    ) {
      this.uploadTapSolution(
        response.id,
        response.code === 'PayrollChecks012',
        this.timeRetryUploadTap
      );
    } else {
      this.user.transactionId = response.id;
      this.userService.setUser(this.user);
      this.creditFlowService.setCreditFlowType(CreditFlowType.ANYFEE);

      if (
        response.modalityType === ModalityType.LOYALTY &&
        this.useFootprintsNewServiceToggle
      ) {
        this.creditFlowService.setModalityType(ModalityType.LOYALTY);
      }

      const payerName =
        ' (' +
        (this.user.customerDecisionType ===
        CustomerDecisionEnum.NEW_PAYROLL_LOAN
          ? this.preselect.payerName
          : this.preselect.payerUniqueName) +
        ')';

      this.setConfigurationPopup(
        'O',
        'Desprendible fué procesado',
        `El desprendible de ${payerName} fué procesado de forma correcta`,
        this.data.type === UploadsFileOpenType.INSALARYLOANS ||
          this.data.type === UploadsFileOpenType.INSALARYLOANSNEW
          ? UploadFileButton.CONTINUE
          : UploadFileButton.CLOSE,
        true,
        this.data.type === UploadsFileOpenType.INSALARYLOANS ||
          this.data.type === UploadsFileOpenType.INSALARYLOANSNEW,
        ''
      );
    }
  }

  private uploadTapSolutionError(error: any) {
    const payerName =
      ' (' +
      (this.user.customerDecisionType === CustomerDecisionEnum.NEW_PAYROLL_LOAN
        ? this.preselect.payerName
        : this.preselect.payerUniqueName) +
      ')';

    switch (error.error.code) {
      case 'PayrollChecks001':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Error subiendo el archivo',
          `No es un desprendible de nómina, por favor carga de nuevo el archivo ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'PayrollChecks003':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Error subiendo el archivo',
          `Por favor carga de nuevo el archivo y envía de nuevo el desprendible ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'PayrollChecks004':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'I',
          'No pudimos leer el desprendible',
          `Por favor envíalo de nuevo, verifica que la foto o documento sea legible ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;

      case 'PayrollChecks007':
        this.setConfigurationPopup(
          'E',
          'Desprendible vencido',
          `Para tramitar tú libranza necesitamos un desprendible de pago actualizado ${payerName}`,
          this.validateTypeButton(UploadFileButton.CLOSE),
          true,
          false,
          ''
        );

        break;
      case 'PayrollChecks008':
        this.setConfigurationPopup(
          'E',
          'No tienes capacidad de pago',
          `Según tú desprendible de pago no podemos ofrecerte más crédito ${payerName}`,
          this.validateTypeButton(UploadFileButton.CLOSE),
          true,
          false,
          ''
        );

        break;
      case 'PayrollChecks009':
      case 'PayrollChecks019':
        this.setConfigurationPopup(
          'I',
          'Cliente no sujeto de libranza',
          `Su tipo de mesada, no es sujeta a oferta de libranzas ${payerName}`,
          this.validateTypeButton(UploadFileButton.CLOSE),
          true,
          false,
          ''
        );

        break;
      case 'PayrollChecks011':
        this.setConfigurationPopup(
          'A',
          'Revisa con tu asesor',
          `Los descuentos de nomina actualmente aplicados, no corresponde al valor del descuento pactado ${payerName}`,
          this.validateTypeButton(UploadFileButton.SALARYLOAN),
          true,
          true,
          ''
        );

        break;

      case 'PayrollChecks013':
        this.setConfigurationPopup(
          'E',
          'La información no pudo ser procesada',
          `Ocurrió una falla en el proceso de calculo de la oferta ${payerName}`,
          this.validateTypeButton(UploadFileButton.SALARYLOAN),
          true,
          true,
          ''
        );

        break;
      case 'PayrollChecks014':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Tiempo de carga agotado',
          'Lo sentimos, finaliza el tiempo de espera, el archivo no fue subido. Envía de nuevo el desprendible.' +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'GrowthAmount001':
        this.setConfigurationPopup(
          'E',
          'Libranza no sujeta a ampliación por este canal',
          `Cliente no cumple con la política de crecimiento definida para canal digital ${payerName}`,
          UploadFileButton.SALARYLOAN,
          true,
          true,
          ''
        );

        break;
      case 'GrowthAmount002':
        this.setConfigurationPopup(
          'E',
          'No podemos atender la solicitud',
          `Lo sentimos, el cliente no cuenta con el disponible necesario para continuar con la solicitud de ${payerName}`,
          UploadFileButton.NEWLOAN,
          true,
          true,
          ''
        );

        break;
      case 'PayrollChecks017':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Error subiendo el archivo',
          `Revisa la titularidad del desprendible ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'PayrollChecks018':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Verifique la información cargada del desprendible',
          `El desprendible cargado no corresponde a la pagaduría ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'PayrollChecks020':
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'I',
          'No pudimos leer el desprendible',
          `Por favor envíalo de nuevo, verifica que cuente con el número de afiliación o código de pensión ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          ''
        );

        break;
      case 'PayrollChecks021':
        this.setConfigurationPopup(
          'C',
          'No se puede generar esta libranza',
          `Tu cliente tiene actualmente un descuento activo con el Banco, revisa tu desprendible y cárgalo de nuevo.`,
          this.validateTypeButton(UploadFileButton.SALARYLOAN),
          true,
          true,
          ''
        );

        break;
      case 'PayrollSimulation001':
        this.setConfigurationPopup(
          'E',
          'La información no pudo ser procesada',
          `Comunicate a la línea de soporte de la aplicación ${payerName}`,
          this.validateTypeButton(UploadFileButton.SALARYLOAN),
          true,
          true,
          ''
        );

        break;
      case 'PayrollSimulation002':
        this.setConfigurationPopup(
          'A',
          'No es posible continuar con la ampliación del crédito',
          `No se tiene capacidad de descuento. Revise condiciones de oferta o saldo del crédito actual ${payerName}`,
          this.validateTypeButton(UploadFileButton.SALARYLOAN),
          true,
          true,
          ''
        );

        break;
      default:
        this.addAttemptErrorNewLoan();
        this.setConfigurationPopup(
          'E',
          'Error subiendo el archivo',
          `Por favor envía de nuevo el desprendible ${payerName}` +
            (this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
              ? ''
              : this.addTextAttemptErrorNewLoan()),
          this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
            ? UploadFileButton.NEWLOAN
            : UploadFileButton.UPLOAD,
          this.user.decisionType ===
            DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
          false,
          error.description !== undefined
            ? error.description
            : error.message !== undefined
            ? error.message
            : 'Ocurrió un error en uploadTapSolutionError'
        );
    }
  }

  private checkPayrollCheckInProgress() {
    let payrollCheckId = 'unknown';
    if (
      (this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL ||
        this.userService.isCustomerNewPayrollLoan()) &&
      this.user.customerPayrollCheckId
    ) {
      payrollCheckId = this.user.customerPayrollCheckId + '';
    }
    this.uploadTapSolution(payrollCheckId, false, this.timeInitUploadTap);
  }

  private setConfigurationPopup(
    icon: string,
    title: string,
    message: string,
    typeButton: UploadFileButton,
    hiddenFile: boolean,
    hiddenButtonClose: boolean,
    textError: string
  ) {
    if (this.verifyTapSolution !== undefined) {
      clearTimeout(this.verifyTapSolution);
    }

    if (icon !== '') {
      this.icon = icon;
    }

    if (title !== '') {
      this.data.title = title;
    }

    if (message !== '') {
      this.data.message = message;
    }

    if (textError !== '') {
      this.uploadError(textError);
    }

    this.hiddenButtonClose = hiddenButtonClose;
    this.buttonType = typeButton.toString();
    this.hiddenFile = hiddenFile;
    this.loading = false;
  }

  private uploadError(error: any) {
    this.addAttemptErrorNewLoan();
    this.setConfigurationPopup(
      'E',
      'Error cargando el archivo',
      'Por favor carga de nuevo el archivo y envía de nuevo el desprendible' +
        (this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
          ? ''
          : this.addTextAttemptErrorNewLoan()),
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL
        ? UploadFileButton.NEWLOAN
        : UploadFileButton.UPLOAD,
      this.user.decisionType === DecisionEnum.NEW_PAYROLL_LOAN_BY_PHONE_CALL,
      false,
      ''
    );

    console.log(
      'UploadFileComponent - uploadError, Error: ' + JSON.stringify(error)
    );
  }

  private addAttemptErrorNewLoan() {
    this.attemptErrorNewLoan--;

    if (this.data.type === UploadsFileOpenType.INSALARYLOANSNEW) {
      if (this.attemptErrorNewLoan === 0) {
        this.goToNewLoanPage();
      }
    }
  }

  private addTextAttemptErrorNewLoan() {
    return this.data.type === UploadsFileOpenType.INSALARYLOANSNEW
      ? ', tienes ' + this.attemptErrorNewLoan + ' intentos de 3'
      : '';
  }

  private getUploadFileRequest(fileName: string): IUploadFileRequest {
    const uploadFileRequest: IUploadFileRequest = {
      documentType: this.user.documentType,
      documentNumber: this.user.documentId.toString(),
      obligationId: this.preselect.obligationId,
      fileName: fileName,
    };

    if (this.userService.isCustomerNewPayrollLoan()) {
      uploadFileRequest.clientType = CustomerTypeEnum.NEW_KNOWN_CUSTOMER;
      uploadFileRequest.payerNit = this.preselect.payerNit;
      uploadFileRequest.sectorNumber = this.preselect.sectorNumber;
    }

    return uploadFileRequest;
  }

  private validateTypeButton(typeButton: UploadFileButton): UploadFileButton {
    return this.userService.isCustomerNewPayrollLoan()
      ? UploadFileButton.NEWLOAN
      : typeButton;
  }
}
