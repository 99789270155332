import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from 'util';

import { CreditFlowType } from '../../../shared/enums/credit-flow-type.enum';
import { ModalityType } from '../../../shared/enums/modality-type.enum';
import { ToggleEnum } from '../../../shared/enums/toggle-type.enum';

import { Preselect } from '../../../shared/interfaces/preselect.interface';
import { Simulation } from '../../../shared/interfaces/simulation.interface';
import { Toggle } from '../../../shared/interfaces/toggle.interface';
import { User } from '../../../shared/interfaces/user.interface';

import { CalculatorDetailComponent } from './calculator-detail/calculator-detail.component';

import { CreditFlowService } from '../../../shared/services/local/credit-flow.service';
import { PreselectService } from '../../../shared/services/local/preselect.service';
import { ToggleService } from '../../../shared/services/local/toggle.service';
import { UserService } from '../../../shared/services/local/user.service';

import { GoogleAnalyticsEventsService } from '../../../shared/services/remote/google-analytics-events.service';
import { OrganizationManagerService } from '../../../shared/services/remote/organization-manager.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css'],
})
export class CalculatorComponent implements OnInit {
  @ViewChild('txtFinancialEntitySimulation', { static: false })
  txtFinancialEntitySimulation: CalculatorDetailComponent;

  financialEntitySimulation: Simulation;
  initialSimulation: Simulation;
  loyaltyAnyFeeToggle: Toggle;
  loyaltySameFeeToggle: Toggle;
  myForm: FormGroup;
  preselect: Preselect;
  user: User;

  cognitoToken: string;
  disabledLoyalty = false;
  dismissOptions = [];
  dropdownSettings: any = {};
  financialEntities: any[];
  financialEntity: any;
  limitSelection = false;
  message = '';
  organizationToggleStatus: boolean;
  selectedItems = [];
  showFilter = false;
  useFootprintsNewServiceToggle: boolean;
  useFootprintsNewServiceToggleV2: boolean;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private creditFlowService: CreditFlowService,
    public dialogRef: MatDialogRef<CalculatorComponent>,
    private fb: FormBuilder,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private organizationManagerService: OrganizationManagerService,
    private preselectService: PreselectService,
    private toggleService: ToggleService,
    private userService: UserService
  ) {
    this.initialSimulation = data;
    this.financialEntitySimulation = null;

    this.preselect = preselectService.getPreselect();
    this.user = userService.getUser();

    this.setOrganizationToggle();
    this.cognitoToken = this.user.cognitoToken;

    organizationManagerService
      .searchBenchmarkingSalaryLoansLoyalty(
        this.organizationToggleStatus,
        this.cognitoToken
      )
      .subscribe(
        (res) => this.searchBenchmarkingSalaryLoansLoyaltySuccess(res),
        (error) => this.searchBenchmarkingSalaryLoansLoyaltyError(error)
      );
  }

  ngOnInit() {
    this.loyaltyAnyFeeToggle = this.toggleService.getToggle('LOYALTY_ANY_FEE');
    this.loyaltySameFeeToggle =
      this.toggleService.getToggle('LOYALTY_SAME_FEE');

    this.dismissOptions = [
      { item_id: 'monto', item_text: 'Monto' },
      { item_id: 'cuota', item_text: 'Cuota' },
      { item_id: 'plazo', item_text: 'Plazo' },
      { item_id: 'tasa', item_text: 'Tasa' },
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Limpiar',
      itemsShowLimit: 4,
      allowSearchFilter: this.showFilter,
    };

    this.myForm = this.fb.group({
      selectDismissOptions: [this.selectedItems],
    });

    this.setUseFootprintsNewServiceToggle();
    this.setUseFootprintsNewServiceToggleV2();
  }

  private searchBenchmarkingSalaryLoansLoyaltySuccess(data: any) {
    this.financialEntities = data;
    this.message =
      this.financialEntities.length === 0
        ? 'No se encontraron entidades oferentes'
        : '';
  }

  private searchBenchmarkingSalaryLoansLoyaltyError(error: any) {
    console.log(
      'CalculatorComponent - searchBenchmarkingSalaryLoansLoyalty, Error: ' +
        JSON.stringify(error)
    );

    this.message =
      'Ha ocurrido algo inesperado cargando las entidades oferentes';
  }

  disableBetterOffer() {
    return (
      this.disabledLoyalty ||
      !this.financialEntity ||
      this.myForm.controls.selectDismissOptions.value.length === 0
    );
  }

  disableImprovementOffer() {
    return (
      this.user.loyaltyAttempts <= 0 ||
      this.disabledLoyalty ||
      !this.financialEntity
    );
  }

  selectFinancialEntity() {
    this.financialEntitySimulation = null;

    if (this.financialEntity) {
      this._document.getElementById('amount').scrollIntoView(true);
      const financialEntity = this.financialEntities.filter((ent) => {
        return ent.companyName === this.financialEntity;
      })[0];

      this.financialEntitySimulation = {
        term: financialEntity === undefined ? 0 : financialEntity.maxTerm,
        interestRate:
          financialEntity === undefined ? 0 : financialEntity.minRate,
      };
    }
  }

  showLoyaltyButton() {
    let loyaltyEnabled = false;

    if (
      this.creditFlowService.getCreditFlowType() === CreditFlowType.ANYFEE &&
      this.loyaltyAnyFeeToggle.featureEnabled &&
      !this.useFootprintsNewServiceToggle &&
      this.creditFlowService.getModalityType() === ModalityType.ORDINARY
    ) {
      loyaltyEnabled = true;
    } else if (
      this.creditFlowService.getCreditFlowType() === CreditFlowType.SAMEFEE &&
      this.loyaltySameFeeToggle.featureEnabled &&
      !this.useFootprintsNewServiceToggle &&
      this.creditFlowService.getModalityType() === ModalityType.ORDINARY
    ) {
      loyaltyEnabled = true;
    }

    return loyaltyEnabled;
  }

  onItemSelect(item: any) {}

  onSelectAll(items: any) {}

  toogleshowFilter() {
    this.showFilter = !this.showFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: this.showFilter,
    });
  }

  handleLimitSelection() {
    this.dropdownSettings = this.limitSelection
      ? Object.assign({}, this.dropdownSettings, { limitSelection: 2 })
      : Object.assign({}, this.dropdownSettings, { limitSelection: null });
  }

  updateLoyaltyDisabled(simulationSuccess: boolean) {
    this.disabledLoyalty = !simulationSuccess;
  }

  saveLoyaltyOffered(flowType: string, loyalty: boolean) {
    this.googleAnalyticsEventsService.emitEvent(
      'Hagamos cuentas',
      flowType === 'loyalty'
        ? 'Da click botón mejorar oferta'
        : flowType === 'dismiss'
        ? 'Da click botón no me interesa tomar este crédito'
        : 'Da click botón continuar con la solicitud',
      flowType === 'loyalty'
        ? 'Botón mejorar oferta'
        : flowType === 'dismiss'
        ? 'Botón no me interesa tomar este crédito'
        : 'Botón continuar con la solicitud',
      Date.now()
    );
    const simulation = this.txtFinancialEntitySimulation.returnValuesOffered();

    this.organizationManagerService
      .setLoyaltyOffered(
        {
          idOffice: this.user.officeCode,
          idAdvisor: this.user.sellerIdNumber,
          documentUser: this.user.documentId,
          idGoogleAnalytics: 0,
          documentType: this.user.documentType,
          idObligation: this.preselect.obligationId,
          payerName: this.preselect.payerName,
          currentBalance: this.preselect.obligationBalance,
          initialValue: this.preselect.obligationInitialValue,
          agreedAmount: this.initialSimulation.agreedAmount,
          ourAmount: this.initialSimulation.amount,
          ourTerm: this.initialSimulation.term,
          ourQuote: this.initialSimulation.feeAmount,
          ourRate: this.initialSimulation.interestRate,
          ourFeePerMillion: this.initialSimulation.feeXMillion,
          ourTotalToPay: this.initialSimulation.totalToPay,
          companyName: isNullOrUndefined(this.financialEntity)
            ? 'N/A'
            : this.financialEntity,
          amountOffered: simulation.amount,
          termOffered: simulation.term,
          quoteOffered: simulation.feeAmount,
          rateOffered: simulation.interestRate,
          feePerMillionOffered: simulation.feeXMillion,
          totalToPayOffered: simulation.totalToPay,
          anotherReason: JSON.stringify(
            this.myForm.controls.selectDismissOptions.value
          ),
          reazonAmountOffered: this.searchReazon(
            'monto',
            this.myForm.controls.selectDismissOptions.value
          ),
          reazonTermOffered: this.searchReazon(
            'plazo',
            this.myForm.controls.selectDismissOptions.value
          ),
          reazonQuoteOffered: this.searchReazon(
            'cuota',
            this.myForm.controls.selectDismissOptions.value
          ),
          reazonRateOffered: this.searchReazon(
            'tasa',
            this.myForm.controls.selectDismissOptions.value
          ),
        },
        this.organizationToggleStatus,
        this.cognitoToken
      )
      .subscribe(
        () => this.saveLoyaltyOfferedSuccess(flowType, loyalty),
        (error) => this.saveLoyaltyOfferedError(error)
      );
  }

  showSpecialOfferButton(): boolean {
    return (
      this.preselect.customerInBureauTrail &&
      this.useFootprintsNewServiceToggle &&
      (this.creditFlowService.getCreditFlowType() === CreditFlowType.ANYFEE ||
        this.creditFlowService.getCreditFlowType() ===
          CreditFlowType.SAMEFEE) &&
      this.loyaltyAnyFeeToggle.featureEnabled &&
      this.creditFlowService.getModalityType() === ModalityType.ORDINARY &&
      !this.userService.isCustomerNewPayrollLoan()
    );
  }

  getFlowTypeInDismiss() {
    return (this.user.idChannel === 1 || this.user.idChannel === 3) &&
      this.preselect.customerInBureauTrail &&
      !this.userService.isCustomerNewPayrollLoan() &&
      this.useFootprintsNewServiceToggleV2
      ? 'certificate'
      : 'dismiss';
  }

  private saveLoyaltyOfferedSuccess(flowType: string, loyalty: boolean) {
    this.dialogRef.close({
      flowType,
      loyalty,
    });
  }

  private saveLoyaltyOfferedError(error: any) {
    console.log(
      'CalculatorComponent - saveLoyaltyOfferedError, Error: ' +
        JSON.stringify(error)
    );
    this.message =
      'Ha ocurrido algo inesperado guardando la mejora de la oferta';
  }

  private searchReazon(item: string, reazon: any[]): boolean {
    return reazon.filter((x) => x.item_id === item).length > 0;
  }

  private setOrganizationToggle() {
    this.organizationToggleStatus = this.toggleService.getToggle(
      ToggleEnum.ORGANIZATION_LAMBDAS_FEATURE_TOGGLE
    ).featureEnabled;
  }

  private setUseFootprintsNewServiceToggle() {
    this.useFootprintsNewServiceToggle = this.toggleService.getToggle(
      'USE_FOOTPRINTS_NEW_SERVICE_TOGGLE'
    ).featureEnabled;
  }

  private setUseFootprintsNewServiceToggleV2() {
    this.useFootprintsNewServiceToggleV2 = this.toggleService.getToggle(
      'USE_FOOTPRINTS_NEW_SERVICE_TOGGLE_V2'
    ).featureEnabled;
  }
}
