import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class StrategyService {
  constructor(
    private http: HttpClient) { }

  getOfficeStrategy(toggleName: string, officeId: string): Observable<object> {
    return this.http.get(environment.serverUrl + 'feature/' + toggleName + '/strategy/offices/' + officeId);
  }
}
